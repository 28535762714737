import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Carousels } from 'components/atoms';
import RatingCustom from 'components/atoms/RatingCustom';
import { useResponsive } from 'constants/hooks';
import { NetworkContext } from 'providers/context';
import React, { useContext, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import 'react-multi-carousel/lib/styles.css';
import StickyBox from 'react-sticky-box';
import { colors } from 'styles';
import Modal from '../../organisms/Modal/index';

interface Props {
	value: any;
	criteriaList: any;
}

interface dataTableTechnicalPerformancePrincipleProps {
	title: string;
	description: string;
	rating: number;
}
interface dataTableTechnicalPerformanceProps {
	criteria: string;
	principle: dataTableTechnicalPerformancePrincipleProps[];
}
const MenuBar = [
	'Description & Function',
	'Application Examples',
	'Potential Application',
	'Technical Considerations & Potential Combination',
	'Scoring',
];

const transformScoreGuideObjectToArray = (input: any) => {
	let c = [];
	for (let i = 0; i < input?.length; i++) {
		for (let e = 0; e < input[i]?.length; e++) {
			c.push(input[i][e]);
		}
	}
	return c;
};

const MenuTable = ['CRITERIA', 'PRINCIPLES', 'SCORING'];
const MenuTableCriteria = ['CRITERIA', 'PRINCIPLES', 'DEFINITION'];

const TableStyle = styled('table')(() => ({
	width: '100%',
	textAlign: 'center',
	marginTop: '30px',
	'& td, & th': {
		border: '1px solid #F0F0F0',
		borderRadius: '10px',
		padding: '8px',
		height: '100px !important',
		'@media (min-width: 900px)': {
			height: '100px !important',
		},
		'@media (max-width: 900px)': {
			height: '100% !important',
		},
	},
	'& td': {
		textAlign: 'left',
		fontFamily: 'Inter',
		fontSize: '16px',
		lineHeigh: '24px',
		fontWieght: '600',
	},
	'& th': {
		background: 'white!important',
		fontFamily: 'Inter',
		fontSize: '18px',
		lineHeigh: '24px!important',
		fontWieght: '600',
	},
	'& tr:nth-of-type(even)': {
		background: '#F5FAFF',
	},
}));

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 2,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
};

const ButtonStyling = {
	background: '#F2F4F7',
	p: '0.1rem 1rem',
	borderRadius: '10px',
};
const ButtonDescriptionStyling = {
	background: colors.gray[100],
	borderRadius: '10px',
	color: '#344054',
	fontSize: '14px',
	fontWeight: '600',
	padding: '3px 15px',
	fontFamily: 'Inter',
	lineHeight: '18px',
	textTransform: 'none',
	pointerEvents: 'none',
};
const MeasureItemDetail: React.FC<Props> = props => {
	const { value = '', criteriaList } = props;
	const [modalOpen, setmodalOpen] = useState(false);
	const isDesktop = useResponsive('down', 'sm');
	const { isOnline } = useContext(NetworkContext);
	const scrollToDescriptionRef = useRef<HTMLLinkElement>(null);
	const scrollToApplicationExampleRef = useRef<HTMLLinkElement>(null);
	const scrollToPotentialRef = useRef<HTMLLinkElement>(null);
	const scrollToTechnicalRef = useRef<HTMLLinkElement>(null);
	const scrollToScoringRef = useRef<HTMLLinkElement>(null);
	const executeScroll = (value: number) => {
		if (value === 0) {
			scrollToDescriptionRef.current?.scrollIntoView();
		} else if (value === 1) {
			scrollToApplicationExampleRef.current?.scrollIntoView();
		} else if (value === 2) {
			scrollToPotentialRef.current?.scrollIntoView();
		} else if (value === 3) {
			scrollToTechnicalRef.current?.scrollIntoView();
		} else if (value === 4) {
			scrollToScoringRef.current?.scrollIntoView();
		}
	};

	const [DescriptionRef, inViewDescription] = useInView({
		threshold: 0.1,
	});
	const [ApplicationExampleRef, inViewApplicationExample] = useInView({
		threshold: 0.1,
	});
	const [PotentialRef, inViewPotential] = useInView({
		threshold: 0.1,
	});
	const [TechnicalRef, inViewTechnical] = useInView({
		threshold: 0.1,
	});
	const [ScoringRef, inViewScoring] = useInView({
		threshold: 0.1,
	});
	const activeTab = () => {
		if (inViewDescription) {
			return 0;
		} else if (inViewApplicationExample) {
			return 1;
		} else if (inViewPotential) {
			return 2;
		} else if (inViewTechnical) {
			return 3;
		} else if (inViewScoring) {
			return 4;
		}
	};

	return (
		<Grid
			container
			mb="100px"
			px={{
				xs: '0px',
				md: '2rem',
			}}
		>
			<Grid
				item
				xs={12}
				md={3}
				sm={3}
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				textAlign="start"
			>
				{!isDesktop && (
					<StickyBox offsetTop={70} offsetBottom={20}>
						{MenuBar &&
							MenuBar.map((item: string, i: number) => (
								<Box
									key={i}
									sx={{
										width: '100%',
										px: {
											xs: '0px',
											md: '2rem',
										},
									}}
								>
									<Button
										onClick={() => executeScroll(i)}
										sx={{
											justifyContent: 'flex-start',
											textAlign: 'start',
											borderLeft: activeTab() === i ? '3px solid #2E90FA' : '',
										}}
									>
										{item}
									</Button>
								</Box>
							))}
					</StickyBox>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={9}
				sm={9}
				display="flex"
				sx={{
					padding: {
						xs: '0px',
						md: '0px 32px',
					},
				}}
				mb="30px"
				flexDirection="column"
				justifyContent="flex-start"
				// sx={{ background: '#F2F4F7' }}
			>
				<Box ref={DescriptionRef}>
					{value && (
						<Box display="flex" flexWrap="wrap" gap={{ xs: 1, lg: 2 }} mb={4}>
							{value.tags.map((items: any, id: number) => (
								<Button key={id} sx={ButtonDescriptionStyling} size="small">
									{items?.name}
								</Button>
							))}
						</Box>
					)}
					<Typography
						ref={scrollToDescriptionRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Description
					</Typography>
					<Typography
						mt={2}
						sx={{
							lineHeight: {
								md: '30px',
								sm: '30px',
								xs: '20px',
							},
							color: colors.gray[500],
							fontFamily: 'Inter',
							fontWeight: '400',
							fontSize: '16px',
							fontStyle: 'normal',
						}}
					>
						{value && value.description ? <p dangerouslySetInnerHTML={{ __html: value.description }}></p> : null}
					</Typography>
				</Box>
				<Box mt={3}>
					<Typography
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Function
					</Typography>
					<Typography
						mt={2}
						sx={{
							lineHeight: {
								md: '30px',
								sm: '30px',
								xs: '20px',
							},
							color: colors.gray[500],
							fontFamily: 'Inter',
							fontWeight: '400',
							fontSize: '16px',
							fontStyle: 'normal',
						}}
					>
						<div style={{ marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: value && value.functions }}></div>
					</Typography>
				</Box>
				<Box ref={ApplicationExampleRef} mt={3} maxWidth="xl">
					<Typography
						ref={scrollToApplicationExampleRef}
						fontSize="24px"
						mb={2}
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Application Examples
					</Typography>
					{value && value?.applications && <Carousels data={value?.applications} />}
				</Box>
				<Box ref={PotentialRef} mt={3}>
					<Typography
						ref={scrollToPotentialRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Potential Application
					</Typography>
					<Typography
						variant="subtitle1"
						mt={2}
						sx={{
							lineHeight: {
								md: '30px',
								sm: '30px',
								xs: '20px',
							},
							color: colors.gray[500],
							fontFamily: 'Inter',
							fontSize: '16px',
						}}
					>
						<span style={{ fontWeight: 'bold' }}>{value && value.location.title}</span>
					</Typography>
					{((value?.location?.location_img_url &&
						value?.location?.location_img_url !== 'https://api-dev.sjadaptationtoolkit.com/') ||
						value?.location?.image) && (
						<Box maxWidth="xl">
							<img
								src={isOnline ? value?.location?.location_img_url : value?.location?.image}
								style={{ margin: '1em 0', width: '100%', borderRadius: '5px' }}
								alt="demo"
								height="inherit"
							/>
						</Box>
					)}
				</Box>

				<Box ref={TechnicalRef} mt={3}>
					<Typography
						ref={scrollToTechnicalRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Important Technical Considerations
					</Typography>
					<Container sx={{ px: '0px!important', mt: '2em' }}>
						{['Planning', 'Design', 'Implementation', 'Operation'].map((item: string, id: number) => (
							<Box
								key={item}
								sx={{
									background: '#F8F8F8',
									padding: '1em',
									'&:nth-of-type(odd)': {
										background: '#F5FAFF',
									},
								}}
							>
								<Typography
									fontSize="18px"
									sx={{
										lineHeight: {
											md: '32px',
											xs: '24px',
										},
										fontWeight: '600',
										fontFamily: 'Inter',
										letterSpacing: '-0.01em',
									}}
								>
									{item}
								</Typography>
								<div
									style={{
										marginTop: '10px',
										lineHeight: '24px',
										fontWeight: '400',
										fontFamily: 'Inter',
										letterSpacing: '-0.01em',
										fontSize: '16px',
										color: '#667085',
									}}
									dangerouslySetInnerHTML={{
										__html:
											value && item === 'Planning'
												? value && value.technical_consider.planning
												: item === 'Design'
												? value && value.technical_consider.design
												: item === 'Implementation'
												? value && value.technical_consider.implementation
												: item === 'Operation'
												? value && value.technical_consider.operation
												: '',
									}}
								></div>
							</Box>
						))}
					</Container>
					<Typography
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							marginTop: '30px',
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
							mt: '2em',
						}}
					>
						Potential Combination with Other Measures
					</Typography>
					<Container sx={{ px: '0px!important', mt: '2em' }}>
						{value &&
							value.potentials.map((item: any) => (
								<ul>
									<li
										style={{
											color: '#667085',
											fontFamily: 'Inter',
											letterSpacing: '-0.01em',
											fontSize: '16px',
											fontWeight: '400',
										}}
									>
										{item.name}
									</li>
								</ul>
							))}
					</Container>
				</Box>
				<Box ref={ScoringRef} mt={2} maxWidth="md">
					<>
						<Typography
							ref={scrollToScoringRef}
							fontSize="24px"
							sx={{
								lineHeight: {
									md: '32px',
									xs: '24px',
								},
								fontWeight: '600',
								fontFamily: 'Inter',
								letterSpacing: '-0.01em',
							}}
						>
							Scoring
						</Typography>
						<TableStyle>
							{!isDesktop && (
								<thead>
									<tr>
										{MenuTable.map((items: string) => (
											<th key={items} scope="col">
												{items}
											</th>
										))}
									</tr>
								</thead>
							)}
							<tbody>
								{criteriaList &&
									criteriaList !== undefined &&
									criteriaList.map((items: any, id: number) =>
										!isDesktop ? (
											<>
												<th key={items.name} rowSpan={items.criterias.length + 1} scope="rowgroup">
													{items.name}
												</th>
												{value &&
													value.criterias
														.filter((e: any, i: number) => e.measure_criteria?.criteria_type_id === items.id)
														.map((options: any, idx: number) => (
															<>
																<tr key={options.id}>
																	<td scope="row">{options.principle}</td>
																	<td>
																		<RatingCustom
																			tooltip={options.measure_criteria.description}
																			key={id}
																			value={options.measure_criteria.point}
																		/>
																	</td>
																</tr>
															</>
														))}
												{value &&
													items.criterias
														.filter((e: any) => value.criterias?.map((e: any) => e.id).indexOf(e.id) === -1)
														.map((res: any, k: number) => (
															<>
																<tr>
																	<td>{res.principle}</td>
																	<td>
																		<RatingCustom value={0} />
																	</td>
																</tr>
															</>
														))}
											</>
										) : (
											<table style={{ width: '100%', marginRight: '0px' }}>
												<tr>
													<th
														key={items?.name}
														style={{
															display: 'flex',
															border: 'none',
														}}
													>
														{items?.name}
													</th>
												</tr>
												{value &&
													value.criterias
														.filter((e: any, i: number) => e.measure_criteria?.criteria_type_id === items.id)
														.map((options: any, idx: number) => (
															<>
																<tr key={options.id}>
																	<td scope="row">{options.principle}</td>
																	<td>
																		<RatingCustom
																			tooltip={options.measure_criteria?.description}
																			key={id}
																			value={options.measure_criteria?.point}
																		/>
																	</td>
																</tr>
															</>
														))}
												{value &&
													items.criterias
														.filter((e: any) => value.criterias?.map((e: any) => e.id).indexOf(e.id) === -1)
														.map((res: any, k: number) => (
															<>
																<tr>
																	<td>{res.principle}</td>
																	<td>
																		<RatingCustom value={0} />
																	</td>
																</tr>
															</>
														))}
											</table>
										)
									)}
							</tbody>
						</TableStyle>
					</>
					{!isDesktop && (
						<Button
							onClick={() => setmodalOpen(true)}
							sx={{
								border: '1px solid #D0D5DD',
								background: '#FFFFFF',
								boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
								my: '1em',
								px: '1em',
								color: '#344054',
							}}
						>
							View scoring criteria &nbsp;
							<ArrowOutwardRoundedIcon fontSize="medium" />
						</Button>
					)}
				</Box>
			</Grid>
			<Modal isOpen={modalOpen} handleClose={() => setmodalOpen(false)}>
				<Box sx={{ p: '20px' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography
							fontSize="36px"
							sx={{
								lineHeight: {
									md: '24px',
									xs: '18px',
								},
								mt: '30px',
								ml: '20px',
								fontWeight: '500',
							}}
						>
							SCORING CRITERIA
						</Typography>
						<Button sx={{ fontSize: '20px', color: 'black' }} onClick={() => setmodalOpen(false)}>
							X
						</Button>
					</Box>
					<TableStyle>
						<thead>
							<tr>
								{MenuTableCriteria.map((items: string) => (
									<th key={items} style={{ color: '#9DA4AE' }} scope="col">
										{items}
									</th>
								))}
								<th scope="col" style={{ color: '#9DA4AE' }} colSpan={5}>
									SCORING GUIDE
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td></td>
								<td>Principles on the positive scoring scale</td>
								{[1, 2, 3, 4, 5].map((num: number, id: number) => (
									<td>
										<RatingCustom key={id} value={num} showTooltip={false} />
									</td>
								))}
							</tr>
							{criteriaList &&
								criteriaList !== undefined &&
								criteriaList.map((items: any, id: number) => (
									<>
										<th key={items.id} rowSpan={items.criterias?.length + 2} scope="rowgroup">
											{items.name}
										</th>
										<tr></tr>
										{items &&
											items.criterias?.map((res: any, key: number) => (
												<>
													<tr key={res.title}>
														<td scope="row">{res.principle}</td>
														<td scope="row">{res.definition}</td>
														<td scope="row">{res.scoring_guide[1]}</td>
														<td scope="row">{res.scoring_guide[2]}</td>
														<td scope="row">{res.scoring_guide[3]}</td>
														<td scope="row">{res.scoring_guide[4]}</td>
														<td scope="row">{res.scoring_guide[5]}</td>
													</tr>
												</>
											))}
									</>
								))}
						</tbody>
					</TableStyle>
				</Box>
			</Modal>
		</Grid>
	);
};

export default MeasureItemDetail;
