import { Box, Button, Link, Typography } from '@mui/material'
import { TextInput } from 'components/atoms'
import { AuthenticationLayout } from 'components/organisms'
import _get from 'lodash/get'
import { RESEND_MAIL_TYPE, useResendVerifyEmail, VerifyEmailInfo } from 'modules/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from 'routers'
import { colors } from 'styles'
import { redirectTo } from 'utils'

interface GetStartedPageProps { }

const GetStartedPage: React.FC<GetStartedPageProps> = ({ }) => {
  const navigate = useNavigate()
  const { mutate, isSuccess, isError, error } = useResendVerifyEmail()

  const { handleSubmit, register, getValues } = useForm<VerifyEmailInfo>({
    defaultValues: {
      email: ''
    }
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.CHECK_MAIL, {
        state: {
          resendMailType: RESEND_MAIL_TYPE.CREATE_PASSWORD,
          email: getValues().email
        }
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && error) {
      const errMessage = _get(error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [isError])

  const onSubmit = (data: VerifyEmailInfo) => {
    mutate(data)
  }

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="div"
          color={colors.gray[900]}
          fontWeight={600}
          fontSize="24px"
          lineHeight="32px"
        >
          Get started
        </Typography>
        <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
          Please enter your email to start
        </Typography>
        <TextInput
          placeholder="Enter your email"
          register={register}
          name="email"
          sx={{
            width: '100%',
            my: '24px'
          }}
        />
        <Button
          sx={{ width: '100%', mb: '24px' }}
          variant="contained"
          type="submit"
        >
          Continue with email
        </Button>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
            Already have account?
          </Typography>
          <Link
            fontSize="14px"
            sx={{
              textDecoration: 'none'
            }}
            href={redirectTo(ROUTES.LOGIN)}
          >
            Sign in
          </Link>
        </Box>
      </form>
    </AuthenticationLayout>
  )
}

export default GetStartedPage
