import { Box, Button, IconButton, Link, Typography } from '@mui/material'
import { PasswordInfoSVG } from 'assets/icons'
import { TextInput } from 'components/atoms'
import { AuthenticationLayout } from 'components/organisms'
import _get from 'lodash/get'
import { UpsertPasswordInfo, useCreatePassword } from 'modules/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from 'routers'
import { colors } from 'styles'
import { redirectTo } from 'utils'
import { formSchemaCreatePassword } from 'constants/form/schema'
import { yupResolver } from '@hookform/resolvers/yup';

interface CreatePasswordPageProps { }

const CreatePasswordPage: React.FC<CreatePasswordPageProps> = ({ }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { mutate, isSuccess, isError, error } = useCreatePassword()

  const { handleSubmit, register,formState: { errors } } = useForm<UpsertPasswordInfo>({
    mode: "onTouched",
    resolver: yupResolver(formSchemaCreatePassword),
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.LOGIN)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && error) {
      const errMessage = _get(error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [isError])

  const onSubmit = (data: UpsertPasswordInfo) => {
    if (token) {
      mutate({ token, password: data.password })
    }
  }

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="div"
          color={colors.gray[900]}
          fontWeight={600}
          fontSize="24px"
          lineHeight="32px"
        >
          Get started
        </Typography>
        <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
          Please create your password
        </Typography>
        <TextInput
          placeholder="Your Password"
          name="password"
          sx={{
            width: '100%',
            my: '24px'
          }}
          type="password"
          register={register}
          // endAdornment={
          //   <IconButton>
          //     <img src={PasswordInfoSVG} alt="password_info_svg" />
          //   </IconButton>
          // }
        />
        <Typography sx={{color: 'red', fontSize:'11px', textAlign:'left'}}>{errors.password?.message}</Typography>
        <TextInput
          placeholder="Confirm Your Password"
          register={register}
          name="confirmPassword"
          type="password"
          sx={{
            width: '100%',
            mb: '24px'
          }}
          // endAdornment={
          //   <IconButton>
          //     <img src={PasswordInfoSVG} alt="password_info_svg" />
          //   </IconButton>
          // }
        />
         <Typography sx={{color: 'red', fontSize:'11px',textAlign:'left'}}>{errors.confirmPassword?.message}</Typography>
        <Button
          sx={{ width: '100%', mb: '24px' }}
          variant="contained"
          type="submit"
        >
          Confirm
        </Button>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
            Already have account?
          </Typography>
          <Link
            fontSize="14px"
            sx={{
              textDecoration: 'none'
            }}
            href={redirectTo(ROUTES.LOGIN)}
          >
            Sign in
          </Link>
        </Box>
      </form>
    </AuthenticationLayout>
  )
}

export default CreatePasswordPage
