import React, { createContext, memo, useMemo, useReducer } from 'react'

export const ModalContext = createContext<{ state: any, dispatch: Function }>({
  state: {},
  dispatch: () => {}
});

export const useContext = () => React.useContext(ModalContext);

export const CRU_ACTION_TYPES = {
  SHOW_CRU:  0,
  CLOSE_CRU: 1,
};

const cruReducer = (state: any, action: any) => {
  switch (action.type) {
    case CRU_ACTION_TYPES.SHOW_CRU:
      return { ...state, displayCRU: true, initialValues: action.payload }
    case CRU_ACTION_TYPES.CLOSE_CRU:
      return { ...state, displayCRU: false }
    default:
      return state;
  }
};

export const CruProvider = memo(({ children }: any) => {
  const [state, dispatch] = useReducer(cruReducer, {});
  const context = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <ModalContext.Provider value={context}>
      {children}
    </ModalContext.Provider>
  );
});

export const useInitialValues = () => {
  const { state } = useContext();
  return [state.initialValues];
};

export const useShowCRUModal = () => {
  const { state, dispatch } = useContext();

  const showCRU = (initialValues?: any) => {
    dispatch({ type: CRU_ACTION_TYPES.SHOW_CRU, payload: initialValues });
  };

  const closeCRUModal = () => {
    dispatch({ type: CRU_ACTION_TYPES.CLOSE_CRU });
  };

  return {
    displayCRUModal: state.displayCRU,
    showCRU,
    closeCRUModal
  };
};

