import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Typography } from '@mui/material'
import { MailBoxSVG } from 'assets/icons'
import { AuthenticationLayout } from 'components/organisms'
import { useCountdown } from 'constants/hooks'
import _get from 'lodash/get'
import { RESEND_MAIL_TYPE, useForgotPassword, useResendVerifyEmail } from 'modules/auth'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { colors } from 'styles'

interface CheckEmailProps { }

const CheckEmail: React.FC<CheckEmailProps> = ({ }) => {
  const { state } = useLocation()
  const { email, resendMailType } = state || {}
  const navigate = useNavigate()
  const resendMailMutation = useResendVerifyEmail()
  const forgotPasswordMutation = useForgotPassword()

  const [remainingCountdown, isCountdownRunning, setCountdownRunning] =
    useCountdown(60)

  useEffect(() => {
    if (resendMailMutation.isSuccess || forgotPasswordMutation.isSuccess) {
      setCountdownRunning(true)
    }
  }, [resendMailMutation.isSuccess, forgotPasswordMutation.isSuccess])


  useEffect(() => {
    if (resendMailMutation.isError || forgotPasswordMutation.isError) {
      const errMessage = _get(resendMailMutation.error || forgotPasswordMutation.error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [resendMailMutation.isError, forgotPasswordMutation.isError])

  const onResendVerifyEmail = () => {
    if (email) {
      switch (resendMailType) {
        case RESEND_MAIL_TYPE.CREATE_PASSWORD:
          return resendMailMutation.mutate({
            email
          })
        case RESEND_MAIL_TYPE.FORGOR_PASSWORD:
          return forgotPasswordMutation.mutate({
            email
          })
        default:
          return
      }

    }
  }

  return (
    <AuthenticationLayout>
      <img src={MailBoxSVG} alt="mailbox_svg" />
      <Typography
        component="div"
        color={colors.gray[900]}
        fontWeight={600}
        fontSize="24px"
        lineHeight="32px"
      >
        Check your email
      </Typography>
      <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
        We sent a verify link to {email}
      </Typography>

      <Button
        disabled={isCountdownRunning}
        sx={{ width: '100%', my: '32px' }}
        variant="contained"
        onClick={onResendVerifyEmail}
      >
        {isCountdownRunning ? `Resend after ${remainingCountdown}s` : 'Resend'}
      </Button>
      <Button onClick={() => navigate(-1)}>
        <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
          <ArrowBackIcon sx={{ color: colors.gray[500] }} /> Back
        </Typography>
      </Button>
    </AuthenticationLayout>
  )
}

export default CheckEmail
