import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiUrl, STORAGE_KEYS } from 'constants/api';
import { ROUTES } from 'routers';

interface AxiosHelper {
	baseUrl: any;
	header: any;
}
class AxiosHelper {
	instance: AxiosInstance;
	constructor(baseUrl: any, defaultHeader: any = {}) {
		this.baseUrl = baseUrl;
		this.header = defaultHeader;
		this.instance = axios.create();
		this.addRequestInterception(config => {
			const token = localStorage.getItem('@AccessToken');
			if (token && config.headers) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		});
		this.instance.interceptors.response.use(
			response => response,
			error => {
				if (error?.response?.status === 401) {
					localStorage.removeItem(STORAGE_KEYS.AccessToken);
					localStorage.removeItem('role');
					window.location.hash = ROUTES.LOGIN;
				}
				throw error;
			}
		);
	}

	addHeader(key: string, value: string) {
		this.header[key] = value;
	}

	addRequestInterception(interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig) {
		return this.instance.interceptors.request.use(interceptor);
	}

	removeHeader(key: string) {
		delete this.header[key];
	}

	setBaseUrl(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	makeUrl(url: string) {
		const host = typeof this.baseUrl === 'function' ? this.baseUrl() : this.baseUrl;

		const [_protocol, _baseUrl] = host.split('://');
		const _url = [_baseUrl, url].join('/').replace(/\/{2,}/g, '/');
		return `${_protocol}://${_url}`;
	}

	async _request(method: string, url: string, data: any, config: any) {
		const _url = this.makeUrl(url);
		const _header = this.header;

		return this.instance.request({
			url: _url,
			method,
			headers: _header,
			...config,
			data,
		});
	}

	async get(url: string, config?: any) {
		return this._request('get', url, undefined, config);
	}

	async post(url: string, data: any = {}, config?: any) {
		return this._request('post', url, data, config);
	}

	async put(url: string, data: any, config?: any) {
		return this._request('put', url, data, config);
	}

	async delete(url: string, data?: any, config?: any) {
		return this._request('delete', url, data, config);
	}

	async patch(url: string, data: any, config?: any) {
		return this._request('patch', url, data, config);
	}
}

const Axios = new AxiosHelper(ApiUrl, {});

export { Axios };
