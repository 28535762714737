import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deepParseJson } from 'deep-parse-json';
import { cloneDeep } from 'lodash';
import { useNetworkContext } from 'providers/context';
import { QK_HOMEPAGE } from './conts';
import { getHomepageData, updateHomepageData } from './services';
import { HomepageData } from './types';

export const useGetHomepageData = () => {
	const processData = (data: any) => {
		const res: any = {};
		data.forEach((it: any) => {
			res[it.key] = it;
		});
		return new HomepageData(res);
	};
	const { isOnline } = useNetworkContext();
	return useQuery<any, any, HomepageData>([QK_HOMEPAGE], isOnline ? getHomepageData : () => null, {
		select: data => {
			if (!isOnline && typeof settings !== 'undefined') {
				data = cloneDeep(settings).map(it => {
					if (it.data_type === 'json') {
						it.value = deepParseJson(it.value);
					}
					return it;
				});
			}
			return processData(data);
		},
	});
};

export const useUpdateHomepageData = () => {
	const queryClient = useQueryClient();
	return useMutation<any, any, any>(async params => {
		const res = await updateHomepageData(params);
		await queryClient.invalidateQueries([QK_HOMEPAGE]);
		return res;
	});
};
