export const gray = {
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  400: '#98A2B3',
  500: '#667085',
  700: '#344054',
  900: '#111927'
}

export const primary = {
  50: '#EFF8FF',
  200: '#B2DDFF',
  500: '#2E90FA',
  700: '#175CD3'
}

export const blue = {
  50: '#84CAFF',
  100: '#53B1FD',
  200: '#2E90FA',
  300: '#1570EF',
  400: '#175CD3'
}

export const pink = '#FA06FA'
export const green = '#219653'
