import React, { useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useFormikContext } from 'formik';

type Category = {
  id: number;
  name: string;
};

interface Props {
  options: Category[] | any;
  selectedValues?: Category[] | any;
  onBlur: () => void;
  name: string;
  placeholders? : string;
}

export const MultiSelect: React.FC<Props> = ({ options, selectedValues, name, onBlur, placeholders}) => {
  const { setFieldValue } = useFormikContext();
  const divRef = useRef<any>(null)
  return (
    <div ref={divRef}>
      <Multiselect
        options={options}
        placeholder={placeholders}
        selectedValues={selectedValues}
        onSelect={(selectedList: any,selectedItem:any) => {
          setFieldValue(name, selectedList);
        }}
        onRemove={(selectedList: any) => {
          setFieldValue(name, selectedList);
        }}
        displayValue="name"
        closeIcon="circle"
      />
    </div>
  );
};

export default MultiSelect;
