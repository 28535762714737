import { useEffect, useState } from 'react'

const useCountdown = (second: number) => {
  const [isCountdownRunning, setCountdownRunning] = useState(false)
  const [remainingCountdown, setRemainingCountdown] = useState(second)

  useEffect(() => {
    let timer: any
    if (isCountdownRunning) {
      if (!remainingCountdown) {
        if (timer) {
          clearInterval(timer)
        }
        setCountdownRunning(false)
        setRemainingCountdown(second)
      }

      timer = setInterval(() => {
        setRemainingCountdown(prev => prev - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [isCountdownRunning, remainingCountdown, second])

  return [remainingCountdown, isCountdownRunning, setCountdownRunning] as const
}

export default useCountdown
