import React, { useCallback } from 'react'
import { FieldArray, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FieldFormik,
  Fieldrow, RatingCustomAdmin, Textarea
} from 'components/atoms'

import { defaultInitialPrincipleValues, principleSchema } from './principleSchema'

const PrincipleModal = (props: any) => {
  const { isOpen = false, onCancel, onOk, initialValues } = props;

  const handleSubmit = useCallback(async (values: any, action: any) => {
    try {
      onOk && await onOk(values);
    } catch (err) {
      action.setSubmitting(false);
    }
  }, [onOk])

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {initialValues ? 'Edit' : 'Add'} Principle
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues || defaultInitialPrincipleValues}
          validationSchema={principleSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, dirty, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Fieldrow name="principle">
                    <FieldFormik type="text" label="Principle name" placeholder="Enter a principle name" name="principle" readonly={false} />
                  </Fieldrow>
                  <Fieldrow name="definition">
                    <Textarea value={values.definition} label="Description" name="definition" placeholder="Enter a description..." maxLength={500} />
                  </Fieldrow>
                  <Box mt={1}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>Score Guide</Typography>
                    <Typography sx={{ color: (theme) => theme.palette.grey[600], fontSize: 14 }}>
                      Update your avatar and then choose where you want it to display
                    </Typography>
                    <FieldArray
                      name="scoring_guide"
                      render={arrayHelpers => (
                        <Box>
                          { values.scoring_guide?.map((scoreGuide: any, idx: number) => (
                            <Box key={idx}>
                              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }} >
                                <RatingCustomAdmin name="1" ids={"1"} readonly={true} value={idx + 1} />
                              </Box>
                              <Fieldrow name={`scoring_guide.${idx}`}>
                                <Textarea value={values.scoring_guide[idx]} name={`scoring_guide.${idx}`} placeholder="Enter a description..." maxLength={500} />
                              </Fieldrow>
                            </Box>
                          ))}
                        </Box>
                      )}
                    />
                  </Box>
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px', gap: '8px' }}>
                  <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                  <LoadingButton variant="contained" type='submit' loading={isSubmitting} disabled={!dirty || !isValid}>
                    Save
                  </LoadingButton>
                </Box>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default PrincipleModal;
