import * as Yup from "yup";

export const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("*Password is required")
    .min(8, "*Password length should be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "*Upper case characters (A - Z), Lower case characters (a - z), Numeric characters (0 - 9),Symbols (e.g. #, %, &, @"
    )
    ,
  oldPassword: Yup.string()
    .required("*Current password is required")
    .min(8, "*Current password length should be at least 8 characters")
});

export const formSchemaCreatePassword = Yup.object().shape({
  password: Yup.string()
    .required("*Password is required")
    .min(8, "*Password length should be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "*Upper case characters (A - Z), Lower case characters (a - z), Numeric characters (0 - 9), Symbols (e.g. #, %, &, @)"
    )
    ,
    confirmPassword: Yup.string()
    .required("*Confirm Password is required")
    .min(8, "*Password length should be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "*Upper case characters (A - Z), Lower case characters (a - z), Numeric characters (0 - 9), Symbols (e.g. #, %, &, @)"
    )
    .oneOf([Yup.ref("password")], "*Passwords do not match")
});