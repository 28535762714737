import { Box, InputLabel } from '@mui/material';
import { Field, useField, useFormikContext } from 'formik';
import React from 'react';

interface Props {
	type?: 'text' | 'tel' | 'password' | 'number' | 'search';
	placeholder?: string;
	name: string;
	useFormik?: boolean;
	setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	readonly?: boolean;
	disabled?: boolean;
	label?: string;
	maxLength?: number;
}

export const Textfield: React.FC<Props> = ({ type, placeholder, name, useFormik, readonly, disabled, label, ...props }) => {
	const [field] = useField({ name });

	return (
		<Box sx={{}} className="a-textfield_input">
			{label && <InputLabel sx={{ marginBottom: 1 }}>{label}</InputLabel>}
			<Field
				style={{
					width: '100%',
					padding: '10px 15px',
					border: '1px solid #D0D5DD',
					borderRadius: '8px',
					background: disabled ? 'rgba(0, 0, 0, 0.12)' : '',
				}}
				type={type}
				placeholder={placeholder}
				name={name}
				readOnly={readonly}
				disabled={disabled}
				{...props}
			/>
			{props?.maxLength && <Box mt={1} color="#475467" fontSize="14px">{`${field.value?.length || 0}/${props.maxLength}`}</Box>}
		</Box>
	);
};

export const FieldFormik: React.FC<Props> = props => {
	const { setFieldValue } = useFormikContext();
	return <Textfield {...props} setFieldValue={setFieldValue} useFormik />;
};

export default FieldFormik;
