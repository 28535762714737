import { Box, Button, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TabProps } from '@mui/material/Tab'
import { TabsProps } from '@mui/material/Tabs'
import { STORAGE_KEYS } from 'constants/api'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routers'
import { colors } from 'styles'

const Tabs = styled((props: TabsProps) => <MuiTabs {...props} />)(() => ({
  '.MuiTabs-indicator': {
    display: 'none'
  }
}))
const Tab = styled((props: TabProps) => <MuiTab {...props} />)(() => ({
  '&.MuiButtonBase-root': {
    alignItems: 'flex-start'
  },
  '&.Mui-selected': {
    backgroundColor: colors.primary[50],
    borderRadius: '6px'
  }
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {value === index && <Box ml={{ xs:'0px', md:'96px' }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

interface VerticalTabProps {
  tabs: Array<{ label: string; content: React.ReactNode }>
}

export default function VerticalTab(props: VerticalTabProps) {
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleLogoutClick = () => {
    localStorage.removeItem(STORAGE_KEYS.AccessToken)
    localStorage.removeItem('role')
    navigate(ROUTES.LOGIN)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: {
          xs: 'column',
          md: 'row'
        }
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {props.tabs.map((item, index) => (
          <Tab
            key={`vertical_tab_label_${index}`}
            label={item.label}
            {...a11yProps(index)}
          />
        ))}
        <Button
          sx={{
            padding: '12px 17px',
            fontSize: '16px',
            fontWeight: 500,
            justifyContent: 'left',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
          onClick={handleLogoutClick}
        >
          Logout
        </Button>
      </Tabs>
      {props.tabs.map((item, index) => (
        <TabPanel
          key={`vertical_tab_panel_${index}`}
          value={value}
          index={index}
        >
          {item.content}
        </TabPanel>
      ))}
    </Box>
  )
}
