import { Container } from '@mui/system'
import { PageTitle, VerticalTab } from 'components/molecules'
import { Layout } from 'components/organisms'
import ChangePassword from 'components/organisms/ChangePassword'
import * as React from 'react'
import MyProfile from '../MyProfile'

interface ProfileProps { }

const ProfilePage: React.FC<ProfileProps> = ({ }) => {
  const tabs = [
    {
      label: 'My details',
      content: <MyProfile />
    },
    { label: 'Change password', content: <ChangePassword /> }
  ]
  return (
    <Layout>
      <Container maxWidth="xl">
        <PageTitle title="My Profile" />
        <VerticalTab tabs={tabs} />
      </Container>
    </Layout>
  )
}

export default ProfilePage
