import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { FieldFormik, Fieldrow, FileInput, Textarea } from 'components/atoms';
import { Formik } from 'formik';
import { useCallback } from 'react';

import { defaultInitialFilterValues, filterSchema } from './filterSchema';

const CreateFilterModal = (props: any) => {
	const { isOpen = false, onCancel, onOk, initialValues } = props;

	const handleSubmit = useCallback(
		async (values: any, action: any) => {
			try {
				onOk && (await onOk(values));
			} catch (err) {
				action.setSubmitting(false);
			}
		},
		[onOk],
	);

	return (
		<Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="md">
			<DialogTitle>
				{initialValues ? 'Edit' : 'Add'} Category
				<IconButton
					onClick={onCancel}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={initialValues || defaultInitialFilterValues}
					validationSchema={filterSchema}
					onSubmit={handleSubmit}
				>
					{({ values, isValid, dirty, isSubmitting, handleSubmit }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Stack spacing={2}>
									<Box>
										<Typography sx={{ mb: 2 }}>
											Please resize the picture to exactly 1920x1080px before
											uploading it
										</Typography>
										<FileInput
											name="image"
											label=""
											maxsize="SVG, PNG, JPG or GIF (max.)"
										/>
										{typeof values?.image === 'string' && (
											<img
												style={{ width: '100px', height: '60px' }}
												src={values?.tag_image_url}
												alt="edit image"
											/>
										)}
									</Box>
									<Fieldrow name="name">
										<FieldFormik
											type="text"
											label="Category name"
											placeholder="ABC Waters design features, etc.."
											name="name"
											readonly={false}
										/>
									</Fieldrow>
									<Fieldrow name="description">
										<Textarea
											value={values.description}
											label="Description"
											name="description"
											placeholder="Enter a description..."
											maxLength={500}
										/>
									</Fieldrow>
								</Stack>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										mt: '10px',
										gap: '8px',
									}}
								>
									<Button variant="outlined" onClick={onCancel}>
										Cancel
									</Button>
									<LoadingButton
										variant="contained"
										type="submit"
										loading={isSubmitting}
										disabled={!dirty || !isValid}
									>
										Save
									</LoadingButton>
								</Box>
							</form>
						);
					}}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default CreateFilterModal;
