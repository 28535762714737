import * as React from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: string;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 300,
    fontSize: '14px',
    fontFamily: 'Inter',
    padding: '10px'
  },
}));

const Tooltips: React.FC<Props> = ({children, title}:any) => {
  return (
    <>
      <BootstrapTooltip placement="top-start" title={title}>
        {children}
      </BootstrapTooltip>
    </>
    
  )
}
export default Tooltips
