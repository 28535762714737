import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded'
import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik';

interface ratingProps {
  value: number
  title?: string
  readonly? : boolean
  onChange? :any
  ids? : any
  name? : string
}

const StyledRating = styled(Rating)({
  '&>*:nth-of-type(1)': {
    color: '#FF5372',
  },
  '&>*:nth-of-type(2)': {
    color: '#FF8F28',
  },
  '&>*:nth-of-type(3)': {
    color: '#FDE600',
  },
  '&>*:nth-of-type(4)': {
    color: '#94C259',
  },
  '&>*:nth-of-type(5)': {
    color: '#179C01',
  },
})

const RatingCustomAdmin: React.FC<ratingProps> = ({ value, title, readonly = true,onChange,ids, name }) => {
  const { setFieldValue, setErrors, errors } = useFormikContext<any>();
  const valueFormik = (res:any,req:any) => {
    onChange(res,ids,name)
    setFieldValue(`${name}.criteria_id`,ids)
    setFieldValue(`${name}.point`,res)
    
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: !title? 'center':'space-between',
        my: 2,
        cursor: 'pointer'
      }}
    >
     {title && <Typography>
        {title}
      </Typography>}
      <StyledRating
        onChange={(event, newValue) => valueFormik(newValue,event)}
        name="customized-color"
        readOnly = {readonly}
        defaultValue={value}
        icon={
          <HorizontalRuleRoundedIcon
            className='hellooooo'
            fontSize="small"
            sx={{
              transform: 'scale(9, 4) rotate(90deg)',
              width: '10px',
            }}
          />
        }
        emptyIcon={
          <HorizontalRuleRoundedIcon
            fontSize="small"
            sx={{ transform: 'scale(9, 4) rotate(90deg)', width: '10px' }}
          />
        }
      />
    </Box>
  )
}

export default RatingCustomAdmin
