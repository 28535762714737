import React from 'react';
import ReactModal from 'react-modal';

interface Props {
  isOpen?: boolean;
  handleClose: () => void;
  children:any;
}

const ModalAdmin: React.FC<Props> = (props) => {
  const { isOpen = false, handleClose, children } = props;
  return (
    <ReactModal
      ariaHideApp={false}
      bodyOpenClassName="u-modal-open"
      overlayClassName="o-modal_overlay"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(74, 72, 72, 0.75)',
          zIndex: '10099',
          borderRadius: '15px!important',
        },
        content: {
          position: 'absolute',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '15px',
          outline: 'none',
          inset: '0px',
          padding: '0px',
          width: '45%',
          margin: '40px auto',
        },
      }}
    >
      <div>{children}</div>
    </ReactModal>
  );
};

export default ModalAdmin;
