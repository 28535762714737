import { array, Asserts, mixed, number, object, string } from 'yup';

export const createSchema = object({
	photoMeasure: mixed(),
	unit: number().required(),
	technical_consider: mixed().required(),
	description: string(),
	measureID: string(),
	functions: string(),
	useProfiles: mixed(),
	tags_type: array(),
	potential: string(),
	applicationdescription: string(),
	applicationname: string(),
	scoringTechnicalPerformance: mixed().required(),
	location: mixed(),
	photo3D: mixed(),
	photoSchematic: mixed(),
	Typology: mixed(),
	application: array(
		object().shape({
			description: string().required(),
			name: string().required(),
			image: mixed().required(),
		})
	),
	Profiles: array(
		object().shape({
			description: string().required(),
			id: string().required(),
			image: string().required(),
			name: string().required(),
			tag_image_url: string().required(),
		})
	),
	Functionality: string().required('Please select Functionality name.'),
	measurename: string().required('Please enter measurename name.'),
	main_picture: mixed(),
	measure_main_picture: mixed(),
});

export type CreateForm = Asserts<typeof createSchema>;

export const initialValue: CreateForm = {
	useProfiles: [],
	tags_type: undefined,
	potential: '',
	photoMeasure: undefined,
	technical_consider: {
		planning: '',
		design: '',
		implementation: '',
		operation: '',
	},
	scoringTechnicalPerformance: [
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
		{
			criteria_id: '',
			description: '',
			point: 0,
		},
	],
	location: {
		title: '',
		description: '',
		file: undefined,
	},

	description: '',
	functions: '',
	unit: 0,
	Profiles: [],
	Typology: [],
	photo3D: [],
	photoSchematic: [],
	application: [],
	applicationdescription: '',
	applicationname: '',
	main_picture: undefined,
	Functionality: '',
	measureID: '',
	measurename: '',
	measure_main_picture: '',
};
