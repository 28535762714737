import React, { ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from '@mui/material'

interface WarningModalProps extends DialogProps {
  open: boolean,
  title: string,
  message?: string | ReactNode,
  subject?: object | string | null,
  renderMessage: Function,
  okProps?: any,
  onCancel: () => void,
  onOk: (arg?: any) => void,
}

const ConfirmModal = (props: WarningModalProps) => {
  const {
    open,
    onCancel,
    onOk,
    title,
    message,
    renderMessage,
    subject,
    okProps = { color: 'error', variant: "contained" },
    maxWidth = "xs"
  } = props;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={onCancel}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {
          typeof message === 'string'
          ? <DialogContentText>{message}</DialogContentText>
          : renderMessage && renderMessage(subject)
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button color="error" variant="contained" {...okProps} onClick={() => onOk(subject)}>{okProps.children || 'Ok'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmModal
