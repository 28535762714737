import { Box } from '@mui/material'
import { Footer, Header } from 'components/molecules'
import * as React from 'react'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Header />
        <Box
          sx={{
            backgroundColor: 'white'
          }}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout
