import { Button } from "@mui/material"
import { AdminLayout } from 'components/organisms'
import { useDownloadToolkit } from "modules/toolkit"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const DownloadToolkit = () => {
	const mutation = useDownloadToolkit()
	const handleDownload = () => mutation.mutate()
	return (
		<AdminLayout>
			<Button sx={{ ml: 2 }} variant="contained" startIcon={<CloudDownloadIcon />} onClick={handleDownload}>Download</Button>
		</AdminLayout>
	)
}

export default DownloadToolkit