import { useEffect, useMemo } from 'react'
import { STORAGE_KEYS } from 'constants/api'
import jwt_decode from 'jwt-decode';
import get from 'lodash/get';

const useAuth = () => {
  const accessToken = localStorage.getItem(STORAGE_KEYS.AccessToken)
  const decoded = accessToken && jwt_decode(accessToken as string);

  const { isLoggedIn } = useMemo(() => {
    return { isLoggedIn: !!accessToken }
  }, [accessToken])

  return { isLoggedIn, userId: get(decoded, 'user_id') }
}

export default useAuth
