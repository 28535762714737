import { Axios } from 'core/httpServices'
import { QK_HOMEPAGE } from './conts'

export const getHomepageData = async () => {
  const { data } = await Axios.get(QK_HOMEPAGE)
  return data.data
}

export const updateHomepageData = async (params: any) => {
  const { data } = await Axios.put(QK_HOMEPAGE, params)
  return data.data
}
