import { OutlinedInput } from '@mui/material'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import * as React from 'react'
import { RegisterOptions } from 'react-hook-form'

interface TextInputProps extends OutlinedInputProps {
  register?: any
  rules?: RegisterOptions
}

const TextInput: React.FC<TextInputProps> = ({ register, rules, ...props }) => {
  return (
    <OutlinedInput
      {...props}
      sx={{
        borderRadius: '8px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D0D5DD'
        },
        '& input': {
          padding: '10px 14px',
          fontSize: '14px'
        },
        ...props.sx
      }}
      {...(register && register(props.name, { ...rules }))}
    />
  )
}

export default TextInput
