export const ROUTES = {
	LOGIN: '/login',
	HOME: '/home',
	TOOLKIT: '/toolkit',
	CATEGORIES: '/categories',
	PROFILE: '/profile',
	MEASURE: '/measure',
	MEASUREITEM: '/measureItem',
	CREATE_PASSWORD: '/create-password',
	GET_STARTED: '/get-started',
	CHECK_MAIL: '/check-mail',
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/reset-password',
	ADMIN: '/admin',
};

export const ADMIN_ROUTES = {
	USERS: '/admin/users',
	DASHBOARD: '/admin/dashboard',
	INTRODUCE: '/admin/introduce',
	CREATE: '/admin/createmeasure',
	EDIT: '/admin/editmeasure',
	FILTER: '/admin/filter/:id',
	INTRODUCTION: '/admin/introduction',
	SCORING_CRITERIA_SETTING: '/admin/scoring-criteria-setting',
	CRITERIA: '/admin/criteria/:id',
	TOOLKIT: '/admin/toolkit',
	HOMEPAGE_CMS: '/admin/homepage-cms',
};
