import { useQuery } from '@tanstack/react-query';
import { useNetworkContext } from 'providers/context';
import { QK_CRITERIA_TYPES, QK_MEASURES, QK_TAG_TYPES } from './conts';
import { getCriteriaTypes, getMeasures, getTagTypes } from './services';
import { CriteriaCategory, Measure, TagType } from './types';

export const useGetTagTypes = () => {
	const { isOnline } = useNetworkContext();
	return useQuery<any, any, TagType[]>([QK_TAG_TYPES], isOnline ? getTagTypes : () => null, {
		select: data => {
			if (!isOnline && typeof tag_type !== 'undefined') {
				data = tag_type;
			}
			return data;
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetMeasures = () => {
	const { isOnline } = useNetworkContext();
	return useQuery<any, any, Measure[]>([QK_MEASURES], isOnline ? getMeasures : () => null, {
		select: data => {
			if (!isOnline && typeof measure !== 'undefined') {
				data = measure;
			}
			return data.map((it: any) => new Measure(it));
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetCriteriaTypes = () => {
	const { isOnline } = useNetworkContext();
	return useQuery<any, any, CriteriaCategory[]>([QK_CRITERIA_TYPES], isOnline ? getCriteriaTypes : () => null, {
		select: data => {
			if (!isOnline && typeof criteria_types !== 'undefined') {
				data = criteria_types;
			}
			return data;
		},
		refetchOnWindowFocus: false,
	});
};
