import { Box, Button, Container, Divider, Grid, IconButton, Typography } from '@mui/material';
import { icon_addMore, icon_arrowLeft, icon_remove, icon_uploadPlus } from 'assets/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import {
	FieldFormik,
	Fieldrow,
	FileInput,
	MultiSelect,
	RatingCustomAdmin,
	RatingEditAdmin,
	Select,
	Textarea,
	TextEditor,
} from 'components/atoms';
import { AdminLayout, ModalAdmin, ModalAdminCustom, ModalLocationEdit } from 'components/organisms';
import { createSchema, initialValue } from 'constants/form/measure-form';
import { Axios } from 'core/httpServices';
import { Field, FieldArray, Form, Formik } from 'formik';
import { pickBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADMIN_ROUTES } from 'routers';

interface AdminCreateMeasureProps {}

const buttonStyling = {
	width: '100%',
	border: '1px dashed #E5E7EB',
	height: '110px',
};

const transformObjectToArray = (input: any) => {
	let c = [];
	for (let i = 0; i < input?.length; i++) {
		for (let e = 0; e < input[i]?.length; e++) {
			c.push(input[i][e]);
		}
	}
	return c;
};

const AdminEditMeasure: React.FC<AdminCreateMeasureProps> = ({}) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const id_number = searchParams.get('id');
	const [getData, setGetdata] = useState<any>();
	const [resUseProfiles_tags, setResUseProfiles_tags] = useState<any>();
	const [functionality_tags, setFunctionality_tags] = useState<any>(null);

	const [modalOpen, setModalOpen] = useState(false);

	const [modalOpenLocation, setModalLocation] = useState(false);

	const [modalOpenSchematic_0, setModalOpenSchematic_0] = useState(false);
	const [modalOpenSchematic_1, setModalOpenSchematic_1] = useState(false);
	const [modalOpenSchematic_2, setModalOpenSchematic_2] = useState(false);
	const [modalOpenPhoto3D_0, setModalOpenPhoto3D_0] = useState(false);
	const [modalOpenPhoto3D_1, setModalOpenPhoto3D_1] = useState(false);
	const [modalOpenPhoto3D_2, setModalOpenPhoto3D_2] = useState(false);

	const [applicationId, setApplicationId] = useState(0);
	const [applicationDeletedIds, setApplicationDeletedIds] = useState<any[]>([]);
	const [filters, setFilters] = useState();
	const [getRes, setGetRes] = useState<any>();
	const [getCriteria, setGetCriteria] = useState<any>();

	const getDatalist = async () => {
		const getData = await Axios.get(`/tag-types/all?&includes=tags,criterias,location`);
		// const getCriteria = await Axios.get(`/criterias/all`)
		const useProfiles_tags = getData.data.data
			.filter((e: any) => e.slug === 'land_use_profiles')
			.map((req: any) => req.tags);
		setResUseProfiles_tags(useProfiles_tags[0]);
		setGetdata(getData.data.data);
	};

	const getCriteriaList = async () => {
		const getCriteria = await Axios.get(`/criteria-types?includes=criterias&sort=created_at`);
		setGetCriteria(getCriteria.data.data);
	};

	const getFunctionality_tags = async () => {
		try {
			const getTags = await Axios.get(`/measures?limit=200&sort=-created_at`);
			setFunctionality_tags(getTags.data.data.filter((it: any) => it.id !== id_number));
		} catch {
			console.log('error');
		}
	};

	const getMeasuresDetail = async () => {
		try {
			const getData = await Axios.get(
				`/measures/${id_number}?includes=applications,attachments,criterias,tags,location,potentials,description`
			);
			setGetRes(getData?.data);
		} catch {
			console.log('error');
		}
	};
	useEffect(() => {
		getDatalist();
		getMeasuresDetail();
		getCriteriaList();
		getFunctionality_tags();
	}, []);

	return (
		<AdminLayout>
			{/* ///////////// */}
			<section>
				<Formik
					enableReinitialize={true}
					initialValues={{
						...initialValue,
						photoMeasure: getRes?.main_picture,
						measureMainPicture: getRes?.measure_main_picture,
						measurename: getRes?.name,
						Functionality: getRes?.category_id,
						description: getRes?.description,
						useProfiles: getRes?.tags.filter((e: any) => e.tag_type_slug === 'land_use_profiles'),
						Typology:
							getRes?.tags
								.filter((e: any) => e.tag_type_slug !== 'land_use_profiles' && e.tag_type_slug !== 'functionality')
								.map((d: any) => d.id) || [],
						functions: getRes?.functions,
						application: getRes ? [...getRes.applications] : [],
						photoSchematic: getRes ? [...getRes.attachments.filter((e: any) => e.type === 1)] : [],
						photo3D: getRes ? [...getRes.attachments.filter((e: any) => e.type === 2)] : [],
						technical_consider: {
							planning: getRes?.technical_consider.planning,
							design: getRes?.technical_consider.design,
							implementation: getRes?.technical_consider.implementation,
							operation: getRes?.technical_consider.operation,
						},
						location: getRes?.location,
						scoringTechnicalPerformance: getRes ? [...getRes?.criterias.map((e: any) => e)] : [],
						potential: getRes?.potentials,
					}}
					validationSchema={createSchema}
					onSubmit={values => {
						console.log('values', values);
					}}
					validateOnMount
				>
					{({ values, isValid, setTouched, touched, setFieldValue, errors, handleChange }: any) => {
						const postData = async () => {
							const data = new FormData();
							const attachment = [...values.photo3D, ...values.photoSchematic];

							if (values.Typology?.length > 0) {
								const cleanedTypology = pickBy(values.Typology, v => v !== undefined);
								const TypologyArray = Object.values(cleanedTypology);
								if (values.Typology?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', TypologyArray);
								} else if (values.Typology?.length > 1) {
									TypologyArray.forEach((element: any) => data.append('tag_ids', element));
								}
							}
							if (values.useProfiles?.length > 0) {
								const tag_type = [...values?.useProfiles.map((e: any) => e.id)];
								if (values.useProfiles?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', tag_type);
								} else if (values.useProfiles?.length > 1) {
									tag_type.forEach((element: any) => data.append('tag_ids', element));
								}
							}

							if (values.potential) {
								let tag_potential = values?.potential.map((e: any) => e.id);
								if (tag_potential?.length === 1) {
									//@ts-ignore
									data.append('potential_ids[]', tag_potential);
								} else if (tag_potential?.length > 1) {
									tag_potential.forEach((element: any) => data.append('potential_ids', element));
								}
							}

							if (attachment.length > 0) {
								attachment
									.filter((e: any) => e.delete === true)
									.map((items: any) => items.id && data.append(`delete_attachment_ids[]`, items.id));
								const attachmentNotDelete = attachment.filter((e: any) => e.delete === undefined);
								for (let i = 0; i < attachmentNotDelete.length; i++) {
									if (
										attachmentNotDelete &&
										attachmentNotDelete[i]?.id !== undefined &&
										attachmentNotDelete[i].delete === undefined
									) {
										data.append(`attachments[${i}][name]`, attachmentNotDelete[i]?.name);
										data.append(`attachments[${i}][type]`, attachmentNotDelete[i]?.type);
										data.append(
											`attachments[${i}][image]`,
											attachmentNotDelete[i]?.file ? attachmentNotDelete[i]?.file : attachmentNotDelete[i]?.image
										);
										data.append(`attachments[${i}][id]`, attachmentNotDelete[i]?.id);
									} else {
										data.append(`attachments[${i}][name]`, attachmentNotDelete[i]?.name);
										data.append(`attachments[${i}][type]`, attachmentNotDelete[i]?.type);
										data.append(`attachments[${i}][image]`, attachmentNotDelete[i]?.file);
									}
								}
							}

							applicationDeletedIds.forEach(id => {
								data.append(`delete_application_ids[]`, id);
							});

							values.application
								?.filter((it: any) => !!it)
								?.forEach((it: any, i: number) => {
									if (it?.id) {
										data.append(`applications[${i}][id]`, it.id);
									}
									data.append(`applications[${i}][title]`, it.title || 'No title');
									data.append(`applications[${i}][description]`, it.description || 'No description');
									data.append(`applications[${i}][image]`, it.image);
								});

							// data.append('measure_id', values.measureID);
							data.append('category_id', values.Functionality);
							data.append('name', values.measurename);
							data.append('description', values.description);
							if (getRes?.main_picture && !values.photoMeasure) {
								data.append('is_delete_main_picture', 'true');
							}
							data.append('main_picture', values.photoMeasure || '');
							data.append('functions', values.functions);

							data.append('technical_consider[planning]', values.technical_consider.planning);
							data.append('technical_consider[design]', values.technical_consider.design);
							data.append('technical_consider[implementation]', values.technical_consider.implementation);
							data.append('technical_consider[operation]', values.technical_consider.operation);

							if (values.location) {
								if (values.location.delete && values.location.delete === true) {
									data.append('delete_location_id', values.location.id);
								} else {
									data.append('location[title]', values.location.title || 'No title');
									data.append('location[description]', values.location.description || 'No description');
									data.append('location[image]', values.location.file || values.location.image || '');
								}
							}
							if (values.scoringTechnicalPerformance) {
								for (var i = 0; i < values.scoringTechnicalPerformance.length; i++) {
									if (values.scoringTechnicalPerformance[i]) {
									} else {
										values.scoringTechnicalPerformance.splice(i, 1);
									}
								}

								const criteriaArray = transformObjectToArray(getCriteria?.map((e: any) => e.criterias));
								for (let i = 0; i < criteriaArray.length; i++) {
									data.append(
										`criterias[${i}][criteria_id]`,
										values?.scoringTechnicalPerformance[i]?.id ||
											values?.scoringTechnicalPerformance[i]?.criteria_id ||
											criteriaArray[i].id
									);
									data.append(
										`criterias[${i}][description]`,
										values?.scoringTechnicalPerformance[i]?.measure_criteria?.description || ''
									);
									data.append(
										`criterias[${i}][point]`,
										values?.scoringTechnicalPerformance[i]?.measure_criteria?.point ?? 0
									);
								}
							}
							try {
								const getData = await Axios.put(`/measures/${getRes.id}`, data, {
									headers: {
										'Content-Type': 'multipart/form-data',
									},
								});
								if (getData.status === 200) {
									toast.success(`Measure ${values.measurename} has been updated successfully`);
									navigate(ADMIN_ROUTES.INTRODUCE);
								}
							} catch (e: any) {
								const errorType = e.response?.data?.error?.errors;
								errorType.forEach((element: any) => {
									if (element.message.indexOf('name') !== -1) {
										const alert = element.message.replace('name', 'Measure name');
										toast.error(alert);
									} else if (element.message.indexOf('category_id') !== -1) {
										const alert = element.message.replace('category_id', 'Functionality');
										toast.error(alert);
									} else {
										toast.error(element.message);
									}
								});
							}
						};
						const clearValues = (name: string, id: number, application: any) => {
							if (name === 'application') {
								if (application?.id) {
									setApplicationDeletedIds(prev => {
										prev.push(application.id);
										return prev;
									});
								}
								setFieldValue(`application[${id}]`, null);
							}
						};

						const onChangeRating = (items: any, opt: any, index: number) => {
							setFilters(items);
						};

						const clearMainPicture = () => {
							setFieldValue('photoMeasure', null);
							setFieldValue('measureMainPicture', null);
						};

						return (
							<Form>
								<Container
									maxWidth="xl"
									sx={{
										'& a': { textDecoration: 'none' },
										px: { xs: '0 !important', md: '16px !important' },
									}}
								>
									<Box display="flex" alignItems="center" width="100%" flexWrap="wrap" gap={2}>
										<Box display="flex" alignItems="center" flex={1} flexShrink={0} ml={-3}>
											<Link to="/admin/introduce">
												<Button>
													<img src={icon_arrowLeft} alt="icon_arrowLeft" />
												</Button>
											</Link>
											<Typography ml={2} variant="h5" fontWeight="bold" whiteSpace="nowrap">
												Edit measure
											</Typography>
										</Box>
										<Box display="flex">
											<Link to="/admin/introduce">
												<Button
													variant="outlined"
													sx={{
														textDecoration: 'none!important',
														background: 'white',
														color: 'black',
														border: '1px solid #D0D5DD',
													}}
												>
													Cancel
												</Button>
											</Link>
											<Button onClick={() => postData()} sx={{ ml: 3 }} variant="contained">
												Save
											</Button>
										</Box>
									</Box>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Photo
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Photo of measure</Typography>
												<Typography sx={{ mb: 2 }}>
													Please resize the picture to exactly 1920x1080px before uploading it
												</Typography>
												<Fieldrow name="photoMeasure">
													<Box position="relative">
														<FileInput
															name="photoMeasure"
															label=""
															maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
															setTouched={() => !touched.photoMeasure && setTouched({ ...touched, photoMeasure: true })}
															ThumbTrigger={!!values?.photoMeasure?.preview}
															previewImg={
																values?.photoMeasure && !values?.photoMeasure?.preview && values?.measureMainPicture
															}
														/>
														{values?.photoMeasure && (
															<IconButton
																sx={{
																	position: 'absolute',
																	left: '88px',
																	bottom: '117px',
																	svg: { width: 8, height: 8 },
																	path: { stroke: 'white' },
																	zIndex: 2,
																	backgroundColor: 'rgb(160, 160, 160)',
																	p: '7px',
																}}
																onClick={clearMainPicture}
															>
																<CloseIcon />
															</IconButton>
														)}
													</Box>
												</Fieldrow>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Schematic Diagram</Typography>
												<Typography sx={{ mb: 2 }}>Upload up to three (3) schematics</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalOpenSchematic_0(true)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{values.photoSchematic &&
															values.photoSchematic.length >= 1 &&
															(values?.photoSchematic[0]?.attachment_image_url || values?.photoSchematic[0]?.file) && (
																<img
																	style={{ width: '100px', height: '60px' }}
																	className="image-contain"
																	src={
																		values?.photoSchematic[0]?.file
																			? values.photoSchematic[0].file?.preview
																			: values?.photoSchematic[0]?.attachment_image_url
																	}
																/>
															)}
													</Box>

													{values.photoSchematic && values.photoSchematic.length >= 1 && (
														<Box sx={{ width: '30%', marginRight: '10px' }}>
															<Button sx={buttonStyling} onClick={() => setModalOpenSchematic_1(true)}>
																<img src={icon_uploadPlus} alt="icon upload" />
															</Button>
															{values?.photoSchematic[1] &&
																(values?.photoSchematic[1]?.attachment_image_url ||
																	values?.photoSchematic[1]?.file) && (
																	<img
																		style={{ width: '100px', height: '60px' }}
																		className="image-contain"
																		src={
																			values?.photoSchematic[1]?.file
																				? values.photoSchematic[1].file?.preview
																				: values?.photoSchematic[1]?.attachment_image_url
																		}
																	/>
																)}
														</Box>
													)}
													{values.photoSchematic && values.photoSchematic.length >= 2 && (
														<Box sx={{ width: '30%', marginRight: '10px' }}>
															<Button sx={buttonStyling} onClick={() => setModalOpenSchematic_2(true)}>
																<img src={icon_uploadPlus} alt="icon upload" />
															</Button>
															{values?.photoSchematic[2] &&
																(values?.photoSchematic[2]?.attachment_image_url ||
																	values?.photoSchematic[2]?.file) && (
																	<img
																		style={{ width: '100px', height: '60px' }}
																		className="image-contain"
																		src={
																			values?.photoSchematic[2]?.file
																				? values.photoSchematic[2].file?.preview
																				: values?.photoSchematic[2]?.attachment_image_url
																		}
																	/>
																)}
														</Box>
													)}
												</Box>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Illustrations</Typography>
												<Typography sx={{ mb: 2 }}>Upload up to three (3) illustrations of measure</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalOpenPhoto3D_0(true)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{values.photo3D &&
															values.photo3D.length >= 1 &&
															(values?.photo3D[0]?.attachment_image_url || values?.photo3D[0]?.file) && (
																<img
																	style={{ width: '100px', height: '60px' }}
																	className="image-contain"
																	src={
																		values?.photo3D[0]?.file
																			? values.photo3D[0]?.file?.preview
																			: values?.photo3D[0]?.attachment_image_url
																	}
																/>
															)}
													</Box>
													{values.photo3D && values.photo3D.length >= 1 && (
														<Box sx={{ width: '30%', marginRight: '10px' }}>
															<Button sx={buttonStyling} onClick={() => setModalOpenPhoto3D_1(true)}>
																<img src={icon_uploadPlus} alt="icon upload" />
															</Button>
															{values?.photo3D[1] &&
																(values?.photo3D[1]?.attachment_image_url || values?.photo3D[1]?.file) && (
																	<img
																		style={{ width: '100px', height: '60px' }}
																		className="image-contain"
																		src={
																			values?.photo3D[1]?.file
																				? values.photo3D[1]?.file?.preview
																				: values?.photo3D[1]?.attachment_image_url
																		}
																	/>
																)}
														</Box>
													)}
													{values?.photo3D && values?.photo3D.length >= 2 && (
														<Box sx={{ width: '30%', marginRight: '10px' }}>
															<Button sx={buttonStyling} onClick={() => setModalOpenPhoto3D_2(true)}>
																<img src={icon_uploadPlus} alt="icon upload" />
															</Button>
															{values?.photo3D[2] &&
																(values?.photo3D[2]?.attachment_image_url || values?.photo3D[2]?.file) && (
																	<img
																		style={{ width: '100px', height: '60px' }}
																		className="image-contain"
																		src={
																			values?.photo3D[2]?.file
																				? values.photo3D[2]?.file?.preview
																				: values?.photo3D[2]?.attachment_image_url
																		}
																	/>
																)}
														</Box>
													)}
												</Box>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Potential Application</Typography>
												<Typography sx={{ mb: 2 }}>Upload Potential Application of measure</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalLocation(true)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{(values?.location?.image || values?.location?.file?.preview) && (
															<img
																style={{ width: '100px', height: '60px' }}
																className="image-contain"
																src={
																	values?.location?.file?.preview
																		? values.location?.file?.preview
																		: values?.location?.location_img_url
																}
															/>
														)}
													</Box>
												</Box>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												General Information
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Measure name *
												</Typography>
												<Fieldrow name="measurename">
													<FieldFormik
														type="text"
														placeholder="ABC Waters design features, etc.."
														name="measurename"
														readonly={false}
													/>
												</Fieldrow>
											</Container>
											{getData &&
												getData !== undefined &&
												getData
													?.filter((e: any) => e.slug === 'functionality')
													?.map((options: any, idx: number) => (
														<Container maxWidth="md" sx={{ mb: 4 }}>
															<Typography sx={{ mb: 2 }} fontWeight="600">
																Functionality *
															</Typography>
															{/* <Select name="Functionality"> */}
															<Field
																// onChange={(e: any) => getFunctionality_tags(e.currentTarget.value, setFieldValue)}
																style={{
																	width: '100%',
																	padding: '10px 15px',
																	border: '1px solid #D0D5DD',
																	borderRadius: '8px',
																}}
																as="select"
																name="Functionality"
																value={values.Functionality}
															>
																<option disabled value="">
																	Make a choice
																</option>
																{options &&
																	options !== undefined &&
																	options.tags?.map((u: any, idx: number) => (
																		<option value={u.id} key={u.name}>
																			{u.name}
																		</option>
																	))}
															</Field>
														</Container>
													))}
											<FieldArray
												name="Typology"
												render={arrayHelpers => (
													<Box>
														{getData &&
															getData !== undefined &&
															getData
																.filter((e: any) => e.slug !== 'land_use_profiles' && e.slug !== 'functionality')
																.map((option: any, index: number) => (
																	<Container key={index} maxWidth="md" sx={{ mb: 4 }}>
																		<Typography sx={{ mb: 2 }} fontWeight="600">
																			{option.name}
																		</Typography>
																		<Select name={`Typology[${index}]`}>
																			<option value="" selected disabled>
																				Make a choice
																			</option>
																			{option &&
																				option !== undefined &&
																				option.tags.map((u: any, idx: number) => (
																					<option value={u.id} key={u.name}>
																						{u.name}
																					</option>
																				))}
																		</Select>
																	</Container>
																))}
													</Box>
												)}
											/>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Land Use Profiles
												</Typography>
												<MultiSelect
													options={resUseProfiles_tags && resUseProfiles_tags}
													selectedValues={values.useProfiles}
													name="useProfiles"
													onBlur={() => !touched.useProfiles && setTouched({ ...touched, useProfiles: true })}
												/>
											</Container>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Description
												</Typography>
												<Field name="description">
													{({ field, meta }: any) => (
														<TextEditor value={values.description} onchange={field.onChange(field.name)} />
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Functions
												</Typography>
												<Field name="functions">
													{({ field, meta }: any) => (
														<TextEditor value={values.functions} onchange={field.onChange(field.name)} />
													)}
												</Field>
											</Container>

											{/* /////////////////////// */}

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Application example
												</Typography>

												{['first', 'second', 'third'].map((items: any, id: number) => {
													const application = values?.application?.[id];
													return (
														<Container
															key={id}
															maxWidth="md"
															sx={{
																mb: 4,
																background: '#FCFCFD',
																border: '1px solid #E5E7EB',
																borderRadius: '8px',
																padding: '0 !important',
															}}
														>
															<Button
																sx={{
																	textTransform: 'none',
																	display: 'flex',
																	position: 'relative',
																	justifyContent: 'flex-start',
																	px: 2,
																	py: 1.5,
																	flexWrap: { xs: 'wrap', lg: 'unset' },
																	gap: 1,
																	minHeight: '64px',
																}}
																onClick={() => {
																	setModalOpen(true);
																	setApplicationId(id);
																}}
																fullWidth
															>
																{application?.image?.preview || application?.application_image_url ? (
																	<Box
																		width="22%"
																		minWidth="130px"
																		mr={1}
																		pt={application?.title || application?.description ? 1 : 0}
																		flexShrink={0}
																		alignSelf="flex-start"
																	>
																		<img
																			src={application?.image?.preview || application?.application_image_url}
																			className="image-contain"
																		/>
																	</Box>
																) : (
																	<Box mr={1}>
																		<img src={icon_addMore} alt="add more" />
																	</Box>
																)}
																<Box display="flex" alignItems="center">
																	{application?.title || application?.description ? (
																		<Box mr={4} textAlign="left">
																			{application?.title && (
																				<Typography mb={1} sx={{ color: '#344054' }} fontWeight="600">
																					{application?.title}
																				</Typography>
																			)}
																			<Typography sx={{ color: '#475467' }}>{application?.description}</Typography>
																		</Box>
																	) : (
																		<Typography sx={{ color: '#384250' }}>Add application example</Typography>
																	)}
																</Box>
																{application && (
																	<Box position="absolute" top={4} right={4}>
																		<Button
																			onClick={e => {
																				e.stopPropagation();
																				clearValues('application', id, application);
																			}}
																			sx={{ minWidth: 'fit-content', height: 'fit-content' }}
																		>
																			<img src={icon_remove} alt="remove logo" />
																		</Button>
																	</Box>
																)}
															</Button>
														</Container>
													);
												})}
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Important Technical Considerations
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Planning
												</Typography>
												<Field name="technical_consider.planning">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.technical_consider.planning}
															onchange={field.onChange(field.name)}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Design
												</Typography>
												<Field name="technical_consider.design">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.technical_consider.design}
															onchange={field.onChange(field.name)}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Implementation
												</Typography>
												<Field name="technical_consider.implementation">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.technical_consider.implementation}
															onchange={field.onChange(field.name)}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Operation
												</Typography>
												<Field name="technical_consider.operation">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.technical_consider.operation}
															onchange={field.onChange(field.name)}
														/>
													)}
												</Field>
											</Container>
										</Grid>
									</Grid>
								</Container>

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Scoring
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{getCriteria &&
												getCriteria.map((opt: any, index: number) => (
													<Container key={index} maxWidth="md">
														<Typography sx={{ mb: 2 }} fontWeight="bold">
															{getRes?.criterias?.some((e: any) => e.id === opt?.criterias?.map((d: any) => d.id)) ===
																false && opt.name}
														</Typography>
														{opt &&
															opt !== undefined &&
															opt?.criterias?.map((options: any, idx: number) => (
																<Fragment key={options.id}>
																	{getRes?.criterias.map((valueRes: any, idxkey: number) => (
																		<Fragment key={idxkey}>
																			{valueRes.id === options.id && (
																				<>
																					<Container
																						key={idx}
																						maxWidth="md"
																						sx={{
																							mb: 4,
																							display: 'flex',
																							alignItems: 'flex-start',
																							flexDirection: 'column',
																						}}
																					>
																						<Typography sx={{ mb: 2 }} fontWeight="600">
																							{options.principle}
																						</Typography>
																						<RatingEditAdmin
																							key={idxkey}
																							onChange={onChangeRating}
																							value={valueRes.measure_criteria?.point}
																							name={`scoringTechnicalPerformance[${idxkey}]`}
																							ids={options.id}
																							title=""
																							readonly={false}
																						/>
																						<Textarea
																							value={
																								values?.scoringTechnicalPerformance &&
																								values?.scoringTechnicalPerformance?.filter(
																									(e: any) => e.id === valueRes.id
																								)[0]?.measure_criteria?.description
																							}
																							name={`scoringTechnicalPerformance[${idxkey}].measure_criteria.description]`}
																							placeholder="Enter a description..."
																							maxLength={500}
																						/>
																					</Container>
																				</>
																			)}
																		</Fragment>
																		// ))
																	))}
																	{getRes?.criterias?.some((e: any) => e.id === options?.id) === false && (
																		<Container
																			key={idx}
																			maxWidth="md"
																			sx={{
																				mb: 4,
																				display: 'flex',
																				alignItems: 'flex-start',
																				flexDirection: 'column',
																			}}
																		>
																			<Typography sx={{ mb: 2 }} fontWeight="600">
																				{options.principle}
																			</Typography>
																			<RatingCustomAdmin
																				onChange={onChangeRating}
																				value={0}
																				name={`scoringTechnicalPerformance.${[idx + getRes?.criterias?.length]}`}
																				ids={options.id}
																				readonly={false}
																				title=""
																			/>
																			<Textarea
																				value={values?.scoringTechnicalPerformance[options.id]?.description}
																				name={`scoringTechnicalPerformance.${[
																					idx + getRes?.criterias?.length,
																				]}.description]`}
																				placeholder="Enter a description..."
																				maxLength={500}
																			/>
																		</Container>
																	)}
																</Fragment>
															))}
													</Container>
												))}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container sx={{ mb: 20, px: { xs: '0 !important', md: '16px !important' } }} maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Potential combination with other measures
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{functionality_tags && (
												<MultiSelect
													options={functionality_tags}
													selectedValues={values.potential}
													placeholders="Choose measure"
													name="potential"
													onBlur={() => !touched.potential && setTouched({ ...touched, potential: true })}
												/>
											)}
										</Grid>
									</Grid>
								</Container>
								{/* ////////////// Modal ///////////// */}
								<ModalAdminCustom
									openModal={modalOpenSchematic_0}
									id={0}
									defaultType={1}
									name="photoSchematic"
									handleClose={() => setModalOpenSchematic_0(false)}
									setTouched={() => !touched.photoSchematic && setTouched({ ...touched, photoSchematic: true })}
								/>
								<ModalAdminCustom
									openModal={modalOpenSchematic_1}
									id={1}
									defaultType={1}
									name="photoSchematic"
									handleClose={() => setModalOpenSchematic_1(false)}
									setTouched={() => !touched.photoSchematic && setTouched({ ...touched, photoSchematic: true })}
								/>
								<ModalAdminCustom
									id={2}
									openModal={modalOpenSchematic_2}
									defaultType={1}
									name="photoSchematic"
									handleClose={() => setModalOpenSchematic_2(false)}
									setTouched={() => !touched.photoSchematic && setTouched({ ...touched, photoSchematic: true })}
								/>
								<ModalAdminCustom
									openModal={modalOpenPhoto3D_0}
									id={0}
									defaultType={2}
									name="photo3D"
									handleClose={() => setModalOpenPhoto3D_0(false)}
									setTouched={() => !touched.photoSchematic_0 && setTouched({ ...touched, photo3D: true })}
								/>
								<ModalAdminCustom
									openModal={modalOpenPhoto3D_1}
									id={1}
									defaultType={2}
									name="photo3D"
									handleClose={() => setModalOpenPhoto3D_1(false)}
									setTouched={() => !touched.photoSchematic_0 && setTouched({ ...touched, photo3D: true })}
								/>
								<ModalAdminCustom
									openModal={modalOpenPhoto3D_2}
									id={2}
									defaultType={2}
									name="photo3D"
									handleClose={() => setModalOpenPhoto3D_2(false)}
									setTouched={() => !touched.photoSchematic_0 && setTouched({ ...touched, photo3D: true })}
								/>
								<ModalLocationEdit
									openModal={modalOpenLocation}
									name="location"
									handleClose={() => setModalLocation(false)}
									setTouched={() => !touched.location && setTouched({ ...touched, location: true })}
								/>
								<ModalAdmin isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
									<Container maxWidth="xl" sx={{ mb: 4 }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography
												fontSize="24px"
												sx={{
													lineHeight: {
														md: '24px',
														xs: '18px',
													},
													my: '30px',
													fontWeight: '600',
												}}
											>
												Add application example
											</Typography>
											<Button sx={{ fontSize: '20px', color: 'black' }} onClick={() => setModalOpen(false)}>
												X
											</Button>
										</Box>
										<Box>
											<Typography fontWeight="600">Photo of application</Typography>
											<Typography sx={{ mb: 2 }}>
												Please resize the picture to exactly 1920x1080px before uploading it
											</Typography>
											<Box
												width="100%"
												sx={{
													justifyContent: 'center',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<FileInput
													name={`application[${applicationId}].image`}
													label=""
													maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
													setTouched={() => !touched.application && setTouched({ ...touched, application: true })}
													ThumbTrigger
													previewImg={
														values?.application[applicationId]?.image?.preview ||
														values?.application[applicationId]?.application_image_url
													}
												/>
											</Box>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Application name
											</Typography>
											<FieldFormik
												type="text"
												placeholder="ABC Waters design features, etc.."
												name={`application[${applicationId}.title]`}
												readonly={false}
												maxLength={127}
											/>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Description
											</Typography>
											<Textarea
												name={`application[${applicationId}.description]`}
												placeholder="Enter a description..."
												maxLength={500}
												value={values.application[applicationId]?.description}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex-end',
												mt: '10px',
											}}
										>
											<Button
												sx={{ mr: '10px' }}
												onClick={() => {
													clearValues('application', applicationId, values.application?.[applicationId]);
													setModalOpen(false);
												}}
												variant="contained"
											>
												Delete
											</Button>
											<Button onClick={() => setModalOpen(false)} variant="contained">
												Apply
											</Button>
										</Box>
									</Container>
								</ModalAdmin>
							</Form>
						);
					}}
				</Formik>
			</section>
		</AdminLayout>
	);
};
export default AdminEditMeasure;
