import React, { useCallback } from 'react'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FieldFormik,
  Fieldrow
} from 'components/atoms'

import { defaultInitialCriteriaValues, criteriaSchema } from './criteriaSchema'

const CriteriaModal = (props: any) => {
  const { isOpen = false, onCancel, onOk, initialValues } = props;

  const handleSubmit = useCallback(async (values: any, action: any) => {
    try {
      onOk && await onOk(values);
    } catch (err) {
      action.setSubmitting(false);
    }
  }, [onOk])

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {initialValues ? 'Edit' : 'Add'} Criteria
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues || defaultInitialCriteriaValues}
          validationSchema={criteriaSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Fieldrow name="name">
                    <FieldFormik type="text" label="Criteria name" placeholder="Enter a criteria name" name="name" readonly={false} />
                  </Fieldrow>
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px', gap: '8px' }}>
                  <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                  <LoadingButton variant="contained" type='submit' loading={isSubmitting} disabled={!dirty || !isValid}>
                    Save
                  </LoadingButton>
                </Box>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CriteriaModal;
