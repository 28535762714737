import { Box } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
// import 'highlight.js/styles/monokai-sublime.css';

export interface ITextEditorProps {
	value?: string;
	readonly?: boolean;
	toolbar?: boolean | any[];
	theme?: 'snow' | 'bubble';
	onchange?: any;
	maxLength?: number;
}

const defaultToolbarOptions = [
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['blockquote', 'code-block'],
	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
	[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
	[{ direction: 'rtl' }], // text direction

	[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ font: [] }],
	[{ align: [] }],

	['clean'], // remove formatting button
];

const TextEditor: React.FC<ITextEditorProps> = props => {
	const { value, readonly = false, toolbar = defaultToolbarOptions, theme = 'snow', onchange, maxLength } = props;

	const reactQuillRef = React.useRef<ReactQuill>(null);

	const newModules = {
		toolbar,
	};

	const handleChange = (...params: any) => {
		const quill = reactQuillRef.current?.getEditor();
		if (quill && maxLength) {
			if (quill.getLength() > maxLength) {
				quill.deleteText(maxLength - 1, quill.getLength());
			}
			params[0] = reactQuillRef.current?.unprivilegedEditor?.getHTML();
		}
		onchange(...params);
	};

	return (
		<Box>
			<ReactQuill
				className=""
				ref={reactQuillRef}
				theme={theme}
				value={value}
				modules={newModules}
				readOnly={readonly}
				preserveWhitespace
				onChange={handleChange}
			/>
			{maxLength && (
				<Box mt={1} color="#475467" fontSize="14px">{`${reactQuillRef.current?.unprivilegedEditor?.getLength() || 0}/${maxLength}`}</Box>
			)}
		</Box>
	);
};

export default TextEditor;
