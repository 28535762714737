import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { icon_guarding } from 'assets/icons';
import { impact_background, More_about_toolkit_background, Union_background } from 'assets/images';
import Image from 'components/atoms/Image';
import { Layout } from 'components/organisms';
import { useResponsive } from 'constants/hooks';
import { useGetHomepageData } from 'modules/home';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = ({}) => {
	const isMobile = useResponsive('down', 'md');
	const { data } = useGetHomepageData();

	return (
		<Layout>
			<Container maxWidth="xl" sx={{ p: '0px !important' }}>
				<Box
					sx={{
						padding: '20px',
						backgroundImage: `url(${Union_background})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
					}}
				>
					<Grid container p="64px 0px 0px" justifyContent="space-around">
						<Grid item xs={12} md={5} sm={5} display="flex" flexDirection="column" justifyContent="center">
							<Typography
								variant="h4"
								fontSize="48px"
								fontWeight="600"
								mb="24px"
								sx={{
									lineHeight: {
										md: '58px',
										xs: '50px',
									},
									LetterSpace: '-0.02em',
									wordBreak: 'break-word',
								}}
								dangerouslySetInnerHTML={{ __html: data?.hero_banner_homepage?.headline || '' }}
							></Typography>
							<Typography
								fontSize="18px"
								fontWeight="400"
								sx={{
									lineHeight: {
										md: '26px',
										sm: '26px',
										xs: '20px',
									},
									color: '#6C737F',
									marginBottom: '20px',
								}}
								dangerouslySetInnerHTML={{ __html: data?.hero_banner_homepage?.sub_headline || '' }}
							></Typography>
						</Grid>
						<Grid item xs={12} md={6} sm={6}>
							<Fade in={true} timeout={1200}>
								<Box
									sx={{
										position: 'relative',
										paddingLeft: {
											sm: '0px',
											md: '64px',
										},
									}}
									textAlign="right"
									component="div"
									height="100%"
								>
									<Box
										borderRadius={{ xs: '16px', md: '30px' }}
										maxWidth="560px"
										maxHeight="560px"
										height="100%"
										width="100%"
										overflow="hidden"
										sx={{ aspectRatio: '1' }}
									>
										<Image src={data?.hero_banner_homepage?.image} className="image-cover" alt="demo" />
									</Box>
									<Box
										textAlign="left"
										sx={{
											position: 'absolute',
											top: {
												xs: '80%',
												md: '60%',
											},
											left: '0px',
											width: {
												xs: 'calc(100% - 20px)',
												md: '45%',
											},
											marginLeft: {
												xs: '10px',
												md: '0px',
											},
											background: '#ffff',
											boxShadow: '0 3px 14px 0.2px rgb(0 0 0 / 20%)',
											borderBottomLeftRadius: '30px',
											borderTopRightRadius: '30px',
											backdropFilter: 'blur(8px)',
										}}
									>
										<Box
											sx={{
												padding: '24px',
												display: 'flex',
												alignItems: 'center',
												flexDirection: {
													xs: 'column',
													md: 'row',
												},
											}}
										>
											<IconButton
												disableRipple
												sx={{
													padding: '8px 0px',
													marginRight: '5px',
													transition: 'transform .7s ease-in-out',
													cursor: 'default !important',
												}}
											>
												<img src={icon_guarding} alt="search_svg" />
											</IconButton>
											<Box>
												{data?.hero_banner_homepage?.floating_container?.split('\r')?.map((it, i) => {
													const list = it.split(' ');
													return (
														<Typography
															key={i}
															sx={{
																lineHeight: '30px',
																color: '6C737F',
																fontSize: '16px',
																fontFamily: 'Inter',
															}}
														>
															<span style={{ fontWeight: 'bold', color: '#2E90FA' }}>{list[0]}</span>{' '}
															{list.slice(1).join(' ')}
														</Typography>
													);
												})}
											</Box>
										</Box>
									</Box>
								</Box>
							</Fade>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ backgroundColor: '#F9FAFB', pt: '50px' }}>
					<Container
						maxWidth="md"
						sx={{
							textAlign: 'center',
						}}
					>
						<Typography
							mb="20px"
							sx={{
								fontFamily: 'Inter',
								fontWeight: '600',
								fontSize: '16px',
								lineHeight: '24px',
								color: '#2E90FA',
							}}
						>
							{data?.impact_section_homepage?.name}
						</Typography>
						<Typography
							fontWeight={600}
							fontSize="40px"
							mb="20px"
							sx={{
								lineHeight: {
									md: '48px',
									xs: '40px',
								},
								color: '#101828',
							}}
							dangerouslySetInnerHTML={{ __html: data?.impact_section_homepage?.headline || '' }}
						></Typography>
						<Typography
							fontWeight={400}
							fontSize="16px"
							sx={{
								lineHeight: {
									md: '24px',
									sm: '24px',
									xs: '20px',
								},
								fontFamily: 'Inter',
								color: '#6C737F',
								fontStyle: 'normal',
							}}
							dangerouslySetInnerHTML={{ __html: data?.impact_section_homepage?.body || '' }}
						></Typography>
					</Container>
					<Box
						component="div"
						mt="50px"
						mb="100px"
						sx={{
							backgroundImage: `url(${impact_background})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							backgroundPosition: 'center',
						}}
					>
						<Box
							maxWidth="md"
							sx={{ margin: 'auto' }}
							borderRadius={{ xs: '16px', md: '30px' }}
							overflow="hidden"
							maxHeight="500px"
							width="100%"
						>
							<Image src={data?.impact_section_homepage?.image} alt="demo" className="image-cover" />
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						padding: '20px 16px',
						margin: '50px 0',
						backgroundImage: `url(${More_about_toolkit_background})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						// backgroundPosition: 'center bottom'
					}}
				>
					<Grid container mt="30px" justifyContent="space-evenly" alignItems="center">
						{!isMobile && (
							<Grid item xs={12} md={5} sm={5}>
								<Box
									component="div"
									textAlign="right"
									maxHeight="560px"
									borderRadius={{ xs: '16px', md: '30px' }}
									overflow="hidden"
								>
									<Image src={data?.toolkit_section_homepage?.image} className="image-cover" alt="demo" />
								</Box>
							</Grid>
						)}
						<Grid
							item
							xs={12}
							md={5}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="flex-start"
						>
							<Typography
								mb="20px"
								sx={{
									fontFamily: 'Inter',
									fontWeight: '600',
									fontSize: '16px',
									lineHeight: '24px',
									color: '#2E90FA',
								}}
							>
								{data?.toolkit_section_homepage?.name}
							</Typography>
							<Typography
								fontWeight={600}
								fontSize="40px"
								sx={{
									lineHeight: {
										md: '48px',
										xs: '34px',
									},
									color: '#101828',
								}}
								dangerouslySetInnerHTML={{ __html: data?.toolkit_section_homepage?.headline || '' }}
							></Typography>
							<Typography
								mb="8px"
								fontWeight={400}
								fontSize="16px"
								sx={{
									lineHeight: {
										md: '24px',
										sm: '24px',
										xs: '20px',
									},
									fontFamily: 'Inter',
									color: '#6C737F',
									fontStyle: 'normal',
								}}
								dangerouslySetInnerHTML={{ __html: data?.toolkit_section_homepage?.body || '' }}
							></Typography>
							<Box
								sx={{
									background: '#F5FAFF',
									borderRadius: '15px',
									width: '100%',
									padding: '15px',
									my: '15px',
									gap: '10px',
								}}
							>
								<Typography
									mb="5px"
									fontWeight={600}
									fontSize="16px"
									sx={{
										lineHeight: {
											md: '24px',
											sm: '24px',
											xs: '20px',
										},
										fontFamily: 'Inter',
										color: '#1570EF',
										fontStyle: 'normal',
									}}
									dangerouslySetInnerHTML={{ __html: data?.toolkit_section_bonus_homepage?.headline || '' }}
								></Typography>
								<Typography
									fontWeight={400}
									fontSize="16px"
									sx={{
										lineHeight: {
											md: '24px',
											sm: '24px',
											xs: '20px',
										},
										fontFamily: 'Inter',
										color: '#2E90FA',
										fontStyle: 'normal',
									}}
									dangerouslySetInnerHTML={{ __html: data?.toolkit_section_bonus_homepage?.body || '' }}
								></Typography>
							</Box>
							<Link to="/toolkit" style={{ width: '100%' }}>
								<Button
									variant="contained"
									sx={{
										mt: '20px',
										width: {
											xs: '100%',
											md: '200px',
										},
										padding: {
											md: '6px 16px',
											xs: '12px 20px',
										},
										mb: {
											xs: '20px',
											md: '0px',
										},
									}}
								>
									<Typography
										fontWeight={400}
										fontSize="16px"
										sx={{
											lineHeight: {
												md: '24px',
												sm: '24px',
												xs: '20px',
											},
											fontFamily: 'Inter',
											color: '#FFFFFF',
											fontStyle: 'normal',
											fontSize: '16px',
											fontWeight: '600',
										}}
									>
										{data?.toolkit_section_bonus_homepage?.button}
									</Typography>
								</Button>
							</Link>
							{isMobile && (
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									width="100%"
									maxWidth="400px"
									margin="auto"
									borderRadius={{ xs: '16px', md: '30px' }}
									overflow="hidden"
									sx={{ aspectRatio: '1' }}
								>
									<Image src={data?.toolkit_section_homepage?.image} className="image-cover" alt="demo" />
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Layout>
	);
};

export default HomePage;
