import { mixed, object, string } from 'yup';

export const filterSchema = object({
	name: string().required('Category name is required'),
	description: string().required('Description is required'),
	image: mixed().required('please upload Image'),
});

export const defaultInitialFilterValues = {
	name: '',
	description: '',
	image: undefined,
};
