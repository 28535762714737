import { Box, Button, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

type Modifier = 'foo' | 'bar';

interface Props {
	modifiers?: Modifier | Modifier[];
	name: any;
	label: string;
	maxsize: string;
	setTouched?: () => void;
	ThumbTrigger?: boolean;
	description?: string;
	previewImg?: string;
}
const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 1,
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 4,
	marginRight: 4,
	width: 100,
	height: 100,
	padding: '2px',
	boxSizing: 'border-box',
};

const buttonStyling = {
	background: '#FCFCFD',
	border: '1px dashed #E5E7EB',
	borderRadius: '12px',
	display: 'flex',
	flexDirection: 'column',
	padding: '40px',
	width: '100%',
};

const MAX_FILE_SIZE = 5242880;
function sizeValidator(file: File) {
	if (file.size > MAX_FILE_SIZE) {
		return {
			code: 'name-too-large',
			message: `The uploaded image ${file.name} exceeded the maximum image size (5 MB).`,
		};
	}

	return null;
}

export const FileInput: React.FC<Props> = props => {
	const { ThumbTrigger = true, previewImg } = props;
	const { setFieldValue } = useFormikContext<any>();
	const [file, setFile] = useState<any>();
	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		accept: {
			'image/jpeg': [],
			'image/png': [],
		},
		multiple: false,
		noClick: true,
		noKeyboard: true,
		validator: sizeValidator,
		onDrop: (acceptedFiles: any) => {
			props.setTouched && props.setTouched();
			acceptedFiles.length && setFieldValue(props.name, acceptedFiles[0]);
			setFile(
				Object.assign(acceptedFiles[0], {
					preview: URL.createObjectURL(acceptedFiles[0]),
				})
			);
		},
	});

	return (
		<Box {...getRootProps()}>
			<Button sx={buttonStyling} className="a-fileinput_button" onClick={open}>
				+
				<p style={{ color: '#175CD3', margin: '0px' }}>
					Click to upload<span style={{ color: '#475467' }}> or drag and drop</span>
				</p>
				<input {...getInputProps()} className="a-fileinput_input" name={props.name} type="file" />
				<p style={{ color: '#475467', marginBottom: '0px' }}>
					{props.label}&nbsp;{props.maxsize}
				</p>
				<p style={{ color: '#475467' }}>{props.description}</p>
			</Button>
			{fileRejections[0]?.errors?.[0]?.message && (
				<Typography mt={1} color="red">
					{fileRejections[0].errors[0].message}
				</Typography>
			)}
			<Box>
				{((ThumbTrigger && file?.preview) || (!file?.preview && previewImg)) && (
					<Box sx={thumbsContainer}>
						<Box sx={thumb}>
							<img src={file?.preview || previewImg} alt="fileinput" className="image-contain" />
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default FileInput;
