import { Measure } from 'modules/measure';
import { createContext, useCallback, useContext, useState } from 'react';

const CompareContext = createContext<{
	showCompareTab: boolean;
	compareData: (Measure | undefined)[];
	handleAddCompareData: (value: Measure, index?: number) => void;
	handleRemoveCompareData: (value: Measure, index: number) => void;
	handleShowCompareTab: (value: boolean) => void;
}>({
	showCompareTab: false,
	compareData: [],
	handleAddCompareData: () => null,
	handleRemoveCompareData: () => null,
	handleShowCompareTab: () => null,
});

function CompareProvider({ children }: any) {
	const [showCompareTab, setShowCompareTab] = useState(false);
	const [compareData, setCompareData] = useState<(Measure | undefined)[]>([]);

	const handleAddCompareData = useCallback((measure: Measure, index?: number) => {
		setCompareData(prev => {
			if (typeof index === 'number') {
				prev[index] = measure;
				return [...prev];
			}
			if (prev.find(it => it?.category?.id === measure.category.id)) {
				for (let i = 0; i < 3; i++) {
					if (!prev[i] || prev[i]?.id === measure.id) {
						prev[i] = measure;
						break;
					} else if (i === 2 && prev[i]) {
						prev[2] = measure;
					}
				}
			} else {
				prev = [measure];
			}
			return [...prev];
		});
	}, []);

	const handleRemoveCompareData = useCallback((measure: Measure, index: number) => {
		setCompareData(prev => {
			prev[index] = undefined;
			return [...prev];
		});
	}, []);

	const handleShowCompareTab = useCallback((value: boolean) => {
		setShowCompareTab(value);
	}, []);

	return (
		<CompareContext.Provider
			value={{ compareData, handleAddCompareData, handleRemoveCompareData, showCompareTab, handleShowCompareTab }}
		>
			{children}
		</CompareContext.Provider>
	);
}

const useCompareContext = () => {
	const context = useContext(CompareContext);
	if (!context) {
		throw new Error('Context must be used within provided!');
	}
	return context;
};

export { CompareProvider, useCompareContext };
