import { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Dialog, DialogContent, DialogTitle, IconButton,
  InputLabel, Stack
} from '@mui/material';
import {
  FieldFormik,
  Fieldrow,
  Select
} from 'components/atoms';
import { Formik } from 'formik';

import { defaultInitialUserValues, userSchema } from './userSchema';

const UserModal = (props: any) => {
  const { isOpen = false, onCancel, onOk, initialValues, options = {} } = props;

  const handleSubmit = useCallback(async (values: any, action: any) => {
    try {
      onOk && await onOk(values);
    } catch (err) {
      action.setSubmitting(false);
    }
  }, [onOk])

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {initialValues ? 'Edit' : 'Add'} User
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues || defaultInitialUserValues}
          validationSchema={userSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Fieldrow name="full_name">
                    <FieldFormik type="text" label="Username" placeholder="Add user's name" name="full_name" readonly={false} />
                  </Fieldrow>
                  <Fieldrow name="email">
                    <FieldFormik type="text" label="Email" placeholder="Add email address" name="email" readonly={!!initialValues} disabled={!!initialValues} />
                  </Fieldrow>
                  <Box>
                    <InputLabel sx={{ marginBottom: 1 }}>Organisation</InputLabel>
                    <Select name="organization_id">
                      <option disabled value="">Select Organisation</option>
                      {options.organizations && options.organizations.map((item: any, idx: number) => (
                        <option value={item.value} key={idx}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Fieldrow name="title">
                    <FieldFormik type="text" label="Designation" placeholder="Engineer,..." name="title" readonly={false} />
                  </Fieldrow>
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px', gap: '8px' }}>
                  <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                  <LoadingButton variant="contained" type='submit' loading={isSubmitting} disabled={!dirty || !isValid}>
                    {initialValues ? 'Save' : 'Send Invitation'}
                  </LoadingButton>
                </Box>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UserModal;
