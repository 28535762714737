import { Box, Container, Typography } from '@mui/material';
import { background_AdaptionMeasures, background_Compare, background_Introduction } from 'assets/images';
import { HorizontalTab } from 'components/molecules';
import { CategoriesTab, CompareTab, Layout } from 'components/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers';
import MeasurePage from '../Measure/index';

const CategoriesPage: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState(1);

	const handleChangeTab = (value: number) => {
		navigate(ROUTES.CATEGORIES, { state: { tab: value } });
	};

	useEffect(() => {
		const tab = location.state?.tab;
		if (Number.isInteger(tab)) {
			setCurrentTab(tab);
			if (tab !== 2) {
				window.history.replaceState({}, document.title);
			}
		}
	}, [location.state]);

	const tabData = useMemo(
		() => [
			{
				label: 'Introduction',
				content: <CategoriesTab />,
				description:
					'Explore adaptation measures by categories and compare them to choose the most relevant solutions for your site.',
				backgroundImage: background_Introduction,
				backgroundColor: '',
			},
			{
				label: 'Adaptation Measures',
				content: <MeasurePage />,
				description:
					'Explore adaptation measures by categories and compare them to choose the most relevant solutions for your site.',
				backgroundImage: background_AdaptionMeasures,
				backgroundColor: '',
			},
			{
				label: 'Compare',
				content: <CompareTab />,
				description:
					'Explore adaptation measures by categories and compare them to choose the most relevant solutions for your site.',
				backgroundImage: background_Compare,
				backgroundColor: '#F5FAFF',
			},
		],
		[]
	);

	return (
		<Layout>
			<Container
				sx={{
					py: '80px',
					textAlign: 'center',
					backgroundImage: `url(${tabData[currentTab].backgroundImage})`,
					backgroundSize: 'cover',
					backgroundColor: tabData[currentTab].backgroundColor,
				}}
				maxWidth="xl"
			>
				<Box maxWidth="md" sx={{ textAlign: 'center', margin: 'auto' }}>
					<Typography fontWeight={600} fontSize="48px" lineHeight="56px" fontFamily="Inter">
						{tabData[currentTab].label}
					</Typography>
					<Typography
						variant="subtitle1"
						fontSize="18px"
						mt="24px"
						fontFamily="Inter"
						sx={{
							lineHeight: {
								md: '26px',
								sm: '26px',
								xs: '20px',
							},
							color: '#667085',
						}}
					>
						{tabData[currentTab].description}
					</Typography>
				</Box>
			</Container>
			<Container maxWidth="xl" sx={{ px: '0 !important' }}>
				<HorizontalTab tabs={tabData} currentTab={currentTab} handleChangeTab={handleChangeTab} />
			</Container>
		</Layout>
	);
};
export default CategoriesPage;
