import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { STORAGE_KEYS } from 'constants/api';
import { Axios } from 'core/httpServices';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers';
import { QK_PROFILE } from './consts';
import {
	createPassword,
	forgotPassword,
	getUserProfile,
	login,
	profileResetPassword,
	resendVerifyEmail,
	resetPassword,
	updateUserProfile,
} from './services';
import { Profile } from './types';

export const useGetUserProfile = () => {
	return useQuery<any, any, Profile>([QK_PROFILE], getUserProfile);
};

export const useLogin = () => {
	const navigate = useNavigate();

	return useMutation(login, {
		onSuccess: data => {
			const { tokens } = data;
			Axios.addHeader('Authorization', `Bearer ${tokens.access_token}`);
			localStorage.setItem(STORAGE_KEYS.AccessToken, tokens.access_token);
			localStorage.setItem('role', data.user.role);
			navigate(ROUTES.HOME);
		},
	});
};

export const useForgotPassword = () => {
	return useMutation(forgotPassword);
};

export const useResendVerifyEmail = () => {
	return useMutation(resendVerifyEmail);
};

export const useCreatePassword = () => {
	return useMutation(createPassword);
};

export const useResetPassword = () => {
	return useMutation(resetPassword);
};

export const useProfileResetPassword = () => {
	return useMutation(profileResetPassword);
};

export const useUpdateUserProfile = () => {
	const queryClient = useQueryClient();
	return useMutation(updateUserProfile, {
		onSuccess: () => {
			queryClient.invalidateQueries([QK_PROFILE]);
		},
	});
};
