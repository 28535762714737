import { Box, Container } from '@mui/material';
import { MeasureItemList } from 'components/organisms/Measure';
import { current } from 'immer';
import { cloneDeep } from 'lodash';
import { useGetMeasures, useGetTagTypes } from 'modules/measure';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { MeasureSidebarFilter } from '../../organisms/Filter/index';

const MeasurePage: React.FC = () => {
	const location = useLocation();
	const { data: tagTypes = [] } = useGetTagTypes();
	const { data: measures = [], isLoading: loadingMeasures } = useGetMeasures();
	const [showFilterDialog, setShowFilterDialog] = useState(false);
	const [filter, setFilter] = useImmer<{ id: string; checked: boolean; disabled: boolean }[][]>([]);
	const [filterData, setFilterData] = useState<typeof measures[0][]>([]);
	const [cloneMeasures, setCloneMeasures] = useState<typeof measures[0][]>([]);
	const [filteringData, setFilteringData] = useState(false);

	useEffect(() => {
		if (tagTypes.length) {
			setFilter(draft => {
				tagTypes.forEach((it, index) => {
					draft[index] = [];
					it.tags.forEach(tag => {
						draft[index].push({ id: tag.id, checked: location.state?.filterId === tag.id, disabled: false });
					});
				});
			});
		}
	}, [tagTypes.length]);

	useEffect(() => {
		if (measures.length) {
			const cloneMeasures = cloneDeep(measures);
			setFilterData(cloneMeasures);
			setCloneMeasures(cloneMeasures);
		}
	}, [measures.length]);

	// filter default
	useEffect(() => {
		if (tagTypes.length && cloneMeasures.length && location.state?.filterId) {
			setFilterData(cloneMeasures.filter(measure => measure.all_tags.includes(location.state.filterId)));
			location.state.filterId = null;
			window.history.replaceState({}, document.title);
		}
	}, [tagTypes.length, cloneMeasures.length, location.state?.filterId]);

	useEffect(() => {
		if (filterData.length) {
			setFilter(draft => {
				draft.forEach((x, pId) => {
					if (x.some(y => y.checked)) {
						const checkedFilter = getCheckedFilter(current(draft));
						let filterDataForDisabledFilter: typeof cloneMeasures;
						if (checkedFilter.length > 1) {
							filterDataForDisabledFilter = cloneMeasures.filter(measure =>
								checkedFilter.some(filter => measure.all_tags.some(tag => filter.includes(tag)))
							);
						} else {
							filterDataForDisabledFilter = cloneMeasures;
						}

						const checkedFilterWithoutParent = getCheckedFilter(current(draft), pId);
						x.forEach((y, cId) => {
							if (y.checked) return;
							const isEnabled = filterDataForDisabledFilter.some(
								measure =>
									measure.all_tags.includes(y.id) &&
									checkedFilterWithoutParent.every(z => measure.all_tags.some(tag => z.includes(tag)))
							);
							draft[pId][cId].disabled = !isEnabled;
						});
					} else {
						x.forEach((y, cId) => {
							const isEnabled = filterData.some(data => data.all_tags.includes(y.id));
							draft[pId][cId].disabled = !isEnabled;
						});
					}
				});
			});
		}
	}, [filterData]);

	const handleShowFilterDialog = () => setShowFilterDialog(true);
	const handleCloseFilterDialog = () => setShowFilterDialog(false);

	const getCheckedFilter = (data: typeof filter, removeParentCategory?: number) => {
		return data
			.filter(
				(x, pId) =>
					x.some(y => y.checked) && (typeof removeParentCategory === 'number' ? !(removeParentCategory === pId) : true)
			)
			.map(x => x.filter(y => y.checked).map(y => y.id));
	};

	const handleChangeFilter = (parentIndex: number, childIndex: number, checked: boolean) => {
		setFilter(draft => {
			draft[parentIndex][childIndex].checked = checked;
			const checkedFilter = getCheckedFilter(current(draft));

			setFilteringData(true);
			setFilterData(
				cloneMeasures.filter(measure =>
					checkedFilter.every(filter => measure.all_tags.some(tag => filter.includes(tag)))
				)
			);
			new Promise(res =>
				setTimeout(() => {
					res(setFilteringData(false));
				}, 500)
			);
		});
	};

	const handleClearFilter = () => {
		setFilter(draft => {
			draft.forEach((x, pId) => {
				x.forEach((y, cId) => {
					draft[pId][cId].checked = false;
				});
			});
		});
		setFilterData(cloneMeasures);
	};

	return (
		<Container maxWidth="lg" sx={{ display: 'flex' }}>
			<MeasureSidebarFilter
				open={showFilterDialog}
				onClose={handleCloseFilterDialog}
				tagList={tagTypes}
				filter={filter}
				handleChangeFilter={handleChangeFilter}
				handleClearFilter={handleClearFilter}
			/>
			<Box
				sx={{
					flexGrow: 1,
					width: '100%',
					pl: { md: 4 },
				}}
			>
				<MeasureItemList
					handleShowFilterDialog={handleShowFilterDialog}
					data={filterData}
					loading={loadingMeasures || filteringData}
				/>
			</Box>
		</Container>
	);
};

export default MeasurePage;
