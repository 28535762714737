import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { icon_tooltip, ViewMoreSVG } from 'assets/icons';
import { Tooltips } from 'components/atoms';
import { useGetTagTypes } from 'modules/measure';
import { NetworkContext } from 'providers/context';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers';

const CategoriesTab: React.FC = () => {
	const { data: value = [] } = useGetTagTypes();
	const navigate = useNavigate();
	const [filters, setFilters] = useState<any>({ categories: [] });
	const [stateImageDetail, setStateImageDetail] = useState<any>({ ids: [] });
	const { isOnline } = useContext(NetworkContext);
	const [hoverButton3D_diagram_1, setHoverButton3D_diagram_1] = useState<number | null>(null);

	const ButtonStyling = {
		justifyContent: 'space-between',
		borderRadius: '10px',
		p: '14px',
		m: '10px 10px 0 10px',
		width: '100%',
		fontSize: '16px',
	};

	const handleChangeMenu = (value: any, res: any) => {
		setFilters({
			categories: value,
			tag_type: res,
		});
	};

	const handleOnChangeImageDetail = (order: number, id: number) => {
		stateImageDetail.ids[id] = { id, order };
		setStateImageDetail({
			...stateImageDetail,
		});
	};

	const handleExploreMeasure = (filterId: string) => {
		navigate(ROUTES.CATEGORIES, { state: { tab: 1, filterId } });
	};

	return (
		<Container maxWidth="xl">
			<Grid
				container
				mb="100px"
				px={{
					xs: '0px',
					md: '3rem',
				}}
			>
				<Grid
					item
					xs={12}
					md={4}
					sm={12}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					sx={{ mt: '50px' }}
				>
					<Box
						sx={{
							px: {
								xs: '0px',
								md: '2rem',
							},
							height: '100%',
						}}
					>
						<Typography
							fontWeight="600"
							lineHeight="17px"
							fontSize="14px"
							sx={{
								textDecoration: 'none',
								color: '#1570EF',
							}}
						>
							INTRODUCTION TO
						</Typography>
						<Typography mt="12px" fontSize="32px" fontWeight="700" lineHeight="40px" mb="24px">
							{value && value.filter((e: any) => e.slug === 'land_use_profiles')[0]?.name}
						</Typography>
						<Typography
							mt="12px"
							fontSize="16px"
							fontWeight="400"
							lineHeight="24px"
							mb="24px"
							sx={{ color: '#6C737F' }}
						>
							{value && value.filter((e: any) => e.slug === 'land_use_profiles')[0]?.description}
						</Typography>
						{/* </Box> */}
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
					sm={12}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					sx={{ mt: '50px' }}
				>
					<Grid
						container
						mb="100px"
						sx={{
							padding: {
								xs: '0px',
								md: '0px 2rem',
							},
						}}
					>
						{value &&
							value !== undefined &&
							value[0]?.tags.map((opt: any, id: number) => (
								<Grid
									key={id}
									item
									xs={12}
									md={6}
									sm={12}
									display="flex"
									flexDirection="column"
									justifyContent="center"
								>
									<Button
										onMouseEnter={() => setHoverButton3D_diagram_1(id)}
										onMouseLeave={() => setHoverButton3D_diagram_1(null)}
										sx={{
											background: isOnline ? `url(${opt.tag_image_url})` : `url(${opt.image})`,
											width: { xs: '100%', md: '95%' },
											backgroundRepeat: 'no-repeat',
											objectFit: 'cover',
											backgroundSize: 'cover',
											height: '240px',
											margin: '10px 0',
											padding: '10px',
											borderRadius: '15px',
											boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.3)',
											border: filters?.categories?.id?.includes(opt.id) ? '3px solid #2E90FA' : '1px solid #E5E7EB',
											flexDirection: 'column',
										}}
										onClick={() => handleChangeMenu(opt, value[0])}
									>
										<Typography
											mt="12px"
											fontSize="18px"
											fontWeight="500"
											lineHeight="28px"
											sx={{
												color: 'white',
												display: 'flex',
												textTransform: 'none!important',
											}}
										>
											<span style={{ margin: '4px' }}>
												{opt.name}
												<Tooltips title={opt.description}>
													<img src={icon_tooltip} style={{ margin: '0 0 2px 4px' }} alt="view_more_svg" />
												</Tooltips>
											</span>
										</Typography>
										{hoverButton3D_diagram_1 === id && (
											<Fade in={true} timeout={400}>
												<Button onClick={() => handleExploreMeasure(opt.id)}>
													<Typography
														mt="12px"
														fontSize="13px"
														fontWeight="300"
														lineHeight="18px"
														mb="24px"
														sx={{ color: 'white', textDecoration: 'underline' }}
													>
														Explore measure -&gt;
													</Typography>
												</Button>
											</Fade>
										)}
									</Button>
								</Grid>
							))}
					</Grid>
				</Grid>
				{value &&
					value !== undefined &&
					value
						.filter((e: any) => e.slug !== 'land_use_profiles')
						.map((item: any, id: number) => (
							<Box
								key={id}
								sx={{
									display: 'flex',
									flexDirection: {
										xs: 'column',
										md: 'row',
									},
								}}
							>
								<Grid
									item
									xs={12}
									md={4}
									sm={12}
									display="flex"
									flexDirection="column"
									justifyContent="center"
									my="2rem"
									sx={{ borderBottom: '1px solid #E5E7EB', pb: '50px' }}
								>
									<Box
										sx={{
											px: { xs: '0px', md: '2rem' },
											height: '100%',
											display: 'flex',
											flexWrap: 'wrap',
											alignContent: 'space-between',
										}}
									>
										<div>
											<Typography
												fontWeight="600"
												lineHeight="17px"
												fontSize="14px"
												sx={{
													textDecoration: 'none',
													color: '#1570EF',
												}}
											>
												INTRODUCTION TO
											</Typography>
											<Typography mt="12px" fontSize="32px" fontWeight="700" lineHeight="40px" mb="24px">
												{item.name}
											</Typography>
											<Typography
												mt="12px"
												fontSize="16px"
												fontWeight="400"
												lineHeight="24px"
												mb="24px"
												sx={{ color: '#6C737F' }}
											>
												{item.description}
											</Typography>
										</div>
										<Container sx={{ p: '0px!important' }}>
											{item &&
												item !== undefined &&
												item.tags.map((opt: any, i: number) => (
													<Button
														sx={{
															...ButtonStyling,
															background:
																stateImageDetail?.ids[id]?.id === id && stateImageDetail?.ids?.length > 0
																	? stateImageDetail?.ids[id]?.order === i
																		? '#F5FAFF'
																		: '#fffff'
																	: i === 0
																	? '#F5FAFF'
																	: '#fffff',

															border:
																stateImageDetail?.ids[id]?.id === id && stateImageDetail?.ids?.length > 0
																	? stateImageDetail?.ids[id]?.order === i
																		? '1px solid #2E90FA'
																		: '1px solid #E5E7EB'
																	: i === 0
																	? '1px solid #2E90FA'
																	: '1px solid #E5E7EB',
															color:
																stateImageDetail?.ids[id]?.id === id && stateImageDetail?.ids?.length > 0
																	? stateImageDetail?.ids[id]?.order === i
																		? ''
																		: '#000000'
																	: i === 0
																	? ''
																	: '#000000',
															marginLeft: {
																xs: '0px',
																md: '10px',
															},
														}}
														key={opt.id}
														onClick={() => handleOnChangeImageDetail(i, id)}
													>
														{opt.name} <img src={ViewMoreSVG} alt="view_more_svg" />
													</Button>
												))}
										</Container>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									md={8}
									sm={12}
									display="flex"
									flexDirection="column"
									justifyContent="center"
									my="2rem"
									sx={{ borderBottom: '1px solid #E5E7EB', pb: '50px' }}
								>
									{stateImageDetail?.ids[id]?.id === id && stateImageDetail?.ids?.length > 0 ? (
										<img
											style={{ width: '100%' }}
											src={
												isOnline
													? item?.tags[stateImageDetail?.ids[id]?.order]?.tag_image_url
													: item?.tags[stateImageDetail?.ids[id]?.order]?.image
											}
											alt="HardEngineering_1"
										/>
									) : (
										<img
											style={{ width: '100%' }}
											src={isOnline ? item?.tags[0]?.tag_image_url : item?.tags[0]?.image}
											alt="HardEngineering_1"
										/>
									)}
									<Typography
										mt="12px"
										fontSize="16px"
										fontWeight="400"
										lineHeight="24px"
										mb="24px"
										sx={{ color: '#6C737F', mt: '20px', background: '#F5FAFF', p: '15px', borderRadius: '8px' }}
									>
										{stateImageDetail?.ids[id]?.id === id && stateImageDetail?.ids?.length > 0
											? item?.tags[stateImageDetail?.ids[id]?.order]?.description
											: item?.tags[0]?.description}
									</Typography>
								</Grid>
							</Box>
						))}
				{/* ////////////////////////// */}
				<Divider light />
			</Grid>
		</Container>
	);
};

export default CategoriesTab;
