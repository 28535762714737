// @ts-ignore
import { ApiUrl } from 'constants/api';
import streamSaver from 'streamsaver';

export const downloadToolkit = async () => {
	const url = `${ApiUrl}/toolkits/download`;
	const token = localStorage.getItem('@AccessToken');
	const fileStream = streamSaver.createWriteStream('AdaptationToolkit.zip');
	fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then(res => {
		const readableStream = res.body;

		// more optimized
		// @ts-ignore
		if (window.WritableStream && readableStream.pipeTo) {
			// @ts-ignore
			return readableStream.pipeTo(fileStream).then(() => console.log('done writing'));
		}

		// @ts-ignore
		window.writer = fileStream.getWriter();

		// @ts-ignore
		const reader = res.body.getReader();
		const pump = () =>
			reader.read().then(res =>
				res.done
					? // @ts-ignore
					  writer.close()
					: // @ts-ignore
					  writer.write(res.value).then(pump)
			);

		pump();
	});
};
