import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	Hidden,
	Typography,
} from '@mui/material';
import { MarkSVG } from 'assets/icons';
import { Tooltips } from 'components/atoms';
import { TagType } from 'modules/measure';

interface FilterProps {
	tagList: TagType[];
	filter: { id: string; checked: boolean; disabled: boolean }[][];
	handleChangeFilter: (parentId: number, childId: number, checked: boolean) => void;
	handleClearFilter: () => void;
}

interface SidebarFilterProps extends FilterProps {
	open: boolean;
	onClose: () => void;
}

function MeasureFilter({ tagList, filter, handleChangeFilter, handleClearFilter }: FilterProps) {
	return (
		<Box width={{ xs: '100%', md: 280 }} position="sticky" top={0} flexShrink={0}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography
					sx={{
						color: '#000000',
						fontWeight: '600',
						fontSize: '18px',
						lineHeight: '24px',
					}}
				>
					Filters
				</Typography>
				<Button
					sx={{
						lineHeight: '24px',
						fontWeight: '400',
						padding: 0,
						px: 1,
						':disabled': {
							color: 'black',
							opacity: '0.3',
						},
						fontSize: '16px',
					}}
					disabled={!filter.some(x => x.some(y => y.checked))}
					onClick={handleClearFilter}
				>
					Clear filter
				</Button>
			</Box>
			<Box mt={{ xs: 3, md: 6 }} display="flex" flexDirection="column">
				{tagList.map((item, pIndex) => (
					<Box key={item.id} py={3} borderTop={1} borderColor="#ECECEC">
						<Box pb={1} display="flex" alignItems="center">
							<Typography
								sx={{
									fontFamily: 'Inter',
									lineHeight: '24px',
									display: 'flex',
									color: '#000000',
									fontWeight: '600',
									fontSize: '18px',
									mr: 0.75,
								}}
							>
								{item.name}
							</Typography>
							<Tooltips title={item.description}>
								<Box display="flex">
									<img src={MarkSVG} alt="view_more_svg" />
								</Box>
							</Tooltips>
						</Box>
						{item.tags.map((tag, cIndex) => (
							<FormGroup key={tag.id} sx={{ mt: 1 }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={!!filter?.[pIndex]?.[cIndex]?.checked}
											disabled={!!filter?.[pIndex]?.[cIndex]?.disabled}
											onChange={e => handleChangeFilter(pIndex, cIndex, e.target.checked)}
										/>
									}
									label={tag.name}
								/>
							</FormGroup>
						))}
					</Box>
				))}
			</Box>
		</Box>
	);
}

export default function MeasureSidebarFilter({
	tagList,
	filter,
	handleChangeFilter,
	open,
	onClose,
	handleClearFilter,
}: SidebarFilterProps) {
	return (
		<>
			<Hidden mdDown>
				<MeasureFilter
					tagList={tagList}
					filter={filter}
					handleChangeFilter={handleChangeFilter}
					handleClearFilter={handleClearFilter}
				/>
			</Hidden>
			<Hidden mdUp>
				<Dialog fullScreen scroll="paper" open={open} onClose={onClose}>
					<DialogContent sx={{ marginTop: '5px' }}>
						<MeasureFilter
							tagList={tagList}
							filter={filter}
							handleChangeFilter={handleChangeFilter}
							handleClearFilter={handleClearFilter}
						/>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</Hidden>
		</>
	);
}
