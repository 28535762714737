import { Box, Stack, styled, Typography } from '@mui/material';
import { NetworkContext } from 'providers/context';
import * as React from 'react';
import { useContext } from 'react';
import Carousel from 'react-multi-carousel';

interface CarouselsProps {
	data: any;
}

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 900 },
		items: 2,
		paritialVisibilityGutter: 60,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 900, min: 0 },
		items: 1,
		paritialVisibilityGutter: 0,
		slidesToSlide: 1,
	},
};

const Carousels: React.FC<CarouselsProps> = ({ data }) => {
	const { isOnline } = useContext(NetworkContext);
	return (
		<Box>
			<StyledCarousel responsive={responsive} partialVisible={data?.length > 2}>
				{data &&
					data !== undefined &&
					data?.map((item: any, i: number) => {
						return (
							<Stack key={i} height="100%">
								<Box key={item.name} style={{ height: '280px' }}>
									<img
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
										}}
										alt="carousel"
										src={isOnline ? item?.application_image_url : item?.image}
									/>
								</Box>
								<Box sx={{ background: '#E4E4E4' }} flex={1}>
									<Typography
										variant="subtitle1"
										sx={{
											lineHeight: {
												md: '30px',
												sm: '30px',
												xs: '20px',
											},
											color: '#000000',
											fontFamily: 'Inter',
											fontSize: '18px',
											fontWeight: '600',
											p: '1em',
										}}
									>
										{item?.title}
									</Typography>
									<Typography
										variant="subtitle1"
										sx={{
											lineHeight: {
												md: '30px',
												sm: '30px',
												xs: '20px',
											},
											color: '#6C737F',
											fontFamily: 'Inter',
											fontSize: '14px',
											p: '0 1em 1em 1em',
										}}
									>
										{item?.description}
									</Typography>
								</Box>
							</Stack>
						);
					})}
			</StyledCarousel>
		</Box>
	);
};

const StyledCarousel = styled(Carousel)(({ theme }) => ({
	'.react-multi-carousel-item': {
		margin: '0 8px',
		'&:first-child': {
			marginLeft: '0',
		},
		'&:last-child': {
			marginRight: '0',
		},
		[theme.breakpoints.down('md')]: {
			margin: '0 !important',
		},
	},
}));

export default Carousels;
