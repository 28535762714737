import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminLayout } from 'components/organisms';
import { useDisclosure } from 'constants/hooks/useDisclosure';
import { QUERY_CACHE } from 'constants/query';
import { Axios } from 'core/httpServices';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { CruProvider, useInitialValues, useShowCRUModal } from 'providers/context/modalContext';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import ConfirmModal from '../../molecules/ConfirmModal';
import FilterModal from '../../organisms/FilterModal';
import IntroductionList, { Introduction } from './IntroductionList';

interface AdminSettingProps {}

const AdminSetting: React.FC<AdminSettingProps> = () => {
	const queryClient = useQueryClient();
	const {
		isOpen: isOpenConfirmModal,
		item: confirmedItem,
		open: openConfirmModal,
		close: closeConfirmModal,
	} = useDisclosure();
	const { displayCRUModal, showCRU, closeCRUModal } = useShowCRUModal();
	const [initialValues] = useInitialValues();

	const { data: criteriaList } = useQuery(
		[QUERY_CACHE.INTRODUCTION],
		async () => {
			const data = await Axios.get('tag-types');
			// const data = await Axios.get('criteria-types?limit=1000')
			return data.data;
		},
		{ keepPreviousData: true }
	);

	const { mutateAsync: createOrUpdateCriteriaAction } = useMutation(
		async ({ criteria, action }: { criteria: any; action: string }) => {
			if (action === 'update') {
				return Axios.put(`tag-types/${criteria.id}`, _pick(criteria, ['name', 'description']));
			}
			criteria.is_multi_choice = false;
			return Axios.post(`tag-types`, criteria);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.INTRODUCTION] });
				if (variables.action === 'update') {
					toast.success(`Filter ${variables.criteria.name} has been updated successfully`);
				} else {
					toast.success(`Filter ${variables.criteria.name} has been created successfully`);
				}
				closeCRUModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: deleteCriteriaAction } = useMutation(
		async (criteria: Introduction) => {
			return Axios.delete(`tag-types/${criteria.id}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.INTRODUCTION] });
				toast.success(`Criteria ${variables.name} has been deleted successfully`);
				closeConfirmModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const handleCreateUpdateCriteria = useCallback(
		(values: any) => {
			return createOrUpdateCriteriaAction({
				criteria: values,
				action: values.id ? 'update' : 'create',
			});
		},
		[createOrUpdateCriteriaAction]
	);

	const handleDeleteCriteria = useCallback(
		(criteria: Introduction) => {
			deleteCriteriaAction(criteria);
		},
		[deleteCriteriaAction]
	);
	return (
		<AdminLayout>
			<Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'start',
						marginBottom: 2,
						paddingBottom: 3,
						borderBottom: '1px solid #EAECF0',
					}}
				>
					<Box>
						<Typography variant="h5" fontWeight="bold">
							Introduction of Filter
						</Typography>
					</Box>
					<Button
						disabled={criteriaList?.data?.length === 5}
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => showCRU()}
					>
						Add filter
					</Button>
				</Box>
				<IntroductionList data={criteriaList?.data ?? []} onUpdate={showCRU} onDelete={openConfirmModal} />
			</Box>
			<FilterModal
				isOpen={displayCRUModal}
				onCancel={closeCRUModal}
				onOk={handleCreateUpdateCriteria}
				initialValues={initialValues}
			/>
			<ConfirmModal
				open={isOpenConfirmModal}
				title="Delete filter"
				subject={confirmedItem}
				renderMessage={(item: any) => (
					<Box>
						<Typography
							sx={{
								mb: 2,
								fontSize: 18,
								fontWeight: 'bold',
								span: { color: theme => theme.palette.primary.main },
							}}
						>
							Are you sure you want to delete <span>{_get(item, 'name')}</span> ?
						</Typography>
						<Typography sx={{ color: theme => theme.palette.grey[500] }}>
							Do note that you cannot undo this action.
						</Typography>
					</Box>
				)}
				onCancel={closeConfirmModal}
				onOk={handleDeleteCriteria}
				okProps={{
					children: 'Delete',
				}}
			/>
		</AdminLayout>
	);
};

const AdminSettingProvider = (props: any) => {
	return (
		<CruProvider>
			<AdminSetting {...props} />
		</CruProvider>
	);
};

export default AdminSettingProvider;
