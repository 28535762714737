import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routers } from 'routers';
import { theme } from 'styles/theme';
// minified version is also included
//// import 'react-toastify/dist/ReactToastify.min.css';

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			networkMode: 'offlineFirst',
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<ToastContainer />
			<QueryClientProvider client={queryClient}>
				<HashRouter>
					<Routers />
				</HashRouter>
			</QueryClientProvider>
		</ThemeProvider>
	);
}

export default App;
