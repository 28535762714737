import { mixed, object, string } from 'yup'

export const createSchema = object({
  logo_homepage: mixed(),
  copyright_homepage: string().required('This is a required field'),
  hero_banner_homepage: object().shape({
    headline: string().required('This is a required field'),
    sub_headline: string().required('This is a required field'),
    image: mixed(),
    floating_container: string().required('This is a required field')
  }),
  impact_section_homepage: object().shape({
    name: string().required('This is a required field'),
    headline: string().required('This is a required field'),
    body: string().required('This is a required field'),
    image: mixed()
  }),
  toolkit_section_homepage: object().shape({
    name: string().required('This is a required field'),
    headline: string().required('This is a required field'),
    body: string().required('This is a required field'),
    image: mixed()
  }),
  toolkit_section_bonus_homepage: object().shape({
    headline: string().required('This is a required field'),
    body: string().required('This is a required field'),
    button: string().required('This is a required field')
  })
})
