import { Axios } from 'core/httpServices';
import _get from 'lodash/get';
import { QK_PROFILE } from './consts';
import { LoginInfo, PasswordInfo, VerifyEmailInfo } from './types';

export const getUserProfile = async () => {
	const { data } = await Axios.get(QK_PROFILE);
	return data;
};

export const login = async (params: LoginInfo) => {
	const { email, password } = params;
	const data = {
		email,
		password,
	};
	const resp = await Axios.post('/auth/login', data);
	return _get(resp, 'data', {});
};

export const forgotPassword = async (params: VerifyEmailInfo) => {
	const { email } = params;
	const data = {
		email,
	};
	const resp = await Axios.post('/auth/forgot-password', data);
	return _get(resp, 'data', {});
};

export const resendVerifyEmail = async (params: VerifyEmailInfo) => {
	const { email } = params;
	const data = {
		email,
	};
	const resp = await Axios.post('/auth/resend-verify-email', data);
	return _get(resp, 'data', {});
};

export const createPassword = async (params: PasswordInfo) => {
	const resp = await Axios.post('/auth/password', params);
	return _get(resp, 'data', {});
};

export const resetPassword = async (params: PasswordInfo) => {
	const resp = await Axios.post('/auth/reset-password', params);
	return _get(resp, 'data', {});
};

export const profileResetPassword = async (params: any) => {
	const resp = await Axios.put('/users/change-password', params);
	return _get(resp, 'data', {});
};

export const updateUserProfile = async (params: any) => {
	const resp = await Axios.put('/users/me', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return _get(resp, 'data', {});
};
