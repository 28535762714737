import AddIcon from '@mui/icons-material/Add';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminLayout } from 'components/organisms';
import { USER_STATUS } from 'constants/app';
import { useDisclosure } from 'constants/hooks/useDisclosure';
import { QUERY_CACHE } from 'constants/query';
import { Axios } from 'core/httpServices';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { MRT_ColumnDef } from 'material-react-table';
import { CruProvider, useInitialValues, useShowCRUModal } from 'providers/context/modalContext';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { FiEdit2, FiRefreshCw, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { dateFormat } from 'utils/datetime';
import Switch from '../../atoms/Switch';
import ConfirmModal from '../../molecules/ConfirmModal';
import PaginationTable from '../../molecules/PaginationTable';
import UserModal from '../../organisms/UserModal';

interface AdminUserProps {}

interface User {
	id: string;
	full_name: string;
	email: string;
	title: string;
	organization?: {
		id: string;
		name: string;
	};
	created_at: string;
	status: number;
}

const AdminUsers: React.FC<AdminUserProps> = () => {
	const queryClient = useQueryClient();
	const {
		isOpen: isOpenConfimModal,
		item: confirmedItem,
		open: openConfirmModal,
		close: closeConfirmModal,
	} = useDisclosure();
	const { displayCRUModal, showCRU, closeCRUModal } = useShowCRUModal();
	const [initialValues] = useInitialValues();

	const { mutateAsync: createOrUpdateUserAction } = useMutation(
		async ({ user, action }: { user: any; action: string }) => {
			if (action === 'update') {
				return Axios.put(`users/${user.id}`, _pick(user, ['full_name', 'title', 'organization_id']));
			}
			return Axios.post(`users`, user);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				if (variables.action === 'update') {
					toast.success('Account has been updated successfully');
				} else {
					toast.success(`User ${variables.user.full_name} has been created successfully`);
				}
				closeCRUModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: activeInactiveUserAction } = useMutation(
		async ({ userId, fullName, action }: { userId: string; fullName: string; action: string }) => {
			return Axios.put(`users/${userId}/${action}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				toast.success(`Status of ${variables.fullName} has been updated successfully`);
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: resendEmailAction } = useMutation(
		async ({ userId, email }: { userId: string; email: string }) => {
			return Axios.post(`users/${userId}/resend-verify-email`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				toast.success(`Resent email to ${variables.email}`);
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: deleteUserAction } = useMutation(
		async (user: User) => {
			return Axios.delete(`users/${user.id}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				toast.success(`Account has been deleted successfully`);
				closeConfirmModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { data: organizationsData } = useQuery(
		[QUERY_CACHE.ORG],
		async () => {
			const data = await Axios.get('organizations/all');
			return data.data;
		},
		{ keepPreviousData: true }
	);

	const organizationsOptions = useMemo(() => {
		return (
			organizationsData &&
			organizationsData.data.map((item: any) => ({
				value: item.id,
				label: item.name,
			}))
		);
	}, [organizationsData]);

	const fetchUsers = useCallback(({ pagination, search }: any) => {
		return Axios.get(
			`/users?${qs.stringify({
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				q: search,
			})}`
		);
	}, []);

	const handleCreateUpdateUser = useCallback(
		(values: any) => {
			return createOrUpdateUserAction({
				user: values,
				action: values.id ? 'update' : 'create',
			});
		},
		[createOrUpdateUserAction]
	);

	const handleDeleteUser = useCallback(
		(user: User) => {
			deleteUserAction(user);
		},
		[deleteUserAction]
	);

	const columns = useMemo<MRT_ColumnDef<User>[]>(
		() => [
			{
				accessorKey: 'full_name',
				header: 'Name',
				accessorFn: row => {
					return (
						<Box>
							<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#101828' }}>{row.full_name}</Typography>
							<Typography sx={{ fontSize: 14, color: theme => theme.palette.grey[600] }}>{row.title}</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: row => _get(row, 'organization.name'),
				header: 'Organisation',
			},
			{
				accessorKey: 'email',
				header: 'Email Address',
			},
			{
				id: 'created_at',
				accessorKey: 'created_at',
				header: 'Date Created',
				size: 150,
				accessorFn: row => {
					return row.created_at && dateFormat(row.created_at);
				},
			},
			{
				id: 'activeAction',
				header: '',
				size: 100,
				accessorFn: row => {
					if (row.status === 2 || row.status === 3) {
						return (
							<IconButton
								size="small"
								sx={{ padding: 0 }}
								onClick={() => resendEmailAction({ userId: row.id, email: row.email })}
							>
								<FiRefreshCw fontSize="medium" />
							</IconButton>
						);
					}
					return (
						<Switch
							defaultChecked={row.status === 1}
							onChange={() => {
								activeInactiveUserAction({
									userId: row.id,
									fullName: row.full_name,
									action: row.status === 1 ? 'inactive' : 'active',
								});
							}}
						/>
					);
				},
			},
			{
				accessorKey: 'status',
				header: 'Status',
				size: 150,
				accessorFn: row => (
					<Box
						sx={{
							'& .MuiChip-colorPrimary': {
								background: '#EFF8FF',
								color: '#175CD3',
							},
							'& .MuiChip-colorSuccess': {
								background: '#ECFDF3',
								color: '#027A48',
							},
							'& .MuiChip-colorError': {
								background: '#FFF4ED',
								color: '#B93815',
							},
						}}
					>
						<Chip
							label={USER_STATUS[row.status]}
							size="small"
							color={row.status === 0 ? 'error' : row.status === 1 ? 'success' : 'primary'}
						/>
					</Box>
				),
			},
			{
				id: 'action',
				header: '',
				size: 120,
				accessorFn: row => (
					<Box display="flex" gap="0.5rem">
						<IconButton onClick={() => openConfirmModal(row)}>
							<FiTrash2 fontSize="medium" />
						</IconButton>
						<IconButton onClick={() => showCRU(row)}>
							<FiEdit2 fontSize="medium" />
						</IconButton>
					</Box>
				),
			},
		],
		[activeInactiveUserAction, resendEmailAction, showCRU, openConfirmModal]
	);

	return (
		<AdminLayout>
			<Box height="calc(100vh - 100px)">
				<Box display="flex" justifyContent="space-between" alignItems="start" mb={2}>
					<Box>
						<Typography variant="h5" fontWeight="bold">
							User Management
						</Typography>
					</Box>
					<Button variant="contained" startIcon={<AddIcon />} onClick={() => showCRU()}>
						Add User
					</Button>
				</Box>
				<PaginationTable
					dataKeys={[QUERY_CACHE.USERS]}
					columns={columns}
					searchPlaceholder="Search by name, org"
					remoteDataFn={fetchUsers}
				/>
			</Box>
			<UserModal
				isOpen={displayCRUModal}
				onCancel={closeCRUModal}
				onOk={handleCreateUpdateUser}
				initialValues={initialValues}
				options={{
					organizations: organizationsOptions,
				}}
			/>
			<ConfirmModal
				open={isOpenConfimModal}
				title="Delete User"
				subject={confirmedItem}
				renderMessage={(item: any) => (
					<Box>
						<Typography
							sx={{
								mb: 2,
								fontSize: 18,
								fontWeight: 'bold',
								span: { color: theme => theme.palette.primary.main },
							}}
						>
							Are you sure you want to delete <span>{_get(item, 'full_name')}</span> ?
						</Typography>
						<Typography sx={{ color: theme => theme.palette.grey[500] }}>
							Do note that you cannot undo this action.
						</Typography>
					</Box>
				)}
				onCancel={closeConfirmModal}
				onOk={handleDeleteUser}
				okProps={{
					children: 'Delete',
				}}
			/>
		</AdminLayout>
	);
};

const AdminUsersProvider = (props: any) => {
	return (
		<CruProvider>
			<AdminUsers {...props} />
		</CruProvider>
	);
};

export default AdminUsersProvider;
