import { Box, Breadcrumbs, Button, Container, Grid, Typography } from '@mui/material';
import Layout from 'components/organisms/Layout';
import * as React from 'react';
// import Carousel from 'react-material-ui-carousel'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Diaram_3D, Photo, Schematic } from 'assets/icons';
import {
	Image_3D_1,
	Image_3D_2,
	Image_3D_3,
	Image_Schematic_1,
	Image_Schematic_2,
	Image_Schematic_3,
} from 'assets/images/index';
import { Axios } from 'core/httpServices';
import { NetworkContext, useCompareContext } from 'providers/context';
import { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { MeasureItemDetail } from '../../organisms/Measure/index';

interface MeasureItemPageProps {}
const ButtonStyling = {
	justifyContent: 'space-between',
	border: '1px solid #E5E7EB',
	borderRadius: '10px',
	px: '16px',
	m: {
		xs: '10px 0 0 0',
		md: '10px 10px 0 10px',
	},
	color: '#6C737F',
	minWidth: { sm: '180px' },
};
const ButtonStylingActive = {
	justifyContent: 'space-between',
	border: '1px solid #1570EF',
	borderRadius: '10px',
	px: '16px',
	m: {
		xs: '10px 0 0 0',
		md: '10px 10px 0 10px',
	},
	background: '#F5FAFF',
	minWidth: { sm: '180px' },
};

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		paritialVisibilityGutter: 60,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 50,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
};

const items = [
	{
		src: [Image_Schematic_1, Image_Schematic_2, Image_Schematic_3],
	},
	{
		src: [Image_3D_1, Image_3D_2, Image_3D_3],
	},
];

const MeasureItemPage: React.FC<MeasureItemPageProps> = ({}) => {
	const { isOnline } = useContext(NetworkContext);
	const firstButtonRef = useRef<any>(null);
	const secondButtonRef = useRef<any>(null);
	const thirdButtonRef = useRef<any>(null);
	const [hoverButton3D_diagram_1, setHoverButton3D_diagram_1] = useState<number | null>(0);
	const [getRes, setGetRes] = useState<any>();
	const [getCriteria, setGetCriteria] = useState<any>();
	const [state, setState] = useState<any>(0);
	const [getschematicImage, setGetschematicImage] = useState<any>();
	const [get3dImage, setGet3dImage] = useState<any>();
	const { showCompareTab, handleShowCompareTab } = useCompareContext();
	const getMeasuresDetail = async () => {
		const query = window.location.href;
		const result = query?.substring(query.indexOf('='));
		const id_number = result.replace(result.substring(0, 1), '');

		if (isOnline) {
			try {
				const getData = await Axios.get(
					`/measures/${id_number}?includes=applications,attachments,criterias,tags,location,potentials,description`
				);
				const getCriteria = await Axios.get(`/criteria-types?includes=criterias&sort=created_at`);

				setGetCriteria(getCriteria.data.data);
				setGetRes(getData?.data);
				const GetschematicImage = getData?.data.attachments?.filter((e: any) => e.type === 1);
				const Get3dImage = getData?.data.attachments?.filter((e: any) => e.type === 2);
				setGetschematicImage(GetschematicImage);
				setGet3dImage(Get3dImage);
			} catch {
				console.log('error');
			}
		} else {
			// @ts-ignore
			const getData = measure as any;
			// @ts-ignore
			const getCriteria = criteria_types;
			setGetCriteria(getCriteria);
			const res = getData?.filter((e: any) => id_number.includes(e.id));
			setGetRes(res[0]);
			const GetschematicImage = res[0].attachments?.filter((e: any) => e.type === 1);
			const Get3dImage = res[0].attachments?.filter((e: any) => e.type === 2);
			setGetschematicImage(GetschematicImage);
			setGet3dImage(Get3dImage);
		}
	};
	useEffect(() => {
		getMeasuresDetail();
		return () => handleShowCompareTab(false);
	}, []);
	const handleFirstButtonClick = () => {
		setState(0);
		firstButtonRef.current.click();
	};
	const handleSecondButtonClick = () => {
		setState(1);
		secondButtonRef.current.click();
	};
	const handleThirdButtonClick = () => {
		setState(2);
		thirdButtonRef.current.click();
	};
	const ButtonGroup = ({ goToSlide, ...rest }: any) => {
		const {
			carouselState: { currentSlide },
		} = rest;
		return (
			<div style={{ background: 'white', position: 'absolute' }}>
				<Button
					ref={firstButtonRef}
					key={1}
					sx={{ display: 'none' }}
					disabled={currentSlide === 0}
					onClick={() => goToSlide(0)}
				></Button>
				<Button
					ref={secondButtonRef}
					key={2}
					sx={{ display: 'none' }}
					disabled={currentSlide === 1}
					onClick={() => goToSlide(1)}
				></Button>
				<Button
					ref={thirdButtonRef}
					key={3}
					sx={{ display: 'none' }}
					disabled={currentSlide === 2}
					onClick={() => goToSlide(2)}
				></Button>
			</div>
		);
	};

	return (
		<Layout>
			<Container maxWidth="xl">
				<Breadcrumbs
					sx={{
						mb: {
							xs: '0px',
							md: '50px',
						},
						padding: {
							xs: '0px',
							md: '0px 2rem',
						},
					}}
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Link
						style={{
							color: '#667085',
							textDecoration: 'none',
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
						}}
						to={`/home`}
					>
						Home
					</Link>
					<Link
						style={{
							color: '#667085',
							textDecoration: 'none',
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
						}}
						to={`/categories`}
						state={{ tab: showCompareTab ? 2 : undefined }}
					>
						Adaptation Measures
					</Link>
					<Typography
						sx={{
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
							color: '#2E90FA',
							fontStyle: 'normal',
						}}
					>
						{getRes && getRes.name}
					</Typography>
				</Breadcrumbs>
				<Grid
					container
					mb={{
						xs: '30px',
						md: '100px',
					}}
					px={{
						xs: '0px',
						md: '2rem',
					}}
					sx={{
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Grid
						item
						xs={12}
						md={3}
						sm={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						// sx={{textAlign: {sm: 'center'}}}
					>
						<Box
							display="flex"
							sx={{
								background: {
									xs: 'none',
									md: '#F9FAFB',
								},
								padding: {
									xs: '0px',
									md: '0px 2rem',
								},
								height: '100%',
								justifyContent: 'space-between',
								flexDirection: 'column',
							}}
						>
							<Box maxWidth="md" sx={{ pt: '50px' }}>
								<Typography
									fontSize="18px"
									color={
										getRes?.category?.id === 'c950a28c-019e-4e35-96fc-009e305af905'
											? '#2E90FA'
											: getRes?.category?.id === '5379e9bb-6867-407b-89ff-9942cc8381e6'
											? '#FF8F28'
											: getRes?.category?.id === '61b35e7a-ec60-47b7-9eca-d3e3f928635c'
											? '#67B817'
											: getRes?.category?.id === '404c71b4-7bb1-4ea4-b681-49677b367cb9'
											? '#1C449C'
											: getRes?.category?.id === '58b6fc09-d0fc-4c65-bea7-acab178833b1'
											? '#189FB1'
											: '#219653'
									}
									sx={{
										lineHeight: {
											md: '20px',
											xs: '18px',
										},
										pb: '30px',
									}}
								>
									{getRes && getRes.category?.name}
								</Typography>
								<Typography
									fontSize="36px"
									sx={{
										lineHeight: '40px',
										overflowWrap: 'break-word',
									}}
								>
									{getRes && getRes.name}
								</Typography>
							</Box>
							<Box
								sx={{
									my: '20px',
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'column',
								}}
							>
								<Button sx={state === 0 ? ButtonStylingActive : ButtonStyling} onClick={handleFirstButtonClick}>
									Photo of Measure
									<img src={Photo} alt="logout_svg" />
								</Button>
								<Button sx={state === 1 ? ButtonStylingActive : ButtonStyling} onClick={handleSecondButtonClick}>
									Schematic
									<img src={Schematic} alt="logout_svg" />
								</Button>
								<Button sx={state === 2 ? ButtonStylingActive : ButtonStyling} onClick={handleThirdButtonClick}>
									Illustration
									<img src={Diaram_3D} alt="logout_svg" />
								</Button>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						sm={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						sx={{ background: '#F2F4F7' }}
					>
						<Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
							<Carousel
								responsive={responsive}
								autoPlay={false}
								arrows={false}
								renderButtonGroupOutside={true}
								customButtonGroup={<ButtonGroup />}
							>
								<div style={{ height: '600px' }}>
									<img
										style={{ width: '100%', height: '100%', objectFit: 'cover' }}
										src={isOnline ? getRes && getRes.measure_main_picture : getRes && getRes.main_picture}
									/>
								</div>
								<div style={{ height: '600px' }}>
									<div style={{ height: '600px', background: 'white', display: 'flex' }}>
										<img
											style={{
												height: 'auto',
												margin: 'auto',
												width: 'auto',
												maxHeight: '80%',
												maxWidth: '90%',
												objectFit: 'contain',
											}}
											// attachment_image_url
											src={
												hoverButton3D_diagram_1 === 0 && isOnline
													? getschematicImage?.[0]?.attachment_image_url
													: hoverButton3D_diagram_1 === 1 && isOnline
													? getschematicImage?.[1]?.attachment_image_url
													: hoverButton3D_diagram_1 === 2 && isOnline
													? getschematicImage?.[2]?.attachment_image_url
													: hoverButton3D_diagram_1 === 0 && !isOnline
													? getschematicImage?.[0]?.image
													: hoverButton3D_diagram_1 === 1 && !isOnline
													? getschematicImage?.[1]?.image
													: hoverButton3D_diagram_1 === 2 && !isOnline
													? getschematicImage?.[2]?.image
													: getschematicImage?.[0].image
											}
										/>
										<div
											style={{
												position: 'absolute',
												bottom: '0px',
												width: '100%',
												background: 'white',
												backdropFilter: 'blur(6px)',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													py: '10px',
												}}
											>
												{getschematicImage && getschematicImage.length > 1 && (
													<>
														<Button
															onMouseEnter={() => setHoverButton3D_diagram_1(0)}
															onMouseLeave={() => setHoverButton3D_diagram_1(0)}
															sx={{
																background: hoverButton3D_diagram_1 === 0 ? '#1570EF' : '#F3F4F6',
																color: hoverButton3D_diagram_1 === 0 ? 'white' : 'black',
																borderRadius: '30px',
																px: '20px',
																mx: '5px',
																'&:hover': {
																	background: '#1570EF',
																	color: 'white',
																},
															}}
														>
															{getschematicImage?.[0]?.name}
														</Button>
														<Button
															onMouseEnter={() => setHoverButton3D_diagram_1(1)}
															onMouseLeave={() => setHoverButton3D_diagram_1(0)}
															sx={{
																background: hoverButton3D_diagram_1 === 1 ? '#1570EF' : '#F3F4F6',
																color: hoverButton3D_diagram_1 === 1 ? 'white' : 'black',
																borderRadius: '30px',
																px: '20px',
																mx: '5px',
																'&:hover': {
																	background: '#1570EF',
																	color: 'white',
																},
															}}
														>
															{getschematicImage?.[1]?.name}
														</Button>
													</>
												)}
												{getschematicImage && getschematicImage.length > 2 && (
													<Button
														onMouseEnter={() => setHoverButton3D_diagram_1(2)}
														onMouseLeave={() => setHoverButton3D_diagram_1(0)}
														sx={{
															background: hoverButton3D_diagram_1 === 2 ? '#1570EF' : '#F3F4F6',
															color: hoverButton3D_diagram_1 === 2 ? 'white' : 'black',
															borderRadius: '30px',
															px: '20px',
															mx: '5px',
															'&:hover': {
																background: '#1570EF',
																color: 'white',
															},
														}}
													>
														{getschematicImage?.[2]?.name}
													</Button>
												)}
											</Box>
										</div>
									</div>
								</div>

								<div style={{ height: '600px' }}>
									<div style={{ height: '600px', background: 'white', display: 'flex' }}>
										<img
											style={{
												height: 'auto',
												margin: 'auto',
												width: 'auto',
												maxHeight: '80%',
												maxWidth: '90%',
												objectFit: 'contain',
											}}
											src={
												hoverButton3D_diagram_1 === 0 && isOnline
													? get3dImage?.[0]?.attachment_image_url
													: hoverButton3D_diagram_1 === 1 && isOnline
													? get3dImage?.[1]?.attachment_image_url
													: hoverButton3D_diagram_1 === 2 && isOnline
													? get3dImage?.[2]?.attachment_image_url
													: hoverButton3D_diagram_1 === 0 && !isOnline
													? get3dImage?.[0]?.image
													: hoverButton3D_diagram_1 === 1 && !isOnline
													? get3dImage?.[1]?.image
													: hoverButton3D_diagram_1 === 2 && !isOnline
													? get3dImage?.[2]?.image
													: get3dImage?.[0].image
											}
										/>
										<div
											style={{
												position: 'absolute',
												bottom: '0px',
												width: '100%',
												background: 'white',
												backdropFilter: 'blur(6px)',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													py: '10px',
												}}
											>
												{get3dImage && get3dImage.length > 1 && (
													<>
														<Button
															onMouseEnter={() => setHoverButton3D_diagram_1(0)}
															onMouseLeave={() => setHoverButton3D_diagram_1(0)}
															sx={{
																background: hoverButton3D_diagram_1 === 0 ? '#1570EF' : '#F3F4F6',
																color: hoverButton3D_diagram_1 === 0 ? 'white' : 'black',
																borderRadius: '30px',
																px: '20px',
																mx: '5px',
																'&:hover': {
																	background: '#1570EF',
																	color: 'white',
																},
															}}
														>
															{get3dImage?.[0]?.name}
														</Button>
														<Button
															onMouseEnter={() => setHoverButton3D_diagram_1(1)}
															onMouseLeave={() => setHoverButton3D_diagram_1(0)}
															sx={{
																background: hoverButton3D_diagram_1 === 1 ? '#1570EF' : '#F3F4F6',
																color: hoverButton3D_diagram_1 === 1 ? 'white' : 'black',
																borderRadius: '30px',
																px: '20px',
																mx: '5px',
																'&:hover': {
																	background: '#1570EF',
																	color: 'white',
																},
															}}
														>
															{get3dImage?.[1]?.name}
														</Button>
													</>
												)}
												{get3dImage && get3dImage.length > 2 && (
													<Button
														onMouseEnter={() => setHoverButton3D_diagram_1(2)}
														onMouseLeave={() => setHoverButton3D_diagram_1(0)}
														sx={{
															background: hoverButton3D_diagram_1 === 2 ? '#1570EF' : '#F3F4F6',
															color: hoverButton3D_diagram_1 === 2 ? 'white' : 'black',
															borderRadius: '30px',
															px: '20px',
															mx: '5px',
															'&:hover': {
																background: '#1570EF',
																color: 'white',
															},
														}}
													>
														{get3dImage?.[2]?.name}
													</Button>
												)}
											</Box>
										</div>
									</div>
								</div>
							</Carousel>
						</Box>
					</Grid>
				</Grid>
				<MeasureItemDetail value={getRes} criteriaList={getCriteria} />
			</Container>
		</Layout>
	);
};

export default MeasureItemPage;
