import React from 'react';
import { Field, FieldArray } from 'formik';

interface Props {
  name: string;
  children: any;
}

export const Select: React.FC<Props> = props => {
  return (
    <Field  placeholder="Food service" style={{ width: '100%', padding: '10px 15px', border: '1px solid #D0D5DD', borderRadius: '8px'}}  as="select" name={props.name}>
      {props.children}
    </Field>
  );
};

export default Select;
