import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React from 'react';

interface Props {
	fieldName?: string;
	lead?: string;
	children?: any;
	isOptional?: boolean;
	caption?: string[] | string;
	captionfee?: boolean;
	isCaptionForInput?: boolean;
	toggleName?: string;
	name?: string;
	errorMessage?: any;
	className?: string;
}

export const Fieldrow: React.FC<Props> = ({
	fieldName,
	isOptional,
	children,
	lead,
	caption,
	captionfee,
	toggleName,
	isCaptionForInput,
	name,
	errorMessage,
	...props
}) => {
	const { errors, touched } = useFormikContext<any>();
	const isShowError = name && get(touched, name || '') && get(errors, name || '');
	return (
		<Box>
			{(fieldName || isOptional) && <div className="m-fieldrow_head"></div>}
			<Box
				className={`${isShowError && 'error'}`}
				sx={{
					'&.error': {
						'& input': {
							border: '1px solid red !important',
						},
						'& button': {
							border: '1px solid red !important',
						},
					},
				}}
			>
				{children}
			</Box>
			{isShowError && (
				<Box color="red" mt={1}>
					{errorMessage || get(errors, name || '')}
				</Box>
			)}
			{((isCaptionForInput && children) || !isCaptionForInput) && caption && (
				<div className="m-fieldrow_caption">
					{typeof caption === 'string' ? <p>{caption}</p> : caption.map((cap, idx) => <p key={idx}>{cap}</p>)}
				</div>
			)}
		</Box>
	);
};

export default Fieldrow;
