import { object, string } from 'yup'

export const userSchema = object({
  full_name: string().required('Username is required').matches(/^\s*\S[\s\S]*$/, '* This field cannot contain only blankspaces'),
  email: string().trim().email().required('Email is required'),
  organization_id: string().required('Organization is required 1'),
  title: string().required('Designation is required'),
})

export const defaultInitialUserValues = {
  full_name: '',
  email: '',
  organization_id: '',
  title: '',
};