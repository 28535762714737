import { Box, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { colors } from 'styles';
interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box mt={6} height="100%">
					{children}
				</Box>
			)}
		</div>
	);
}

interface HorizontalTabProps {
	tabs: Array<{ label: string; content: React.ReactNode }>;
	currentTab: number;
	handleChangeTab: (value: number) => void;
	position?: 'left' | 'center';
}

const HorizontalTab: React.FC<HorizontalTabProps> = ({ tabs, handleChangeTab, currentTab, position = 'center' }) => {
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		handleChangeTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: colors.gray[200],
				}}
			>
				<Tabs value={currentTab} onChange={handleChange} centered={position === 'center'}>
					{tabs.map((item, index) => (
						<Tab
							sx={{
								fontFamily: 'Inter',
								fontSize: '14px',
								lineHeight: '18px',
								mx: '20px',
								px: '50px',
								py: '20px',
								color: '#667085',
								fontWeight: '600',
								padding: {
									xs: '0px 10px',
									md: '20px 50px',
								},
								margin: {
									xs: '0px',
									md: '0px 20px',
								},
							}}
							key={`tab_${index}`}
							label={item.label}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</Box>
			<Box
				sx={{
					backgroundColor: 'white',
				}}
			>
				{tabs.map((item, index) => (
					<TabPanel key={`tabPanel_${index}`} value={currentTab} index={index}>
						{item.content}
					</TabPanel>
				))}
			</Box>
		</Box>
	);
};

export default HorizontalTab;
