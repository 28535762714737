import { createTheme } from '@mui/material/styles'
import { colors } from 'styles'
import './fonts.css'

export const theme = createTheme({
  palette: {},
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          ':disabled': {
            // backgroundColor: 'white',
            // color: 'white',
            opacity: '0.8'
          }
        },
        
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.primary[700]
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '16px',
          fontWeight: 500,
          '&.Mui-selected': {
            color: colors.primary[700]
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 'none'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: 'Inter',
    h4: {
      fontWeight: 'bold',
      color: colors.gray[900],
      fontFamily: 'Inter'
    },
    subtitle1: {
      color: colors.gray[500]
    }
  },
})
