import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { FieldFormik, Fieldrow, FileInput, Textarea, TextEditor } from 'components/atoms';
import { AdminLayout } from 'components/organisms';
import { createSchema } from 'constants/form/homepage-form';
import { Field, Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useGetHomepageData, useUpdateHomepageData } from 'modules/home';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminHompageCMS: React.FC = () => {
	const { data } = useGetHomepageData();
	const { mutate, isLoading } = useUpdateHomepageData();

	return (
		<AdminLayout>
			<section>
				<Formik
					enableReinitialize
					initialValues={{
						logo_homepage: data?.logo_homepage,
						copyright_homepage: data?.copyright_homepage,
						hero_banner_homepage: data?.hero_banner_homepage,
						impact_section_homepage: data?.impact_section_homepage,
						toolkit_section_homepage: data?.toolkit_section_homepage,
						toolkit_section_bonus_homepage: data?.toolkit_section_bonus_homepage,
					}}
					validationSchema={createSchema}
					validateOnMount
					onSubmit={() => {}}
				>
					{({ values, errors }: FormikProps<any>) => {
						const postData = async () => {
							const data = new FormData();

							data.append('logo_homepage', values.logo_homepage?.file || values.logo_homepage?.value);
							data.append('copyright_homepage', values.copyright_homepage);

							data.append('hero_banner_homepage[headline]', values.hero_banner_homepage.headline);
							data.append('hero_banner_homepage[sub_headline]', values.hero_banner_homepage.sub_headline);
							data.append(
								'hero_banner_homepage[image]',
								values.hero_banner_homepage.image?.file || values.hero_banner_homepage.image
							);
							data.append('hero_banner_homepage[floating_container]', values.hero_banner_homepage.floating_container);

							data.append('impact_section_homepage[name]', values.impact_section_homepage.name);
							data.append('impact_section_homepage[headline]', values.impact_section_homepage.headline);
							data.append('impact_section_homepage[body]', values.impact_section_homepage.body);
							data.append(
								'impact_section_homepage[image]',
								values.impact_section_homepage.image?.file || values.impact_section_homepage.image
							);

							data.append('toolkit_section_homepage[name]', values.toolkit_section_homepage.name);
							data.append('toolkit_section_homepage[headline]', values.toolkit_section_homepage.headline);
							data.append('toolkit_section_homepage[body]', values.toolkit_section_homepage.body);
							data.append(
								'toolkit_section_homepage[image]',
								values.toolkit_section_homepage.image?.file || values.toolkit_section_homepage.image
							);

							data.append('toolkit_section_bonus_homepage[headline]', values.toolkit_section_bonus_homepage.headline);
							data.append('toolkit_section_bonus_homepage[body]', values.toolkit_section_bonus_homepage.body);
							data.append('toolkit_section_bonus_homepage[button]', values.toolkit_section_bonus_homepage.button);

							await mutate(data, {
								onSuccess: () => {
									toast.success(`Homepage has been updated successfully`);
								},
								onError: e => {
									const errorType = e.response?.data?.error?.errors;
									errorType.forEach((element: any) => {
										toast.error(element.message);
									});
								},
							});
						};

						return (
							<Form>
								<Container
									maxWidth="xl"
									sx={{
										'& a': { textDecoration: 'none' },
									}}
								>
									<Box display="flex" alignItems="center" width="100%" flexWrap="wrap" gap={2}>
										<Box flex={1} flexShrink={0}>
											<Typography fontSize="32px" fontWeight="600" whiteSpace="nowrap">
												Homepage CMS
											</Typography>
											<Typography variant="body2" mt={0.5} sx={{ color: '#475467' }} whiteSpace="nowrap">
												Manage and edit homepage content
											</Typography>
										</Box>
										<Box display="flex">
											<Link to="/admin/users">
												<Button
													variant="outlined"
													sx={{
														textDecoration: 'none!important',
														background: 'white',
														color: 'black',
														border: '1px solid #D0D5DD',
													}}
												>
													Cancel
												</Button>
											</Link>
											<Button
												disabled={!isEmpty(errors) || isLoading}
												onClick={() => postData()}
												sx={{ ml: 3 }}
												variant="contained"
											>
												Save
											</Button>
										</Box>
									</Box>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Logo and copyright
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Logo</Typography>
												<Typography sx={{ mb: 2 }}>
													Please resize the picture to exactly 1920x1080px before uploading it
												</Typography>
												<Fieldrow name="logo_homepage.file">
													<FileInput
														name="logo_homepage.file"
														label=""
														maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
														previewImg={values?.logo_homepage?.url}
													/>
												</Fieldrow>
											</Container>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Copyright
												</Typography>
												<Field name="copyright_homepage">
													{({ field, meta }: any) => (
														<TextEditor
															value={values?.copyright_homepage}
															onchange={field.onChange(field.name)}
															maxLength={100}
														/>
													)}
												</Field>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Herobanner
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Headline
												</Typography>
												<Field name="hero_banner_homepage.headline">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.hero_banner_homepage?.headline}
															onchange={field.onChange(field.name)}
															maxLength={100}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Sub-Headline
												</Typography>
												<Field name="hero_banner_homepage.sub_headline">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.hero_banner_homepage?.sub_headline}
															onchange={field.onChange(field.name)}
															maxLength={200}
														/>
													)}
												</Field>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Image</Typography>
												<Typography sx={{ mb: 2 }}>
													Please resize the picture to exactly 1080x1080px before uploading it
												</Typography>
												<Fieldrow name="hero_banner_homepage.image.file">
													<FileInput
														previewImg={values?.hero_banner_homepage?.image_url}
														name="hero_banner_homepage.image.file"
														label=""
														maxsize="SVG, PNG, JPG or GIF (max. 1080x1080px)"
													/>
												</Fieldrow>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Floating container
												</Typography>
												<Textarea
													name="hero_banner_homepage.floating_container"
													value={values.hero_banner_homepage?.floating_container}
													error={(errors as any)?.hero_banner_homepage?.floating_container}
													maxLength={100}
												/>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Section
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Tag
												</Typography>
												<Fieldrow name="impact_section_homepage.name">
													<FieldFormik type="text" name="impact_section_homepage.name" maxLength={50} />
												</Fieldrow>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Headline
												</Typography>
												<Field name="impact_section_homepage.headline">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.impact_section_homepage?.headline}
															onchange={field.onChange(field.name)}
															maxLength={80}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Body
												</Typography>
												<Field name="impact_section_homepage.body">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.impact_section_homepage?.body}
															onchange={field.onChange(field.name)}
															maxLength={800}
														/>
													)}
												</Field>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Image</Typography>
												<Typography sx={{ mb: 2 }}>
													Please resize the picture to exactly 1920x1080px before uploading it
												</Typography>
												<Fieldrow name="impact_section_homepage.image.file">
													<FileInput
														previewImg={values?.impact_section_homepage?.image_url}
														name="impact_section_homepage.image.file"
														label=""
														maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
													/>
												</Fieldrow>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Section
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Tag
												</Typography>
												<Fieldrow name="toolkit_section_homepage.name">
													<FieldFormik type="text" name="toolkit_section_homepage.name" maxLength={50} />
												</Fieldrow>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Headline
												</Typography>
												<Field name="toolkit_section_homepage.headline">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.toolkit_section_homepage?.headline}
															onchange={field.onChange(field.name)}
															maxLength={60}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Body
												</Typography>
												<Field name="toolkit_section_homepage.body">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.toolkit_section_homepage?.body}
															onchange={field.onChange(field.name)}
															maxLength={600}
														/>
													)}
												</Field>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Image</Typography>
												<Typography sx={{ mb: 2 }}>
													Please resize the picture to exactly 1920x1080px before uploading it
												</Typography>
												<Fieldrow name="toolkit_section_homepage.image.file">
													<FileInput
														previewImg={values?.toolkit_section_homepage?.image_url}
														name="toolkit_section_homepage.image.file"
														label=""
														maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
													/>
												</Fieldrow>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Toolkit section (bonus)
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Headline
												</Typography>
												<Field name="toolkit_section_bonus_homepage.headline">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.toolkit_section_bonus_homepage?.headline}
															onchange={field.onChange(field.name)}
															maxLength={50}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Body
												</Typography>
												<Field name="toolkit_section_bonus_homepage.body">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.toolkit_section_bonus_homepage?.body}
															onchange={field.onChange(field.name)}
															maxLength={240}
														/>
													)}
												</Field>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Button
												</Typography>
												<Fieldrow name="toolkit_section_bonus_homepage.button">
													<FieldFormik type="text" name="toolkit_section_bonus_homepage.button" maxLength={50} />
												</Fieldrow>
											</Container>
										</Grid>
									</Grid>
								</Container>
							</Form>
						);
					}}
				</Formik>
			</section>
		</AdminLayout>
	);
};
export default AdminHompageCMS;
