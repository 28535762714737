import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  Slide
} from '@mui/material'
import { DialogProps } from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import { CloseSVG } from 'assets/icons'
import * as React from 'react'

const Dialog = styled((props: DialogProps) => <MuiDialog {...props} />)(() => ({
  '.MuiDialog-container': {
    alignItems: 'flex-end',
    width: '100%'
  },

  '.MuiDialog-paper': {
    margin: 0,
    maxWidth: '100%',
    width: '100%',
    height: '80%',
    paddingTop: '80px',
    position: 'relative'
  },
  "@media (max-width: 900px)": {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface FullWidthPopupProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

const FullWidthPopup: React.FC<FullWidthPopupProps> = ({
  open,
  onClose,
  children
}) => {
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '30px',
          right: '30px',
          overflow: 'hidden'
        }}
      >
        <img src={CloseSVG} alt="close_icon" />
      </IconButton>
      <DialogContent sx={{
        overflow: {
          xs: 'visible',
          md: 'hidden'
        }
      }}>{children}</DialogContent>
    </Dialog>
  )
}

export default FullWidthPopup
