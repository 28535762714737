import { Container, Paper } from '@mui/material'
import { ContainerProps } from '@mui/material/Container'
import * as React from 'react'
import { colors } from 'styles'

interface AuthenticationLayoutProps extends ContainerProps {
  children: React.ReactNode
}

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: colors.gray[50],
        pt: '20vh',
        height: '100vh'
      }}
      disableGutters
      {...props}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: '440px',
          margin: '0 auto',
          p: 4,
          textAlign: 'center',
          mb: '8px'
        }}
      >
        {children}
      </Paper>
    </Container>
  )
}

export default AuthenticationLayout
