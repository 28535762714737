import { Box, Button, Link, Typography } from '@mui/material'
import { TextInput } from 'components/atoms'
import { AuthenticationLayout } from 'components/organisms'
import _get from 'lodash/get'
import { LoginInfo, useLogin } from 'modules/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ROUTES } from 'routers'
import { colors } from 'styles'
import { redirectTo } from 'utils'

interface LoginProps { }

const Login: React.FC<LoginProps> = ({ }) => {
  const { mutate, isError, error } = useLogin()

  const { handleSubmit, register } = useForm<LoginInfo>({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  useEffect(() => {
    if (isError && error) {
      const errMessage = _get(error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [isError])

  const onSubmit = (data: LoginInfo) => {
    mutate(data)
  }

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="div"
          color={colors.gray[900]}
          fontWeight={600}
          fontSize="24px"
          lineHeight="32px"
        >
          Welcome back
        </Typography>
        <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
          Please enter your detail
        </Typography>
        <TextInput
          name="email"
          placeholder="Enter your email"
          register={register}
          sx={{
            width: '100%',
            my: '24px'
          }}
        />
        <TextInput
          name="password"
          placeholder="Enter your password"
          type="password"
          register={register}
          sx={{
            width: '100%',
            mb: '24px'
          }}
        />
        <Box textAlign="right" mb="24px">
          <Link
            fontSize="14px"
            sx={{
              textDecoration: 'none'
            }}
            href={redirectTo(ROUTES.FORGOT_PASSWORD)}
          >
            Forgot password?
          </Link>
        </Box>
        <Button
          type="submit"
          sx={{ width: '100%', mb: '24px' }}
          variant="contained"
        >
          Continue with email
        </Button>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
            Don’t have an account?
          </Typography>
          <Link
            fontSize="14px"
            sx={{
              textDecoration: 'none'
            }}
            href={redirectTo(ROUTES.GET_STARTED)}
          >
            Sign up
          </Link>
        </Box>
      </form>
    </AuthenticationLayout>
  )
}

export default Login
