import React, { useCallback, useMemo, useState } from 'react'
import _get from 'lodash/get'
import Box from "@mui/material/Box"
import MaterialReactTable from 'material-react-table'
import { Pagination, PaginationItem } from '@mui/material'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { PaginationState } from '@tanstack/react-table'
import { useQuery } from '@tanstack/react-query'

const PER_PAGE = 10;

const PaginationTable = (props: any) => {
  const { columns, data, dataKeys, remoteDataFn, searchPlaceholder, maxHeight, perPage = PER_PAGE, ...rest } = props;
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: perPage
  })
  const [rowCount, setRowCount] = useState(0);

  const { isLoading, isError, data: remoteData } = useQuery(
    [...dataKeys, searchValue, pagination.pageIndex, pagination.pageSize],
    async () => {
      if (!remoteDataFn) {
        return []
      }
      const data = await remoteDataFn({ pagination, search: searchValue });
      setRowCount(_get(data, 'data.meta_data.total', 0))
      return data.data
    },
    { keepPreviousData: true }
  )

  const handleSearch = useCallback((value: string) => {
    setPagination((prev: any) => ({ ...prev, pageIndex: 1 }));
    setSearchValue(value);
  }, [setSearchValue, setPagination])

  const tableData = useMemo(() => {
    return data || _get(remoteData, 'data', [])
  }, [data, remoteData])

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        enableStickyHeader={true}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableToolbarInternalActions={false}
        manualFiltering
        manualPagination
        manualSorting
        initialState={{ showGlobalFilter: true, density: 'comfortable' }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: searchPlaceholder || 'Search...',
          sx: { minWidth: '300px' },
          variant: 'outlined',
          size: 'small'
        }}
        onGlobalFilterChange={handleSearch}
        rowCount={rowCount}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: 'Error loading data'
            }
            : undefined
        }
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableHeadCellProps={{ sx: { background: '#F9FAFB', fontWeight: 'bold', fontSize: '12px', color: (theme) => theme.palette.grey[600] } }}
        muiTableBodyCellProps={{ sx: { color: (theme) => theme.palette.grey[600] } }}
        muiTableBodyRowProps={{ hover: false }}
        renderBottomToolbar={() => (
          <Box p={2} display="flex" justifyContent="center">
            <Pagination
              disabled={isLoading}
              count={Math.ceil(rowCount / perPage)}
              page={pagination.pageIndex}
              onChange={(_, v) => {
                setPagination((prev: any) => ({ ...prev, pageIndex: v }))
              }}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    next: () => (<Box>Next<KeyboardArrowRight/></Box>),
                    previous: () => (<Box><KeyboardArrowLeft/>Previous</Box>)
                  }}
                  {...item}
                />
              )}
              color="primary"
            />
          </Box>
        )}
        {...rest}
      />
    </Box>
  )
}

export default PaginationTable;