import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Typography } from '@mui/material'
import { TextInput } from 'components/atoms'
import { AuthenticationLayout } from 'components/organisms'
import _get from 'lodash/get'
import { RESEND_MAIL_TYPE, useForgotPassword, VerifyEmailInfo } from 'modules/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from 'routers'
import { colors } from 'styles'

interface ForgotPasswordPageProps { }

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ }) => {
  const navigate = useNavigate()
  const { mutate, isSuccess, isError, error } = useForgotPassword()

  const { handleSubmit, register, getValues } = useForm<VerifyEmailInfo>({
    defaultValues: {
      email: ''
    }
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.CHECK_MAIL, {
        state: {
          resendMailType: RESEND_MAIL_TYPE.FORGOR_PASSWORD,
          email: getValues().email
        }
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && error) {
      const errMessage = _get(error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [isError])

  const onSubmit = (data: VerifyEmailInfo) => {
    mutate(data)
  }

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="div"
          color={colors.gray[900]}
          fontWeight={600}
          fontSize="24px"
          lineHeight="32px"
        >
          Forgot your password?
        </Typography>
        <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
          Please enter your email to reset password
        </Typography>
        <TextInput
          name="email"
          placeholder="Enter your email"
          register={register}
          sx={{
            width: '100%',
            my: '24px'
          }}
        />
        <Button
          sx={{ width: '100%', mb: '32px' }}
          variant="contained"
          type="submit"
        >
          Request password reset
        </Button>
        <Button onClick={() => navigate(-1)}>
          <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
            <ArrowBackIcon sx={{ color: colors.gray[500] }} /> Back
          </Typography>
        </Button>
      </form>
    </AuthenticationLayout>
  )
}

export default ForgotPasswordPage
