import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { icon_i } from 'assets/icons';
import { Tooltips } from 'components/atoms';
import * as React from 'react';

interface ratingProps {
	value: number;
	title?: string;
	readonly?: boolean;
	tooltip?: string;
	showTooltip?: boolean;
}

const StyledRating = styled(Rating)({
	'&>*:nth-of-type(1)': {
		color: '#FF5372',
	},
	'&>*:nth-of-type(2)': {
		color: '#FF8F28',
	},
	'&>*:nth-of-type(3)': {
		color: '#FDE600',
	},
	'&>*:nth-of-type(4)': {
		color: '#94C259',
	},
	'&>*:nth-of-type(5)': {
		color: '#179C01',
	},
});

const RatingCustom: React.FC<ratingProps> = ({ value, title, readonly = true, tooltip, showTooltip = true }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: !title ? 'center' : 'space-between',
				alignItems: 'center',
				cursor: 'pointer',
			}}
		>
			{title && <Typography mr={0.5}>{title}</Typography>}
			<Box flexShrink={0} display="flex" alignItems="center">
				<StyledRating
					name="customized-color"
					readOnly={readonly}
					defaultValue={value}
					icon={
						<HorizontalRuleRoundedIcon
							fontSize="small"
							sx={{
								transform: 'scale(9, 4) rotate(90deg)',
								width: '10px',
							}}
						/>
					}
					emptyIcon={
						<HorizontalRuleRoundedIcon
							fontSize="small"
							sx={{ transform: 'scale(9, 4) rotate(90deg)', width: '10px' }}
						/>
					}
				/>

				{showTooltip && (
					<Tooltips title={tooltip || 'No description available'}>
						<img src={icon_i} alt="view_more_svg" style={{ marginLeft: '4px' }} />
					</Tooltips>
				)}
			</Box>
		</Box>
	);
};

export default RatingCustom;
