import { Axios } from 'core/httpServices';
import { QK_CRITERIA_TYPES, QK_MEASURES, QK_TAG_TYPES } from './conts';

export const getCriteriaTypes = async () => {
	const params = {
		includes: 'criterias',
		sort: 'created_at',
	};
	const { data } = await Axios.get(QK_CRITERIA_TYPES, { params });
	return data.data;
};

export const getTagTypes = async () => {
	const params = {
		includes: 'tags, location, attachments',
	};
	const { data } = await Axios.get(QK_TAG_TYPES, { params });
	return data.data;
};

export const getMeasures = async () => {
	const params = {
		limit: 500,
		includes: 'tags,location,criterias,potentials,attachments',
	};
	const { data } = await Axios.get(QK_MEASURES, { params });
	return data.data;
};
