import { Typography } from '@mui/material';
import { FullWidthPopup, HorizontalTabCompare } from 'components/molecules';
import * as React from 'react';

interface MeasurePopupProps {
	open?: number;
	onClose: () => void;
}

const MeasurePopup: React.FC<MeasurePopupProps> = ({ open, onClose }) => {
	return (
		<FullWidthPopup open={typeof open === 'number'} onClose={onClose}>
			<Typography sx={{ pt: 0 }} fontWeight={600} fontSize="32px" lineHeight="39px" mb="13px">
				Choose measure to compare
			</Typography>
			<Typography fontSize="16px" lineHeight="24px" mb="50px">
				* You can compare 2-3 measure at the same time. Make sure that these measure have the same category.
			</Typography>
			<HorizontalTabCompare
				itemIndex={open}
				position="left"
				containerSx={{
					height: '100%',
				}}
				contentSx={{
					height: 'calc(100% - 150px)',
					overflow: 'auto',
				}}
				onClose={onClose}
			/>
		</FullWidthPopup>
	);
};

export default MeasurePopup;
