import { ServerUrl } from 'constants/api';
import { useNetworkContext } from 'providers/context';
import React from 'react';

const Image = ({ src, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
	const { isOnline } = useNetworkContext();
	if (src && isOnline && !/^(.?\/static)/.test(src)) {
		src = `${ServerUrl}/${src}`;
	}
	return <img src={src} {...props} />;
};

export default Image;
