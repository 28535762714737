import { Box, Button, Container, Typography } from '@mui/material';
import { FieldFormik, FileInput } from 'components/atoms';
import { ModalAdmin } from 'components/organisms';
import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
	handleClose: () => void;
	handleCancel?: () => void;
	setTouched?: () => void;
	name: string;
	openModal?: boolean;
	defaultType?: number;
	title?: boolean;
	id?: any;
}

const ModalAdminCustom: React.FC<Props> = props => {
	const { handleClose, handleCancel, setTouched, name, openModal, defaultType, title, id = null } = props;
	const { setFieldValue, values } = useFormikContext<any>();

	const clearValues = (header: string) => {
		if (header === 'photo3D') {
			setFieldValue(`photo3D[${id}].attachment_image_url`, undefined, false);
			setFieldValue(`photo3D[${id}].image`, undefined, false);
			setFieldValue(`photo3D[${id}].name`, undefined, false);
			setFieldValue(`photo3D[${id}].file`, undefined, false);
			setFieldValue(`photo3D[${id}].delete`, true, false);
		} else if (header === 'photoSchematic') {
			setFieldValue(`photoSchematic[${id}].attachment_image_url`, undefined, false);
			setFieldValue(`photoSchematic[${id}].image`, undefined, false);
			setFieldValue(`photoSchematic[${id}].name`, undefined, false);
			setFieldValue(`photoSchematic[${id}].file`, undefined, false);
			setFieldValue(`photoSchematic[${id}].delete`, true, false);
		}
		handleClose();
	};

	const saveValues = () => {
		if (name !== 'location') {
			setFieldValue(`${name}[${id}].type`, defaultType);
			setFieldValue(`photoSchematic[${id}].delete`, undefined, false);
		}
		handleClose();
	};

	return (
		<ModalAdmin isOpen={openModal} handleClose={handleClose}>
			<Container maxWidth="xl" sx={{ mb: 4 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '24px',
								xs: '18px',
							},
							my: '30px',
							fontWeight: '600',
						}}
					>
						Add photo
					</Typography>
					<Button sx={{ fontSize: '20px', color: 'black' }} onClick={handleClose}>
						X
					</Button>
				</Box>
				<Box>
					<Typography sx={{ mt: 2 }} fontWeight="600">
						Photo of application
					</Typography>
					<Typography sx={{ mb: 2 }}>Please resize the picture to exactly 1920x1080px before uploading it</Typography>
					<Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
						<FileInput
							name={`${name}[${id}].file`}
							label=""
							maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
							ThumbTrigger
							previewImg={
								props.name === 'photoSchematic'
									? values?.photoSchematic[id]?.file?.preview || values?.photoSchematic[id]?.attachment_image_url
									: values?.photo3D[id]?.file?.preview || values?.photo3D[id]?.attachment_image_url
							}
						/>
					</Box>

					<Typography sx={{ my: 2 }} fontWeight="600">
						Title
					</Typography>
					<FieldFormik
						type="text"
						placeholder="ABC Waters design features, etc.."
						name={id !== null ? `${name}[${id}].name` : `${name}[name]`}
						readonly={false}
						maxLength={127}
					/>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
					<Button
						sx={{ mr: '10px' }}
						onClick={() => {
							clearValues(name);
						}}
						variant="contained"
					>
						Delete
					</Button>
					<Button onClick={() => saveValues()} variant="contained">
						Apply
					</Button>
				</Box>
			</Container>
		</ModalAdmin>
	);
};

export default ModalAdminCustom;
