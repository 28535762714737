export class HomepageData {
	logo_homepage: {
		value: string;
	};
	copyright_homepage: string;
	hero_banner_homepage: {
		headline: string;
		sub_headline: string;
		floating_container: string;
		image: string;
	};
	impact_section_homepage: {
		name: string;
		headline: string;
		body: string;
		image: string;
	};
	toolkit_section_homepage: {
		name: string;
		headline: string;
		body: string;
		image: string;
	};
	toolkit_section_bonus_homepage: {
		headline: string;
		body: string;
		button: string;
	};

	constructor(data: any = {}) {
		this.logo_homepage = data?.logo_homepage;
		this.copyright_homepage = data?.copyright_homepage?.value || '';
		this.hero_banner_homepage = data?.hero_banner_homepage?.value;
		this.impact_section_homepage = data?.impact_section_homepage?.value;
		this.toolkit_section_homepage = data?.toolkit_section_homepage?.value;
		this.toolkit_section_bonus_homepage = data?.toolkit_section_bonus_homepage?.value;
	}
}
