import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {
	AppBar,
	Box,
	Button,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { AdminPortalSVG, LogoSVG, LogoutSVG, SearchSVG } from 'assets/icons';
import Image from 'components/atoms/Image';
import { STORAGE_KEYS } from 'constants/api';
import { useGetUserProfile } from 'modules/auth';
import { useGetHomepageData } from 'modules/home';
import { NetworkContext } from 'providers/context';
import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers';
import { colors } from 'styles';

interface FooterProps {}

const pages = [
	{ name: 'Home', path: ROUTES.HOME },
	{ name: 'How to Use this Toolkit', path: ROUTES.TOOLKIT },
	{ name: 'Adaptation Measures', path: ROUTES.CATEGORIES },
];

const Header: React.FC<FooterProps> = ({}) => {
	const navigate = useNavigate();
	const role = localStorage.getItem('role');
	const { isOnline } = useContext(NetworkContext);
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const { data } = useGetHomepageData();
	const { data: profile } = useGetUserProfile();

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = (path?: string) => {
		path && navigate(path);
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleSignout = () => {
		localStorage.removeItem(STORAGE_KEYS.AccessToken);
		localStorage.removeItem('role');
		navigate(ROUTES.LOGIN);
	};

	const handleAdminNavigation = () => navigate(ROUTES.ADMIN);

	return (
		<AppBar
			sx={{
				height: '80px',
				backgroundColor: 'white',
			}}
			position="static"
		>
			<Container maxWidth="xl" sx={{ height: '100%' }}>
				<Toolbar disableGutters sx={{ height: 'inherit', width: '100%' }}>
					<Box
						sx={{
							display: { xs: 'none', md: 'flex' },
							mr: 1,
							alignItems: 'center',
						}}
					>
						<Image src={data?.logo_homepage?.value} height="40px" alt="PUB_Logo" style={{ objectFit: 'contain' }} />
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' }, color: 'black' }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={() => handleCloseNavMenu()}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map(page => (
								<MenuItem
									sx={{
										fontWeight: '600',
										fontSize: '16px',
										lineHeight: '24px',
										fontFamily: 'Inter',
										color: colors.gray[700],
									}}
									key={page.path}
									onClick={() => handleCloseNavMenu(page.path)}
								>
									<Typography
										sx={{
											fontWeight: '600',
											fontSize: '16px',
											lineHeight: '24px',
											fontFamily: 'Inter',
											color: colors.gray[700],
										}}
										textAlign="center"
									>
										{page.name}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Box
						sx={{
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Image src={data?.logo_homepage?.value} height="40px" alt="PUB_Logo" style={{ objectFit: 'contain' }} />
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 10 }}>
						{pages.map(page => (
							<Button
								variant="text"
								key={page.path}
								onClick={() => handleCloseNavMenu(page.path)}
								sx={{
									mx: 2,
									fontSize: '16px',
									fontWeight: '500',
									color: colors.gray[700],
								}}
							>
								{page.name}
							</Button>
						))}
					</Box>
					<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
						{isOnline && role === '1' && (
							<IconButton sx={{ mx: 1 }} onClick={handleAdminNavigation}>
								<img src={AdminPortalSVG} alt="admin_svg" />
								<span
									style={{
										fontSize: '16px',
										fontWeight: '500',
										color: colors.gray[700],
									}}
								>
									Go to CMS
								</span>
							</IconButton>
						)}
						{isOnline && (
							<IconButton sx={{ mx: 1 }} onClick={handleSignout}>
								<img src={LogoutSVG} alt="logout_svg" />
							</IconButton>
						)}
						{isOnline && (
							<IconButton
								onClick={() => navigate(ROUTES.PROFILE)}
								sx={{
									width: '40px',
									height: '40px',
									borderRadius: '50%',
									border: '1px solid #E2E2E2',
									p: 1,
								}}
							>
								<Image src={profile?.image || LogoSVG} className="image-contain" alt="logo_svg" />
							</IconButton>
						)}
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' }, color: 'black' }}>
						{isOnline && (
							<IconButton
								sx={{
									width: '40px',
									height: '40px',
									borderRadius: '50%',
									border: '1px solid #E2E2E2',
									p: 1,
								}}
								onClick={handleOpenUserMenu}
							>
								<Image src={profile?.image || LogoSVG} className="image-contain" alt="logo_svg" />
							</IconButton>
						)}

						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							<MenuItem
								onClick={() => {
									navigate(ROUTES.PROFILE);
									handleCloseUserMenu();
								}}
							>
								<ListItemIcon>
									<AccountCircleIcon />
								</ListItemIcon>
								<ListItemText>Profile </ListItemText>
							</MenuItem>
							<MenuItem onClick={handleCloseUserMenu}>
								<ListItemIcon>
									<img src={SearchSVG} alt="search_svg" />
								</ListItemIcon>
								<ListItemText>Search </ListItemText>
							</MenuItem>
							<MenuItem
								onClick={() => {
									handleCloseUserMenu();
									handleSignout();
								}}
							>
								<ListItemIcon>
									<img src={LogoutSVG} alt="search_svg" />
								</ListItemIcon>
								<ListItemText>Logout </ListItemText>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default Header;
