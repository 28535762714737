import { Box, Divider, Typography } from '@mui/material'
import * as React from 'react'
import { colors } from 'styles'

interface ProfileProps {
  title: string
}

const PageTitle: React.FC<ProfileProps> = ({ title }) => {
  return (
    <>
      <Box pb="24px" pt="48px">
        <Typography
          fontSize="24px"
          fontWeight={500}
          lineHeight="38px"
          color={colors.gray[900]}
        >
          {title}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: colors.gray[200], mb: '32px' }} />
    </>
  )
}

export default PageTitle
