import { Box, Button, Link, Typography } from '@mui/material'
import { TextInput } from 'components/atoms'
import { AuthenticationLayout } from 'components/organisms'
import _get from 'lodash/get'
import { UpsertPasswordInfo, useResetPassword } from 'modules/auth'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from 'routers'
import { colors } from 'styles'
import { redirectTo } from 'utils'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { formSchema } from 'constants/form/schema'
import { ErrorMessage } from 'formik'

interface ResetPasswordPageProps { }



const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ }) => {
  
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { mutate, isSuccess, isError, error } = useResetPassword()

  const { handleSubmit, register,formState: { errors }, } = useForm<UpsertPasswordInfo>({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.LOGIN)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && error) {
      const errMessage = _get(error, 'response.data.error.message', '')
      toast.error(errMessage)
    }
  }, [isError])

  const onSubmit = (data: UpsertPasswordInfo) => {
    if (token) {
      mutate({ token, password: data.password })
    }
  }
  
  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="div"
          color={colors.gray[900]}
          fontWeight={600}
          fontSize="24px"
          lineHeight="32px"
        >
          Reset your password
        </Typography>
        <Typography color={colors.gray[500]} fontSize="16px" lineHeight="24px">
          Please create your new password
        </Typography>
        <TextInput
          register={register}
          type="password"
          // name="password"
          {...register('password')}
          placeholder="Your New Password"
          sx={{
            width: '100%',
            mt: '24px'
          }}
        />
        <Typography sx={{color: 'red', fontSize:'11px', textAlign:'left'}}>{errors.password?.message}</Typography>
        <TextInput
          register={register}
          type="password"
          name="confirmPassword"
          placeholder="Confirm Your New Password"
          sx={{
            width: '100%',
            mt: '24px'
          }}
        />
         <Typography sx={{color: 'red', fontSize:'11px',textAlign:'left'}}>{errors.confirmPassword?.message}</Typography>
        <Button
          sx={{ width: '100%', my: '24px' }}
          variant="contained"
          type="submit"
          // onClick={() => handleSubmit(onSubmit)}
          // disabled={typeof errors.confirmPassword !== 'undefined' || typeof errors.password !== 'undefined'}
        >
          Confirm
        </Button>
        {/* <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color={colors.gray[500]} fontSize="14px" mr="5px">
            Already have account?
          </Typography>
          <Link
            fontSize="14px"
            sx={{
              textDecoration: 'none'
            }}
            href={redirectTo(ROUTES.LOGIN)}
          >
            Sign in
          </Link>
        </Box> */}
      </form>
    </AuthenticationLayout>
  )
}

export default ResetPasswordPage
