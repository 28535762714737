import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import Image from 'components/atoms/Image';
import { useGetHomepageData } from 'modules/home';
import * as React from 'react';
import { colors } from 'styles';

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
	const { data } = useGetHomepageData();

	return (
		<Box
			sx={{
				backgroundColor: colors.gray[50],
				position: 'relative',
				bottom: '0',
				width: '100%',
				padding: '30px',
			}}
		>
			<Container maxWidth="xl" sx={{ height: '100%' }}>
				<Grid container height="100%">
					<Grid
						item
						md={6}
						xs={12}
						display="flex"
						alignItems="center"
						mb={{ xs: 1, md: 0 }}
						sx={{
							justifyContent: {
								md: 'flex-start',
								xs: 'center',
							},
						}}
					>
						<Image src={data?.logo_homepage?.value} height="40px" alt="PUB_Logo" style={{ objectFit: 'contain' }} />
					</Grid>
					<Grid item md={6} xs={12} display="flex" alignItems="center" justifyContent="flex-end">
						<Typography
							variant="body1"
							color={colors.gray[400]}
							sx={{
								textAlign: { md: 'right', xs: 'center' },
							}}
							dangerouslySetInnerHTML={{ __html: data?.copyright_homepage || '' }}
						></Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default Footer;
