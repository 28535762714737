import 'bootstrap/dist/css/bootstrap.min.css';
import { CompareProvider, NetworkProvider } from 'providers/context';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<NetworkProvider>
			<CompareProvider>
				<App />
			</CompareProvider>
		</NetworkProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
