import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { TextInput } from "components/atoms"
import { useProfileResetPassword } from "modules/auth"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import _get from "lodash/get"
import { formSchema } from 'constants/form/schema'
import { yupResolver } from '@hookform/resolvers/yup';

const ChangePassword = () => {
	const { isError, error, mutateAsync } = useProfileResetPassword()
	const { handleSubmit, register, reset, formState: { errors }, } = useForm({
		defaultValues: {
			password: '',
			oldPassword: '',
		},
		mode: "onTouched",
    resolver: yupResolver(formSchema),
	})

	useEffect(() => {
		if (isError && error) {
			const errMessage = _get(error, 'response.data.error.message', '');
			toast.error(errMessage || 'An internal error has occurred. Please try again later');
		}
	}, [isError])

	const onSubmit = async (data: any) => {
		try {
			await mutateAsync({
				old_password: data.oldPassword,
				password: data.password
			})
			toast.success('Successfully changed user password.')
		} catch (error) {
			const errMessage = _get(error, 'response.data.error.message', '')
			console.log(errMessage)
		}
	}
	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container margin="10px 0">
					<Grid item xs={12} md={4} paddingTop="10px !important">
						<Typography
							component="div"
							color="#344054"
							fontWeight={500}
							fontSize="16px"
							lineHeight="32px"
						>
							Current password
						</Typography>
					</Grid>
					<Grid item xs={12} md={8} sx={{ pt: { xs: '10px !important', md: '32px'}}}>
						<TextInput sx={{ fontSize: '16px', width: {
							xs: '100%',
							md: '40%'
						}, }} placeholder='Your current password' register={register} type="password" name="oldPassword" />
						<Typography sx={{color: 'red', fontSize:'11px', textAlign:'left'}}>{errors.oldPassword?.message}</Typography>
					</Grid>
				</Grid>
				<Grid container margin="10px 0">
					<Grid item xs={12} md={4} paddingTop="10px !important">
						<Typography
							component="div"
							color="#344054"
							fontWeight={500}
							fontSize="16px"
							lineHeight="32px"
						>
							New password
						</Typography>
					</Grid>
					<Grid item xs={12} md={8} sx={{ pt: { xs: '10px !important', md: '32px'}}}>
						<TextInput sx={{ fontSize: '16px', width: {
							xs: '100%',
							md: '40%'
						}}} placeholder='New password' register={register} type="password" name="password" />
						<Typography sx={{color: 'red', fontSize:'11px',textAlign:'left'}}>{errors.password?.message}</Typography>
					</Grid>
				</Grid>
				<Stack flexDirection="row" mt="20px" justifyContent={{ xs: 'space-between', md: 'end' }} alignItems='center' mb="10px" pr={{  xs: '0px', md: '30px' }}>
					<Button
						sx={{ mb: { xs: '60px', md: '24px' }, mr: '5px', borderRadius: '8px', width: { xs: '100%', md: 'inherit' }}}
						variant="outlined"
						onClick={() => reset({
							oldPassword: '',
							password: ''
						})}
					>
						Cancel
					</Button>
					<Button
						sx={{ mb: {xs: '60px', md: '24px'}, borderRadius: '8px', width: { xs: '100%', md: 'inherit'}}}
						variant="contained"
						type="submit"
						disabled={typeof errors.password !== 'undefined'}
					>
						Save
					</Button>
				</Stack>
			</form>
		</Box>
	)
}

export default ChangePassword
