import { useAuth } from 'constants/hooks';
import { NetworkContext } from 'providers/context';
import { FC, useContext, useEffect, useLayoutEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, ROUTES } from './consts';
import { admin, auth, unAuth } from './routes';

interface RoutersProps {}

const ProtectedRoute = ({ isSignedIn, redirectPath = ROUTES.LOGIN, children }: any) => {
	if (!isSignedIn) {
		return <Navigate to={redirectPath} replace />;
	}
	return children;
};

const OnlineRoute = ({ onlineMode, children }: any) => {
	const { isOnline } = useContext(NetworkContext);

	if (onlineMode && !isOnline) {
		return <Navigate to={ROUTES.HOME} replace />;
	}
	return children;
};

const Routers: FC<RoutersProps> = ({}) => {
	const { isOnline } = useContext(NetworkContext);
	const navigate = useNavigate();
	const location = useLocation();
	const { isLoggedIn } = useAuth();
	const role = localStorage.getItem('role');

	useEffect(() => {
		if (location.pathname.startsWith(ROUTES.ADMIN)) {
			return;
		}
		if (isLoggedIn || !isOnline) {
			!auth.some(route => route.path === location.pathname) && navigate(ROUTES.HOME);
		} else {
			!unAuth.some(route => route.path === location.pathname) && navigate(ROUTES.LOGIN);
		}
	}, [location.pathname, isLoggedIn]);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<Routes>
			<Route path="/" element={<Navigate to={isLoggedIn || !isOnline ? ROUTES.HOME : ROUTES.LOGIN} replace={true} />} />
			<Route path={ROUTES.ADMIN} element={<Navigate to={ADMIN_ROUTES.USERS} replace={true} />} />
			{isLoggedIn || !isOnline
				? auth.map((route, index) => (
						<Route
							key={`auth_${index}`}
							path={route.path}
							element={
								<OnlineRoute {...route}>
									<route.element />
								</OnlineRoute>
							}
						/>
				  ))
				: unAuth.map((route, index) => <Route key={`unAuth_${index}`} path={route.path} element={<route.element />} />)}
			{admin.map((route, index) => (
				<Route
					key={`admin_${index}`}
					path={route.path}
					element={
						<OnlineRoute onlineMode>
							<ProtectedRoute isSignedIn={isLoggedIn && role === '1'}>
								<route.element />
							</ProtectedRoute>
						</OnlineRoute>
					}
				/>
			))}
		</Routes>
	);
};

export default Routers;
