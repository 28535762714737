import { useEffect, useState } from "react";

export const useDisclosure = (initialState = false, { onOpen, onClose }: any = {}) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (isOpen !== initialState) {
      setIsOpen(initialState);
    }
  }, [initialState]);

  const open = (item?: any) => {
    setIsOpen(true);
    setItem(item);
    if (typeof onOpen === "function") {
      onOpen();
    }
  };

  const close = () => {
    setIsOpen(false);
    setItem(null);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const toggle = () => (isOpen ? close() : open());

  return { isOpen, item, open,  close, toggle };
};