import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Axios } from 'core/httpServices';
import _get from 'lodash/get'
import { TextInput } from 'components/atoms';
import { useUpdateUserProfile } from 'modules/auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Avatar } from 'assets/images';
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { ROUTES } from 'routers'
import { STORAGE_KEYS } from 'constants/api'

const Subtitle = styled('p')(() => ({
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '20px',
	color: '#667085'
}))
interface User {
	created_at?: string;
	email: string;
	full_name: string;
	id: string;
	image?: string;
	image_url?: string;
	organization?: {
		name: string,
		id: string
	};
	organization_id?: string;
	status: number;
	title: string;
	updated_at?: string
}
const buttonStyling = {
	borderRadius: '12px', display: 'flex', flexDirection: 'column', padding: '40px',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	height: '120px',
	width: '120px'
}
const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: "2px",
	boxSizing: 'border-box'
};
const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};
const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};


const MyProfile = () => {
	const { mutateAsync, isError, error } = useUpdateUserProfile()
	const navigate = useNavigate()
	const handleLogoutClick = () => {
		localStorage.removeItem(STORAGE_KEYS.AccessToken)
		localStorage.removeItem('role')
		navigate(ROUTES.LOGIN)
	}
	
	useEffect(() => {
		if (isError && error) {
			const errMessage = _get(error, 'response.data.error.message', '');
			toast.error(errMessage || 'An internal error has occurred. Please try again later')
		}
	}, [isError])

	const onSubmit = async (data: any) => {
		try {
			await mutateAsync({
				full_name: data.fullname,
				file: files[0]
			})
			toast.success('Successfully changed user information.')
		} catch(error) {
			const errMessage = _get(error, 'response.data.error.message', '')
			console.log(errMessage)
		}
	}

	const [files, setFiles] = useState([]);
	const { getRootProps, getInputProps, open } = useDropzone({
		accept: {
			'image/jpeg': [],
			'image/png': []
		},
		noClick: true,
		noKeyboard: true,
		maxSize: 100428800,
		onDrop: (acceptedFiles: any) => {
			setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
				preview: URL.createObjectURL(file)
			})));
		},
		onDropRejected: fileRejections => {
			const errorMessage =
				fileRejections[0]?.errors[0]?.message.replace(/ \d+ /, ' 100 ').replace(' bytes', 'mb') ||
				'Invalid File Format.';
			console.log({ errorMessage })
		},
	});

	const [user, setUser] = useState<User>();
	useEffect(() => {
		(async () => {
			try {
			const data = await Axios.get('/users/me')
			setUser(data.data)
			}catch(e:any) {
				if( e.response?.status === 401 || e.response?.status === 400){
          handleLogoutClick()
        }
			}
		})()
	}, [])
	const { handleSubmit, register, reset } = useForm({
		defaultValues: {
			fullname: user?.full_name
		}
	})
	useEffect(() => {
		if (user) {
			reset({
				fullname: user.full_name
			})
		}
	}, [user])
	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid sx={{ paddingTop: '0px' }} container mb="10px">
					<Grid item xs={12} md={4} paddingRight="5px">
						<Stack>
							<Typography
								component="div"
								color="#344054"
								fontWeight={500}
								fontSize="16px"
								lineHeight="32px"
								pr="5px"
							>
								Avatar
							</Typography>
							<Subtitle>Update your avatar and then choose where you want it to display</Subtitle>
						</Stack>
					</Grid>
					<Grid item xs={12} md={8} {...getRootProps()}>
						<Button sx={{
							...buttonStyling,
							// @ts-ignore
							backgroundImage: files.length ? `url("${files[0].preview}")` : ( user?.image_url && user.image !== null ) ? `url('${user?.image_url}')` : `url(${Avatar})`
						}} className="a-fileinput_button" onClick={open} >
							<input {...getInputProps()} className="a-fileinput_input" name='avatar' type="file" />
						</Button>
					</Grid>
				</Grid>
				<Grid sx={{ paddingTop: '0px', marginBottom: '10px' }} container>
					<Grid item xs={12} md={4} >
						<Stack>
							<Typography
								component="div"
								color="#344054"
								fontWeight={500}
								fontSize="16px"
								lineHeight="32px"
							>
								My fullname
							</Typography>
							<Subtitle>This will be displayed on your profile</Subtitle>
						</Stack>
					</Grid>
					<Grid item xs={12} md={8}>
						<TextInput sx={{ fontSize: '16px', width: {
							xs: '100%',
							md: '40%'
						} }} register={register} name="fullname" />
					</Grid>
				</Grid>
				<Grid sx={{ paddingTop: '0px', marginBottom: '10px' }} container>
					<Grid item xs={12} md={4}>
						<Stack>
							<Typography
								component="div"
								color="#344054"
								fontWeight={500}
								fontSize="16px"
								lineHeight="32px"
							>
								Title
							</Typography>
							<Subtitle>A quick snapshot of your company</Subtitle>
						</Stack>
					</Grid>

					<Grid item xs={12} md={8}>
						<TextInput value={user?.title || ''} disabled sx={{ width: {
							xs: '100%',
							md: '40%'
						}, fontSize: '16px' }} />
					</Grid>
				</Grid>
				<Grid sx={{ paddingTop: '0px', marginBottom: '10px' }} container>
					<Grid item xs={12} md={4}>
						<Stack>
							<Typography
								component="div"
								color="#344054"
								fontWeight={500}
								fontSize="16px"
								lineHeight="32px"
							>
								Your organisation
							</Typography>
							<Subtitle>A quick snapshot of your company</Subtitle>
						</Stack>

					</Grid>
					<Grid item xs={12} md={8}>
						<TextInput value={(user?.organization && user.organization.name) ? user.organization.name : ''} disabled sx={{ fontSize: '16px', width: {
							xs: '100%',
							md: '40%'
						}}} />
					</Grid>
				</Grid>
				<Grid sx={{ paddingTop: '0px', marginBottom: '10px' }} container>
					<Grid item xs={12} md={4}>
						<Typography
							component="div"
							color="#344054"
							fontWeight={500}
							fontSize="16px"
							lineHeight="32px"
						>
							Email Address
						</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<TextInput value={user?.email || ''} disabled sx={{ fontSize: '16px', width: {
							xs: '100%',
							md: '40%'
						} }} />
					</Grid>
				</Grid>
				<Stack flexDirection="row" mt="10px" justifyContent={{ xs: 'space-between', md: 'end' }} alignItems='center' pr={{ xs: '0px', md: '30px' }}>
					<Button
						sx={{ mb: {xs: '60px', md: '26px'}, borderRadius: '8px', width: { xs: '100%', md: 'inherit'}}}
						variant="contained"
						type="submit"
					>
						Save
					</Button>
				</Stack>
			</form>
		</Box>
	)
}

export default MyProfile