import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { RatingCustom } from 'components/atoms';
import Image from 'components/atoms/Image';
import { Measure, useGetCriteriaTypes } from 'modules/measure';
import React from 'react';
import { colors } from 'styles';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		borderBottom: `1px solid ${colors.gray[200]}`,
		padding: '0 14px!important',
		'&:before': {
			display: 'none',
		},
		'&:first-of-type': {
			borderTop: `1px solid ${colors.gray[200]}`,
		},
	})
);

interface AccordionCustomCompareProps {
	res: Measure;
}

const AccordionCustomCompare: React.FC<AccordionCustomCompareProps> = ({ res }) => {
	const { data: criterias = [] } = useGetCriteriaTypes();
	const [expanded, setExpanded] = React.useState<string | true>(true);

	const accordions = [
		{
			label: 'Description',
			expandState: false,
			content: (
				<Box>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res?.description }}
					></div>
				</Box>
			),
		},
		{
			label: 'Function',
			expandState: false,
			content: (
				<Box>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res?.functions }}
					></div>
				</Box>
			),
		},
		{
			label: 'Potential application',
			expandState: true,
			content: (
				<div>
					<Image
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						src={res?.location?.image}
						alt="potential application"
					/>
					<Typography
						fontSize="14px"
						fontWeight="500"
						lineHeight="18px"
						color="#6C737F"
						my={'12px'}
						sx={{ textAlign: 'center' }}
					>
						{res?.location?.title}
					</Typography>
				</div>
			),
		},
		...criterias.map(it => ({
			label: it.name,
			expandState: true,
			content: (
				<Box display="flex" flexDirection="column" gap={2}>
					{it.criterias.map(item => {
						const data = res.criterias.find(x => x.id === item.id);
						return (
							<div key={it.id}>
								<RatingCustom
									tooltip={data?.measure_criteria?.description}
									title={item.principle}
									value={data?.measure_criteria?.point || 0}
								/>
							</div>
						);
					})}
				</Box>
			),
		})),
		{
			label: 'Important Technical Considerations',
			expandState: true,
			content: (
				<div>
					<Typography fontSize="15px" fontWeight="500" lineHeight="24px" mb={'12px'}>
						Planning
					</Typography>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res.technical_consider.planning }}
					></div>
					<Typography fontSize="15px" fontWeight="500" lineHeight="24px" mb={'12px'}>
						Design
					</Typography>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res.technical_consider.design }}
					></div>
					<Typography fontSize="15px" fontWeight="500" lineHeight="24px" mb={'12px'}>
						Operation
					</Typography>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res.technical_consider.operation }}
					></div>
					<Typography fontSize="15px" fontWeight="500" lineHeight="24px" mb={'12px'}>
						Implementation
					</Typography>
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res.technical_consider.implementation }}
					></div>
				</div>
			),
		},
		{
			label: 'Potential Combination with Other Measures',
			expandState: true,
			content: (
				<>
					<ul>
						{res.potentials.map((items: any, id: number) => (
							<li
								style={{
									marginTop: '10px',
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: '20px',
									marginBottom: '12px',
									color: '#9DA4AE',
								}}
							>
								{items.name}
							</li>
						))}
					</ul>
				</>
			),
		},
	];

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : true);
	};

	return (
		<>
			{accordions.map((item, index) => {
				const panelKey = `panel_${index}`;
				return (
					<Accordion
						key={`accordion_${index}`}
						defaultExpanded={index !== 0 ? true : false}
						onChange={handleChange(panelKey)}
					>
						<AccordionSummary
							sx={{ p: '0px!important' }}
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography fontSize="24px" fontWeight="600">
								{item.label}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>{item.content}</AccordionDetails>
					</Accordion>
				);
			})}
		</>
	);
};

export default AccordionCustomCompare;
