import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, CardContent, Container, Grid, IconButton, Typography } from '@mui/material';
import { CloseSVG, Diaram_3D, Photo, Schematic } from 'assets/icons';
import Image from 'components/atoms/Image';
import { AccordionCustomCompare } from 'components/molecules';
import { useCompareContext } from 'providers/context';
import * as React from 'react';
import { useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { colors } from 'styles';
import MeasurePopup from '../MeasurePopup/index';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		paritialVisibilityGutter: 60,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 50,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
};

const CompareTab: React.FC = () => {
	const firstButtonRef_0 = useRef<any>(null);
	const secondButtonRef_0 = useRef<any>(null);
	const thirdButtonRef_0 = useRef<any>(null);
	const firstButtonRef_1 = useRef<any>(null);
	const secondButtonRef_1 = useRef<any>(null);
	const thirdButtonRef_1 = useRef<any>(null);
	const firstButtonRef_2 = useRef<any>(null);
	const secondButtonRef_2 = useRef<any>(null);
	const thirdButtonRef_2 = useRef<any>(null);
	const pointScrollTo = useRef<HTMLDivElement>(null);

	const { compareData, handleRemoveCompareData } = useCompareContext();

	const [toggle, setToggle] = useState<number>();

	const handleFirstButtonClick = (id: number) => {
		if (id === 0) {
			firstButtonRef_0.current.click();
		} else if (id === 1) {
			firstButtonRef_1.current.click();
		} else {
			firstButtonRef_2.current.click();
		}
	};
	const handleSecondButtonClick = (id: number) => {
		if (id === 0) {
			secondButtonRef_0.current.click();
		} else if (id === 1) {
			secondButtonRef_1.current.click();
		} else {
			secondButtonRef_2.current.click();
		}
	};
	const handleThirdButtonClick = (id: number) => {
		if (id === 0) {
			thirdButtonRef_0.current.click();
		} else if (id === 1) {
			thirdButtonRef_1.current.click();
		} else {
			thirdButtonRef_2.current.click();
		}
	};

	const ButtonGroup = ({ goToSlide, ...rest }: any) => {
		const {
			carouselState: { currentSlide },
		} = rest;
		return (
			<div style={{ background: 'white', position: 'absolute' }}>
				<Button
					ref={
						rest.data === 0
							? firstButtonRef_0
							: rest.data === 1
							? firstButtonRef_1
							: rest.data === 2
							? firstButtonRef_2
							: firstButtonRef_0
					}
					key={1}
					sx={{ display: 'none' }}
					disabled={currentSlide === 0}
					onClick={() => goToSlide(0)}
				></Button>
				<Button
					ref={
						rest.data === 0
							? secondButtonRef_0
							: rest.data === 1
							? secondButtonRef_1
							: rest.data === 2
							? secondButtonRef_2
							: secondButtonRef_0
					}
					key={2}
					sx={{ display: 'none' }}
					disabled={currentSlide === 1}
					onClick={() => goToSlide(1)}
				></Button>
				<Button
					ref={
						rest.data === 0
							? thirdButtonRef_0
							: rest.data === 1
							? thirdButtonRef_1
							: rest.data === 2
							? thirdButtonRef_2
							: thirdButtonRef_1
					}
					key={3}
					sx={{ display: 'none' }}
					disabled={currentSlide === 2}
					onClick={() => goToSlide(2)}
				></Button>
			</div>
		);
	};
	const ButtonGroupSub = ({ goToSlide, previous, next, ...rest }: any) => {
		const {
			carouselState: { currentSlide },
		} = rest;
		return (
			<div
				style={{
					background: 'white',
					position: 'absolute',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					marginTop: '10px',
					alignItems: 'center',
				}}
			>
				<Button key={1} disabled={currentSlide === 0} onClick={() => previous()}>
					<ArrowBackIcon sx={{ color: colors.gray[500] }} />
				</Button>
				<Typography>{rest && rest.data && rest?.data[currentSlide]?.name}</Typography>
				<Button disabled={currentSlide === rest?.data?.length - 1} onClick={() => next()}>
					<ArrowForwardIcon sx={{ color: colors.gray[500] }} />
				</Button>
			</div>
		);
	};
	return (
		<Container maxWidth="lg">
			<Grid ref={pointScrollTo} container pl="0px" mb="100px" alignItems="flex-start" overflow="auto" flexWrap="nowrap">
				<>
					{['1', '2', '3'].map((option: any, id: number) => (
						<Grid
							key={id}
							item
							xs={12}
							md={4}
							sm={4}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							sx={{
								display: {
									xs: 'inline-block',
									md: 'flex',
								},
								minWidth: '300px',
								flex: 1,
							}}
						>
							{compareData[id] ? (
								<Box
									sx={{
										border: '1px dashed #E5E7EB',
										height: '100%',
										mx: '0.5em',
										position: 'relative',
									}}
								>
									<IconButton
										onClick={() => handleRemoveCompareData(compareData[id] as any, id)}
										sx={{
											position: 'absolute',
											top: '5px',
											right: '5px',
										}}
									>
										<img src={CloseSVG} alt="close_icon" />
									</IconButton>
									<CardContent
										sx={{
											display: 'flex',
											alignContent: 'flex-start',
											flexDirection: 'column',
										}}
									>
										<Typography
											sx={{
												fontFamily: 'Inter',
												fontWeight: '700',
												fontSize: '12px',
												letterSpacing: '0.08em',
												textTransform: 'uppercase',
											}}
											gutterBottom
											component="div"
											color={
												compareData[id]?.category?.id === 'c950a28c-019e-4e35-96fc-009e305af905'
													? '#2E90FA'
													: compareData[id]?.category?.id === '5379e9bb-6867-407b-89ff-9942cc8381e6'
													? '#FF8F28'
													: compareData[id]?.category?.id === '61b35e7a-ec60-47b7-9eca-d3e3f928635c'
													? '#67B817'
													: compareData[id]?.category?.id === '404c71b4-7bb1-4ea4-b681-49677b367cb9'
													? '#1C449C'
													: compareData[id]?.category?.id === '58b6fc09-d0fc-4c65-bea7-acab178833b1'
													? '#189FB1'
													: '#219653'
											}
										>
											{compareData[id]?.category?.name}
										</Typography>
										<Typography
											sx={{
												fontSize: '24px',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												fontFamily: 'Inter',
												fontWeight: '600',
											}}
										>
											{compareData[id]?.name}
										</Typography>
										<Box display="flex" alignItems="flex-end" justifyContent="space-between">
											<Typography
												sx={{ mt: '20px', fontFamily: 'Inter', fontWeight: '600', fontSize: '16px' }}
												gutterBottom
												component="div"
											>
												Image type
											</Typography>
											<div>
												<Button onClick={() => handleFirstButtonClick(id)}>
													<img src={Photo} alt="logout_svg" />
												</Button>
												<Button onClick={() => handleSecondButtonClick(id)}>
													<img src={Schematic} alt="logout_svg" />
												</Button>
												<Button onClick={() => handleThirdButtonClick(id)}>
													<img src={Diaram_3D} alt="logout_svg" />
												</Button>
											</div>
										</Box>
										<Box sx={{ pb: '30px' }}>
											<Carousel
												responsive={responsive}
												autoPlay={false}
												arrows={false}
												renderButtonGroupOutside={true}
												customButtonGroup={<ButtonGroup data={id} />}
											>
												<Image
													style={{ width: '100%', height: '200px', objectFit: 'cover' }}
													alt="compare data"
													src={compareData[id]?.main_picture}
												/>
												{['1', '2'].map((res: any, idex: number) => (
													<Carousel
														key={idex}
														responsive={responsive}
														autoPlay={false}
														arrows={false}
														renderButtonGroupOutside={true}
														customButtonGroup={
															idex === 0 ? (
																<ButtonGroupSub data={compareData[id]?.attachments?.filter((e: any) => e.type === 1)} />
															) : (
																<ButtonGroupSub data={compareData[id]?.attachments?.filter((e: any) => e.type === 2)} />
															)
														}
													>
														{idex === 0 &&
															compareData[id]?.attachments
																?.filter(e => e.type === 1)
																.map((item, i) => (
																	<Image
																		key={i}
																		style={{
																			width: '100%',
																			height: '140px',
																			margin: 'auto',
																		}}
																		src={item.image}
																	/>
																))}
														{idex === 1 &&
															compareData[id]?.attachments
																?.filter(e => e.type === 2)
																.map((item, i) => (
																	<>
																		<Image
																			key={i}
																			style={{
																				width: '100%',
																				height: '140px',
																				margin: 'auto',
																			}}
																			src={item.image}
																		/>
																	</>
																))}
													</Carousel>
												))}
											</Carousel>
										</Box>
									</CardContent>
									<Container maxWidth="xl" sx={{ p: '0px!important' }}>
										<AccordionCustomCompare res={compareData[id] as any} />
									</Container>
								</Box>
							) : (
								<Box
									sx={{
										border: '1px dashed #E5E7EB',
										height: '300px',
										mx: '0.5em',
										display: 'flex',
										background: '#F3F4F6',
										justifyContent: 'center',
										alignItems: 'flex-start',
										mr: '5px',
									}}
								>
									<Button
										sx={{
											color: 'black',
											margin: 'auto',
											background: '#FFFFFF',
											p: '5px 25px',
											border: '1px solid #D0D5DD',
											borderRadius: '6px',
											boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
										}}
										onClick={() => {
											setToggle(id);
										}}
									>
										+ Add Measure
									</Button>
								</Box>
							)}
						</Grid>
					))}
				</>
			</Grid>
			<MeasurePopup open={toggle} onClose={() => setToggle(undefined)} />
		</Container>
	);
};

export default CompareTab;
