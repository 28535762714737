import { array, object, string } from 'yup'

export const principleSchema = object({
  principle: string().required('Principle name is required'),
  definition: string(),
  scoring_guide: array().of(string()).min(0).max(5)
})

export const defaultInitialPrincipleValues = {
  principle: '',
  definition: '',
  scoring_guide: ['', '', '', '', '']
}