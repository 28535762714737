import { ApiUrl, ApiUrlOffline } from 'constants/api';
import { Axios } from 'core/httpServices';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { isValidHttpUrl } from 'utils';

const NetworkContext = createContext({
	isOnline: true,
});

const NetworkProvider = ({ children }: { children?: ReactNode }) => {
	const [isOnline, setIsOnline] = useState(isValidHttpUrl(window.location.href));

	const handleOnline = useCallback(() => {
		Axios.setBaseUrl(ApiUrl);
		setIsOnline(true);
	}, []);

	const handleOffline = useCallback(() => {
		Axios.setBaseUrl(ApiUrlOffline);
		setIsOnline(false);
	}, []);

	useEffect(() => {
		if (isOnline) {
			handleOnline();
		} else {
			handleOffline();
		}
	}, []);

	return <NetworkContext.Provider value={{ isOnline }}>{children}</NetworkContext.Provider>;
};

const useNetworkContext = () => {
	const context = useContext(NetworkContext);
	if (!context) {
		throw new Error('Context must be provided!');
	}
	return context;
};

export { NetworkProvider, NetworkContext, useNetworkContext };
