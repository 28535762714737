import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import { AccordionProps } from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import React, { useState} from 'react'
import { colors } from 'styles'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${colors.gray[200]}`,
  '&:before': {
    display: 'none'
  }
}))

interface AccordionCustomProps {
  data: Array<{ label: string; content: React.ReactNode }>
  icon?: boolean
  onchange? : any
}

const AccordionCustom: React.FC<AccordionCustomProps> = ({ data, icon, onchange }) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel_0')
  const [state, setState] = useState<string>('')
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
      setState(panel)
      onchange(panel)
    }
  return (
    <>
      {data.map((item, index:number) => {
        const panelKey = `panel_${index}`
        return (
          <Accordion
            key={`accordion_${index}`}
            defaultExpanded={expanded === 'panel_0'}
            expanded={expanded === panelKey}
            onChange={handleChange(panelKey)}
            sx={{
              '& .Mui-expanded': {
                background: '#F5FAFF',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                mt: '10px'
              },
              '& .MuiCollapse-root': {
                background: '#F5FAFF',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
                mb: '10px'
              },
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontSize="16px" fontWeight="500">
                {item.label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{item.content}</AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default AccordionCustom
